import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'burddy-monorepo-loading',
  standalone: true,
  imports: [SpinnerComponent, TranslateModule],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {}
