import { AddressData, BookingData, BookingType } from '../booking';
import {
  ALL_OPTIONS_PERCENT_REDUCTION,
  AvailableOptions,
  B2B_MINIMUM_DAYS_BEFORE_EVENT,
  B2C_MINIMUM_DAYS_BEFORE_EVENT,
  ONLY_FOR_B2B_OPTIONS,
  ONLY_FOR_B2C_OPTIONS,
  OPTIONS_THAT_CAN_BE_PROMOTIONAL,
  PRICES,
} from '../constants';

export function addressAreSame(
  delivery: AddressData | undefined,
  returnAddress: AddressData | undefined,
): boolean {
  if (!delivery && !returnAddress) return true;
  return (
    !!delivery &&
    !!returnAddress &&
    ((delivery?.id &&
      returnAddress?.id &&
      delivery?.id === returnAddress?.id) ||
      (delivery?.city === returnAddress?.city &&
        delivery?.streetName === returnAddress?.streetName &&
        delivery?.postalCode === returnAddress?.postalCode &&
        delivery?.country === returnAddress?.country))
  );
}

export function initNewBooking(bookingType: BookingType): BookingData {
  return {
    options: new Map<string, number>([
      [AvailableOptions.insurance, 1],
      [AvailableOptions.b2cDay, 1],
    ]),
    infoForUser: {},
  };
}

export function calculateBookingPrice(data?: BookingData): number {
  let total = 0;
  if (data) {
    total = Array.from(data?.options?.entries() || []).reduce(
      (acc, [key, value]) => {
        if (!(key in PRICES)) {
          return acc;
        }

        return (
          acc +
          (PRICES[key as keyof typeof PRICES] ?? 0) *
            (Math.abs(value) ?? 0) *
            (checkAllPossibleOptionsAreTaken(
              data.options ?? new Map<string, number>(),
            ) &&
            OPTIONS_THAT_CAN_BE_PROMOTIONAL.includes(key as AvailableOptions)
              ? ALL_OPTIONS_PERCENT_REDUCTION / 100
              : 1)
        );
      },
      total,
    );

    total = total + (data?.selectedPrints?.price ?? 0);

    if (data?.promoCode?.isValid) {
      if (data?.promoCode.isPercentage) {
        total = total - (total * data?.promoCode?.reduction) / 100;
      } else {
        total = total - data?.promoCode?.reduction;
      }
    }

    if (total < 0) {
      return 0;
    }
  }
  return total;
}

export function checkAllPossibleOptionsAreTaken(
  options: Map<string, number>,
): boolean {
  let isMissingOptions = false;
  // foreach property in PRICES
  OPTIONS_THAT_CAN_BE_PROMOTIONAL.forEach((option) => {
    if (!options?.has(option)) {
      isMissingOptions = true;
    }
  });

  return !isMissingOptions;
}

export function getMinimumDate(): Date {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
}

export function compareDate(
  firstDate: Date | undefined,
  lastDate: Date | undefined,
): number {
  if (!firstDate || !lastDate) return 0;
  firstDate = new Date(firstDate.setHours(12, 0, 0, 0));
  lastDate = new Date(lastDate.setHours(12, 0, 0, 0));
  if (
    firstDate.getMonth() === lastDate.getMonth() &&
    firstDate.getFullYear() === lastDate.getFullYear() &&
    firstDate.getDate() === lastDate.getDate()
  ) {
    return 0;
  }
  const diffTime = lastDate.getTime() - firstDate.getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

// formate date as YYYY-MM-DD OR DD-MM-YYYY
export function formatDate(
  date: Date,
  withTime = false,
  dayBefore = false,
): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return dayBefore
    ? `${day >= 10 ? day : '0' + day}-${
        month >= 10 ? month : '0' + month
      }-${date.getFullYear()}`
    : `${date.getFullYear()}-${month >= 10 ? month : '0' + month}-${
        day >= 10 ? day : '0' + day
      }` +
        (withTime
          ? ` ${hours >= 10 ? hours : '0' + hours}:${
              minutes >= 10 ? minutes : '0' + minutes
            }:${seconds >= 10 ? seconds : '0' + seconds}`
          : '');
}

export function filterOptionBasedOnBookingType(
  bookingType: string,
): (
  value: [string, number],
  index: number,
  array: [string, number][],
) => unknown {
  return ([key, value]) => {
    if (ONLY_FOR_B2B_OPTIONS.includes(key as AvailableOptions)) {
      return false;
    }

    if (ONLY_FOR_B2C_OPTIONS.includes(key as AvailableOptions)) {
      return false;
    }

    return true;
  };
}

export function getPossibleDeliveryDateForB2BEventDate(
  eventDate: Date,
): Date[] {
  if (!eventDate) {
    return [];
  }

  const possibleDeliveryDates: Date[] = [];
    possibleDeliveryDates.push(eventDate);
    possibleDeliveryDates.push(addDaysToDate(eventDate, -1));
    possibleDeliveryDates.push(addDaysToDate(eventDate, -2));

  return possibleDeliveryDates
    .sort((a, b) => b.getTime() - a.getTime())
    .slice(0, 3);
}

// catch the next weekday from a given date for the b2c logic
function getNextWeekday(date: Date, dayOfWeek: number): Date {
  const resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + ((dayOfWeek + 7 - date.getDay()) % 7));
  return resultDate;
}

export function getPossibleDeliveryDateForB2CEventDate(
  eventDate: Date,
): Date[] {
  if (!eventDate) {
    return [];
  }

  const thursday = getNextWeekday(eventDate, 4);
  const friday = getNextWeekday(eventDate, 5);

  return [thursday, friday];
}

export function getPossibleReturnDateForB2CEventDate(eventDate: Date): Date[] {
  if (!eventDate) {
    return [];
  }

  const monday = getNextWeekday(eventDate, 1);
  const tuesday = getNextWeekday(eventDate, 2);

  return [monday, tuesday];
}

export function getPossibleReturnDateForB2BEventDate(eventDate: Date): Date[] {
  if (!eventDate) {
    return [];
  }

  const possibleReturnDates: Date[] = [];
    possibleReturnDates.push(eventDate);
    possibleReturnDates.push(addDaysToDate(eventDate, 1));
    possibleReturnDates.push(addDaysToDate(eventDate, 2));
  return possibleReturnDates
    .sort((a, b) => a.getTime() - b.getTime())
    .slice(0, 3);
}

export function addDaysToDate(eventDate: Date, days: number): Date {
  const date = new Date(eventDate);
  date.setDate(date.getDate() + days);
  return date;
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  const dateToReturn = new Date(date);
  date.setMinutes(date.getMinutes() + minutes);
  return dateToReturn;
}

export function canEditBooking(
  eventDate: Date,
  bookingType: BookingType,
): boolean {
  return (
    addDaysToDate(
      eventDate,
      -(bookingType === 'b2c'
        ? B2C_MINIMUM_DAYS_BEFORE_EVENT
        : B2B_MINIMUM_DAYS_BEFORE_EVENT),
    ).getTime() > new Date().getTime()
  );
}

export function getEventDatesTitle(
  eventDate: Date | undefined,
  duration?: number,
): string {
  if (!eventDate) return '';

  return `${eventDate.getDate()} {{MONTH.${eventDate.getMonth()}}} ${eventDate.getFullYear()}`;
}

export function getSecondPossibleDeliveryDate(deliveryDate: Date): Date {
  // if delivery date is on Thursday, second date should be next friday if other day, thursday of the same week
  const secondePossibleDeliveryDate = new Date(deliveryDate);
  const dayOfWeek = secondePossibleDeliveryDate.getDay();
  if (dayOfWeek === 4) {
    secondePossibleDeliveryDate.setDate(
      secondePossibleDeliveryDate.getDate() + 1,
    );
  } else {
    secondePossibleDeliveryDate.setDate(
      secondePossibleDeliveryDate.getDate() + (4 - dayOfWeek),
    );
  }
  return secondePossibleDeliveryDate;
}

export function getSecondPossibleReturnDate(returnDate: Date): Date {
  // if return date is on monday, returns tuesday of the same week, else monday of the same week
  const secondePossibleReturnDate = new Date(returnDate);
  const dayOfWeek = secondePossibleReturnDate.getDay();
  if (dayOfWeek === 1) {
    secondePossibleReturnDate.setDate(secondePossibleReturnDate.getDate() + 1);
  } else {
    secondePossibleReturnDate.setDate(
      secondePossibleReturnDate.getDate() - (dayOfWeek - 1),
    );
  }
  return secondePossibleReturnDate;
}

export function getInformationDeliveryDate(deliveryDate: Date) {
  let informationDeliveryDate: Date;
  const dayOfWeek = deliveryDate.getDay();

  if (dayOfWeek === 1 || dayOfWeek === 2 || dayOfWeek === 3) {
    // If deliveryDate is Monday, Tuesday or Wednesday
    // Set informationDeliveryDate to Friday of the week before
    informationDeliveryDate = new Date(deliveryDate);
    informationDeliveryDate.setDate(deliveryDate.getDate() - (dayOfWeek + 2));
  } else {
    // If deliveryDate is any other day
    // Set informationDeliveryDate to Monday of the same week
    informationDeliveryDate = new Date(deliveryDate);
    informationDeliveryDate.setDate(deliveryDate.getDate() - (dayOfWeek - 1));
  }
  return informationDeliveryDate;
}

export function getTVAToApply(
  element: { deliveryCountry: string; invoiceDate: Date; agency: string },
  tvaToUse: number,
) {
  const date2023 = new Date(2023, 0, 1);
  const date2024 = new Date(2024, 0, 1);

  switch (element.deliveryCountry) {
    case 'LU':
      if (element.agency.toUpperCase() === 'NBE') {
        tvaToUse = 21;
      } else {
        tvaToUse =
          element.invoiceDate < date2023 || element.invoiceDate >= date2024
            ? 17
            : 16;
      }
      break;
    case 'FR':
      switch (element.agency.toUpperCase()) {
        case 'LU':
          tvaToUse = 20;
          break;
        case 'BE':
        case 'BRU':
        case 'NBE':
          tvaToUse = 21;
          break;
        default:
          tvaToUse = 20;
          break;
      }
      break;
    case 'BE':
      switch (element.agency.toUpperCase()) {
        case 'LU':
          tvaToUse =
            element.invoiceDate < date2023 || element.invoiceDate >= date2024
              ? 17
              : 16;
          break;
        default:
          tvaToUse = 21;
          break;
      }
      break;
    case 'NL':
      tvaToUse = 21;
      break;
  }
  return tvaToUse;
}
