import { InjectionToken } from '@angular/core';

export interface IEnvironment {
  mainHostUrl: string;
  bookingApiUrl: string;
  production: boolean;
  apiConfigToolUrl: string;
  basePath: string;
  remoteConfigToolsScriptUrls: string[];
}

export const ENVIRONNEMENT = new InjectionToken<IEnvironment>('ENVIRONNEMENT');
