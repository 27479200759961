import { FormGroup } from '@angular/forms';

import {
  AddressFormGroup,
  AddressFormGroupInitValues,
} from './addressFormGroup';
import {
  ContactFormGroup,
  ContactFormGroupInitValues,
} from './contactFormGroup';
import {
  PasswordModificationFormGroup,
  PasswordModificationFormGroupInitValues,
} from './password-modification-form-group';

export interface CustomerProfileFormGroupInitValues {
  customerAddress: AddressFormGroupInitValues;
  invoiceAddress: AddressFormGroupInitValues;
  mainInfo: ContactFormGroupInitValues;
  passwordModification: PasswordModificationFormGroupInitValues;
}

export class CustomerProfileFormGroup extends FormGroup {
  constructor(init?: CustomerProfileFormGroupInitValues | undefined) {
    super({
      invoiceAddress: new AddressFormGroup(init?.invoiceAddress),
      customerAddress: new AddressFormGroup(init?.customerAddress),
      mainInfo: new ContactFormGroup(init?.mainInfo),
      passwordModification: new PasswordModificationFormGroup(
        init?.passwordModification,
      ),
    });
  }
}
