import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONNEMENT,
  IEnvironment,
} from '@burddy-monorepo/front/shared/interfaces';
import {
  IVerifyAddressRequest,
  VerifyAddressResponse,
} from '@burddy-monorepo/shared/shared-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(
    @Inject(ENVIRONNEMENT) private environment: IEnvironment,
    private httpService: HttpClient,
  ) {}

  verify(
    addressData: IVerifyAddressRequest,
  ): Observable<VerifyAddressResponse> {
    return this.httpService.get<VerifyAddressResponse>(
      `${this.environment.bookingApiUrl}/address/verify`,
      {
        params: {
          ...addressData,
        },
      },
    );
  }
}
