import { PrintDto } from '../booking';
import { PossibleDeliveryHours } from '../enums';

export enum AvailableOptions {
  insurance = 'insurance',
  b2bInsurance = 'b2bInsurance',
  // day = 'day',
  b2cDay = 'b2cDay',
  b2bDay = 'b2bDay',
  b2cDelivery = 'b2cDelivery',
  b2bDelivery = 'b2bDelivery',
  deliveryCustomMade = 'deliveryCustomMade',
  props = 'props',
  virtualProps = 'virtualProps',
  deleteLogo = 'deleteLogo',
  goldenBook = 'goldenBook',
  galleryPhotos = 'galleryPhotos',
  b2bNightDelivery = 'b2bNightDelivery',
  b2bNightReturn = 'b2bNightReturn',
  dismantling = 'dismantling',
  b2cSelectDay = 'b2cSelectDay',
}

export const ALL_OPTIONS_PERCENT_REDUCTION = 50;

export const NIGHT_HOURS = [
  PossibleDeliveryHours.H_20_22,
  PossibleDeliveryHours.H_22_00,
];

export function getPropsId(option: AvailableOptions): number {
  switch (option) {
    // case AvailableOptions.insurance:
    //   return 8;
    // case AvailableOptions.b2bInsurance:
    //   return 13;
    // case AvailableOptions.b2cDelivery:
    //   return 14;
    // case AvailableOptions.b2bDelivery:
    //   return 16;
    // case AvailableOptions.props:
    //   return 1;
    // case AvailableOptions.virtualProps:
    //   return 9;
    // case AvailableOptions.deleteLogo:
    //   return 6;
    // case AvailableOptions.goldenBook:
    //   return 2;
    // case AvailableOptions.galleryPhotos:
    //   return 17;
    // case AvailableOptions.b2cSelectDay:
    //   return 18;
    // case AvailableOptions.deliveryCustomMade:
    //   return 19;
    // case AvailableOptions.dismantling:
    //   return 5;

    case AvailableOptions.insurance:
      return 20;
    case AvailableOptions.b2bInsurance:
      return 21;
    case AvailableOptions.b2cDay:
      return 22;
    case AvailableOptions.b2bDay:
      return 23;
    case AvailableOptions.b2cDelivery:
      return 24;
    case AvailableOptions.b2bDelivery:
      return 25;
    case AvailableOptions.deliveryCustomMade:
      return 26;
    case AvailableOptions.props:
      return 27;
    case AvailableOptions.virtualProps:
      return 28;
    case AvailableOptions.deleteLogo:
      return 29;
    case AvailableOptions.goldenBook:
      return 30;
    case AvailableOptions.galleryPhotos:
      return 31;
    case AvailableOptions.b2bNightDelivery:
      return 32;
    case AvailableOptions.b2bNightReturn:
      return 33;
    case AvailableOptions.dismantling:
      return 34;
    default:
      return 0;
  }
}

export function getPropsFromId(id: number): AvailableOptions | undefined {
  switch (id) {
    case 8:
      return AvailableOptions.insurance;
    case 13:
      return AvailableOptions.b2bInsurance;
    case 14:
      return AvailableOptions.b2cDelivery;
    case 16:
      return AvailableOptions.b2bDelivery;
    case 1:
      return AvailableOptions.props;
    case 9:
      return AvailableOptions.virtualProps;
    case 6:
      return AvailableOptions.deleteLogo;
    case 2:
      return AvailableOptions.goldenBook;
    case 17:
      return AvailableOptions.galleryPhotos;
    case 18:
      return AvailableOptions.b2cSelectDay;
    case 19:
      return AvailableOptions.deliveryCustomMade;
    case 5:
      return AvailableOptions.dismantling;

    case 20:
      return AvailableOptions.insurance;
    case 21:
      return AvailableOptions.b2bInsurance;
    case 22:
      return AvailableOptions.b2cDay;
    case 23:
      return AvailableOptions.b2bDay;
    case 24:
      return AvailableOptions.b2cDelivery;
    case 25:
      return AvailableOptions.b2bDelivery;
    case 26:
      return AvailableOptions.deliveryCustomMade;
    case 27:
      return AvailableOptions.props;
    case 28:
      return AvailableOptions.virtualProps;
    case 29:
      return AvailableOptions.deleteLogo;
    case 30:
      return AvailableOptions.goldenBook;
    case 31:
      return AvailableOptions.galleryPhotos;
    case 32:
      return AvailableOptions.b2bNightDelivery;
    case 33:
      return AvailableOptions.b2bNightReturn;
    case 34:
      return AvailableOptions.dismantling;

    default:
      return undefined;
  }
}

export const PRICES = {
  [AvailableOptions.insurance]: 29,
  [AvailableOptions.b2bInsurance]: 29,
  // [AvailableOptions.day]: 99,
  [AvailableOptions.b2cDay]: 119,
  [AvailableOptions.b2bDay]: 119,
  [AvailableOptions.b2cDelivery]: 29,
  [AvailableOptions.b2bDelivery]: 29,
  [AvailableOptions.deleteLogo]: 15,
  [AvailableOptions.goldenBook]: 15,
  [AvailableOptions.virtualProps]: 15,
  [AvailableOptions.props]: 15,
  [AvailableOptions.galleryPhotos]: 20,
  [AvailableOptions.deliveryCustomMade]: 79,
  [AvailableOptions.b2bNightDelivery]: 90,
  [AvailableOptions.b2bNightReturn]: 90,
  [AvailableOptions.dismantling]: 50,
  [AvailableOptions.b2cSelectDay]: 50,
};

// The list of options that price is reduce by ALL_OPTIONS_PERCENT_REDUCTION if all are selected
export const OPTIONS_THAT_CAN_BE_PROMOTIONAL = [
  AvailableOptions.deleteLogo,
  AvailableOptions.goldenBook,
  AvailableOptions.virtualProps,
  AvailableOptions.props,
  AvailableOptions.galleryPhotos,
  // AvailableOptions.dismantling,
];

export const NOT_SELECTABLE_OPTIONS = [
  AvailableOptions.insurance,
  AvailableOptions.b2bInsurance,
  AvailableOptions.b2cDelivery,
  AvailableOptions.b2bDelivery,
  // AvailableOptions.day,
  AvailableOptions.b2cDay,
  AvailableOptions.b2bDay,
  AvailableOptions.deliveryCustomMade,
  AvailableOptions.b2bNightDelivery,
  AvailableOptions.b2bNightReturn,
  AvailableOptions.dismantling,
  AvailableOptions.b2cSelectDay,
];

export const ONLY_FOR_B2B_OPTIONS = [
  AvailableOptions.dismantling,
  AvailableOptions.b2bDay,
  AvailableOptions.b2bInsurance,
  AvailableOptions.b2bDelivery,
  AvailableOptions.deliveryCustomMade,
  AvailableOptions.b2bNightDelivery,
  AvailableOptions.b2bNightReturn,
];

export const ONLY_FOR_B2C_OPTIONS = [
  AvailableOptions.b2cDelivery,
  AvailableOptions.insurance,
  AvailableOptions.b2cDay,
  AvailableOptions.b2cSelectDay,
];

export const MANDATORY_OPTIONS = [
  AvailableOptions.insurance,
  AvailableOptions.b2bInsurance,
  // AvailableOptions.b2bDelivery,
  AvailableOptions.b2cDay,
  AvailableOptions.b2bDay,
];

export const MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED = 400;

export const AVAILABLE_PRINTS: PrintDto[] = [
  {
    id: 1,
    name: `PRINTS_0`,
    desc : 'PRINT_CLASS_0',
    price: 0,
    quantity: 0,
    isActive: true,
    priceGroup: 5,
  },
  {
    id: 2,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 50,
    quantity: 100,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 2,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 50,
    quantity: 100,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 3,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 75,
    quantity: 200,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 4,
    name: 'PRINTS_300', desc: 'PRINT_CLASS_300',
    price: 100,
    quantity: 300,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 5,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 125,
    quantity: 400,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 6,
    name: 'PRINTS_500', desc: 'PRINT_CLASS_500',
    price: 200,
    quantity: 500,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 7,
    name: 'PRINTS_600', desc: 'PRINT_CLASS_600',
    price: 300,
    quantity: 600,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 8,
    name: 'PRINTS_700', desc: 'PRINT_CLASS_700',
    price: 400,
    quantity: 700,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 9,
    name: 'PRINTS_800', desc: 'PRINT_CLASS_800',
    price: 500,
    quantity: 800,
    isActive: false,
    priceGroup: 1,
  },
  {
    id: 10,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 65,
    quantity: 100,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 11,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 90,
    quantity: 200,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 12,
    name: 'PRINTS_300', desc: 'PRINT_CLASS_300',
    price: 115,
    quantity: 300,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 13,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 140,
    quantity: 400,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 14,
    name: 'PRINTS_500', desc: 'PRINT_CLASS_500',
    price: 200,
    quantity: 500,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 15,
    name: 'PRINTS_600', desc: 'PRINT_CLASS_600',
    price: 300,
    quantity: 600,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 16,
    name: 'PRINTS_700', desc: 'PRINT_CLASS_700',
    price: 400,
    quantity: 700,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 17,
    name: 'PRINTS_800', desc: 'PRINT_CLASS_800',
    price: 500,
    quantity: 800,
    isActive: false,
    priceGroup: 2,
  },
  {
    id: 18,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 75,
    quantity: 100,
    isActive: false,
    priceGroup: 3,
  },
  {
    id: 19,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 100,
    quantity: 200,
    isActive: false,
    priceGroup: 3,
  },
  {
    id: 20,
    name: 'PRINTS_300', desc: 'PRINT_CLASS_300',
    price: 125,
    quantity: 300,
    isActive: false,
    priceGroup: 3,
  },
  {
    id: 21,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 150,
    quantity: 400,
    isActive: false,
    priceGroup: 3,
  },
  {
    id: 22,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 85,
    quantity: 100,
    isActive: false,
    priceGroup: 4,
  },
  {
    id: 23,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 110,
    quantity: 200,
    isActive: false,
    priceGroup: 4,
  },
  {
    id: 24,
    name: 'PRINTS_300', desc: 'PRINT_CLASS_300',
    price: 135,
    quantity: 300,
    isActive: false,
    priceGroup: 4,
  },
  {
    id: 25,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 160,
    quantity: 400,
    isActive: false,
    priceGroup: 4,
  },
  {
    id: 26,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 101,
    quantity: 100,
    isActive: false,
    priceGroup: 5,
  },
  {
    id: 27,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 151,
    quantity: 200,
    isActive: false,
    priceGroup: 5,
  },
  {
    id: 28,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 201,
    quantity: 400,
    isActive: false,
    priceGroup: 5,
  },
  {
    id: 29,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 120,
    quantity: 100,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 30,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 180,
    quantity: 200,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 31,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 210,
    quantity: 400,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 32,
    name: 'PRINTS_500', desc: 'PRINT_CLASS_500',
    price: 200,
    quantity: 500,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 33,
    name: 'PRINTS_600', desc: 'PRINT_CLASS_600',
    price: 300,
    quantity: 600,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 34,
    name: 'PRINTS_700', desc: 'PRINT_CLASS_700',
    price: 400,
    quantity: 700,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 35,
    name: 'PRINTS_800', desc: 'PRINT_CLASS_800',
    price: 500,
    quantity: 800,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 36,
    name: 'PRINTS_900', desc: 'PRINT_CLASS_900',
    price: 600,
    quantity: 900,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 37,
    name: 'PRINTS_1000', desc: 'PRINT_CLASS_1000',
    price: 700,
    quantity: 1000,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 38,
    name: 'PRINTS_1100', desc: 'PRINT_CLASS_1100',
    price: 800,
    quantity: 1100,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 39,
    name: 'PRINTS_1200', desc: 'PRINT_CLASS_1200',
    price: 900,
    quantity: 1200,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 40,
    name: 'PRINTS_1500', desc: 'PRINT_CLASS_1500',
    price: 1200,
    quantity: 1500,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 41,
    name: 'PRINTS_2000', desc: 'PRINT_CLASS_2000',
    price: 1700,
    quantity: 2000,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 42,
    name: 'PRINTS_2500', desc: 'PRINT_CLASS_2500',
    price: 2200,
    quantity: 2500,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 43,
    name: 'PRINTS_3000', desc: 'PRINT_CLASS_3500',
    price: 2700,
    quantity: 3000,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 44,
    name: 'PRINTS_3500', desc: 'PRINT_CLASS_3500',
    price: 3200,
    quantity: 3500,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 45,
    name: 'PRINTS_4000', desc: 'PRINT_CLASS_4000',
    price: 3700,
    quantity: 4000,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 46,
    name: 'PRINTS_4500', desc: 'PRINT_CLASS_4500',
    price: 4200,
    quantity: 4500,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 47,
    name: 'PRINTS_5000', desc: 'PRINT_CLASS_5000',
    price: 4700,
    quantity: 5000,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 48,
    name: 'PRINTS_5500', desc: 'PRINT_CLASS_5500',
    price: 5200,
    quantity: 5500,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 49,
    name: 'PRINTS_6000', desc: 'PRINT_CLASS_6000',
    price: 5700,
    quantity: 6000,
    isActive: false,
    priceGroup: 6,
  },
  {
    id: 50,
    name: 'PRINTS_100', desc: 'PRINT_CLASS_100',
    price: 50,
    quantity: 100,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 51,
    name: 'PRINTS_200', desc: 'PRINT_CLASS_200',
    price: 100,
    quantity: 200,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 52,
    name: 'PRINTS_400', desc: 'PRINT_CLASS_400',
    price: 200,
    quantity: 400,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 53,
    name: 'PRINTS_500', desc: 'PRINT_CLASS_500',
    price: 250,
    quantity: 500,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 54,
    name: 'PRINTS_600', desc: 'PRINT_CLASS_600',
    price: 300,
    quantity: 600,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 55,
    name: 'PRINTS_700', desc: 'PRINT_CLASS_700',
    price: 350,
    quantity: 700,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 56,
    name: 'PRINTS_800', desc: 'PRINT_CLASS_800',
    price: 400,
    quantity: 800,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 57,
    name: 'PRINTS_900', desc: 'PRINT_CLASS_900',
    price: 450,
    quantity: 900,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 58,
    name: 'PRINTS_1000', desc: 'PRINT_CLASS_1000',
    price: 500,
    quantity: 1000,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 59,
    name: 'PRINTS_1100', desc: 'PRINT_CLASS_1100',
    price: 550,
    quantity: 1100,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 60,
    name: 'PRINTS_1200', desc: 'PRINT_CLASS_1200',
    price: 600,
    quantity: 1200,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 61,
    name: 'PRINTS_1500', desc: 'PRINT_CLASS_1500',
    price: 750,
    quantity: 1500,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 62,
    name: 'PRINTS_2000', desc: 'PRINT_CLASS_2000',
    price: 1000,
    quantity: 2000,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 63,
    name: 'PRINTS_2500', desc: 'PRINT_CLASS_2500',
    price: 1250,
    quantity: 2500,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 64,
    name: 'PRINTS_3000', desc: 'PRINT_CLASS_3500',
    price: 1500,
    quantity: 3000,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 65,
    name: 'PRINTS_3500', desc: 'PRINT_CLASS_3500',
    price: 1750,
    quantity: 3500,
    isActive: true,
    priceGroup: 7,
  },
  {
    id: 66,
    name: 'PRINTS_4000', desc: 'PRINT_CLASS_4000',
    price: 2000,
    quantity: 4000,
    isActive: true,
    priceGroup: 7,
  },
];
