<ng-container *ngIf="startDate$ | async as startDate">
  <div class="calendar-row">
    <ng-container>
      <div class="month-year-section">
        {{ 'CALENDAR.MONTHS.' + (startDate.getMonth() + 1) | translate }}
        {{ startDate?.getFullYear() }}
        <burddy-monorepo-increment-decrement
          (valueChange)="updateDaysInRange($event)"
          *ngIf="isRange"
          [(value)]="currentDaysInRange"
          [incrementLabel]="{
            singular: 'SHARED.DAY',
            plural: 'SHARED.DAYS'
          }"
          [maxValue]="maxNumberInRange"
          [minValue]="rangeMinimumNumber"
        ></burddy-monorepo-increment-decrement>
      </div>
      <div class="chevron-section">
        <div (click)="addMonthToStartDate(-1)">&lt;</div>
        <div (click)="addMonthToStartDate(1)">&gt;</div>
      </div>
    </ng-container>
  </div>
  <hr />
  <li *ngFor="let day of days" [class]="getDayNameClass(day)" class="days">
    {{ 'CALENDAR.DAYS.' + day | translate }}
  </li>
  <ng-container *ngIf="allDays$ | async as allDays">
    <li
      (click)="clickOnDate(date)"
      *ngFor="let date of allDays"
      [class]="getDateClass(date)"
      class="date"
    >
      <span *ngIf="date.getMonth() === startDate.getMonth()">
        {{ date | date : 'd' }}
      </span>
    </li>
  </ng-container>
</ng-container>
