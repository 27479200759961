import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName], [formControl]',
  standalone: true,
})
export class ShowInputErrorDirective {
  @Input() htmlElementToShowError?: HTMLElement;

  private _currentErrorDiv: any;

  constructor(
    @Self() private control: NgControl,
    private element: ElementRef,
    private render: Renderer2,
    private translateService: TranslateService,
  ) {}

  @HostListener('keyup')
  onKeyup() {
    if (!this.control?.errors) {
      this.render.removeClass(this.element.nativeElement, 'error');
      if (this._currentErrorDiv) {
        this.render.removeChild(
          this.element.nativeElement.parentNode,
          this._currentErrorDiv,
        );
        this._currentErrorDiv = undefined;
      }
    }
  }

  @HostListener('focusout')
  onBlur() {
    this.render.removeClass(this.element.nativeElement, 'error');
    if (this._currentErrorDiv) {
      this.render.removeChild(
        this.element.nativeElement.parentNode,
        this._currentErrorDiv,
      );
      this._currentErrorDiv = undefined;
    }

    if (this.control?.errors) {
      const [key, value] = this.getErrorFromControl(this.control) ?? [];
      if (!key) {
        return;
      }

      this._currentErrorDiv = this.render.createElement('small');

      const messageToShow = `${this.translateService
        .instant('SHARED.FORMS.ERRORS.' + key.toUpperCase())
        .replace(
          '{{' + key.toLowerCase() + '}}',
          value[key] ?? value['requiredLength'],
        )}`;

      this._currentErrorDiv.innerHTML = messageToShow;
      this.render.addClass(this.element.nativeElement, 'error');
      this.render.addClass(this._currentErrorDiv, 'error');
      this.render.appendChild(
        this.htmlElementToShowError ?? this.element.nativeElement.parentNode,
        this._currentErrorDiv,
      );
    }
  }

  private getErrorFromControl(control: NgControl): [string, any] | undefined {
    const errors = Object.entries(control.errors || {});
    if (!errors?.length) {
      return undefined;
    }
    return errors[0];
  }
}
