import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  PrepareBookingForm,
  SetBookingData,
} from '@burddy-monorepo/front/shared/data';
import { BookingService } from '@burddy-monorepo/front/shared/services';
import {
  LoadingComponent,
  ModalService,
} from '@burddy-monorepo/front/shared/ui-kit';
import { BookingData } from '@burddy-monorepo/shared/shared-data';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';

import { BookingRoutes } from '../features/booking/booking-routes.enum';
import { MainRoutes } from '../routes';

export function loadBookingResolve(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<BookingData> {
  const bookingService = inject(BookingService);
  const store = inject(Store);
  const modalService = inject(ModalService);
  const bookingId = route.paramMap.get('id');
  const isSuccess = route.queryParamMap.get('is-success');
  const translateService = inject(TranslateService);
  if (!bookingId) {
    store.dispatch(new Navigate([MainRoutes.BOOKING]));
  }
  let modalInstance = modalService.openModal({
    component: LoadingComponent,

    options: {
      showCloseButton: false,
      title: '',
    },
  });
  return bookingService.getBooking(+(bookingId ?? 0)).pipe(
    catchError((err) => {
      if (modalInstance) {
        modalService.closeModal(modalInstance);
        modalInstance = undefined;
      }
      if (err.status === 401) {
        return store.dispatch(new Navigate([MainRoutes.LOGIN]));
      }
      if (err.status === 404) {
        return store.dispatch(new Navigate([MainRoutes.MY_BOOKINGS]));
      }
      throw err;
    }),
    tap((_) => {
      if (modalInstance) {
        modalService.closeModal(modalInstance);
        modalInstance = undefined;
      }
      const newToDispatch =
        isSuccess === undefined
          ? [
              new Navigate([
                MainRoutes.BOOKING + '/' + BookingRoutes.SET_ADDRESS,
              ]),
            ]
          : [];

      store
        .dispatch(new SetBookingData(_))
        .pipe(
          switchMap(() => {
            return store.dispatch(new PrepareBookingForm(_?.type));
          }),
          switchMap(() => {
            if (newToDispatch?.length > 0) {
              return store.dispatch(newToDispatch);
            }
            return of(undefined);
          }),
        )
        .subscribe(() => _);
    }),
  );
}
