import { ContactFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import {
  CustomerData,
  getContactPersonRules,
} from '@burddy-monorepo/shared/shared-data';

export function contactFormGroupToContactData(
  source: ContactFormGroup,
): CustomerData {
  const rules = getContactPersonRules();
  return {
    names: source?.get(rules.names.fieldName)?.value,
    email: source?.get(rules.email.fieldName)?.value,
    phone: source?.get(rules.phone.fieldName)?.value,
  };
}
