<h3 [innerHTML]="'LOGIN.CREATE_OR_LOG.TO_CONTINUE_TITLE' | translate"></h3>
<ng-container *ngIf="data$ | async as data">
  <burddy-monorepo-alert-box
    *ngIf="!data[1] && showedInBookingFlow"
    [customIcon]="'success'"
    [type]="'warning'"
  ><p
    [innerHTML]="'LOGIN.CREATE_OR_LOG.TO_CONTINUE_WARNING_TEXT' | translate"
  ></p
  ></burddy-monorepo-alert-box>

  <burddy-monorepo-alert-box *ngIf="data[3]" [type]="'success'"
  ><p [innerHTML]="'LOGIN.CREATE_OR_LOG.ACCOUNT_CREATED' | translate"></p
  ></burddy-monorepo-alert-box>

  <burddy-monorepo-alert-box *ngIf="data[1]" [type]="'error'"
  ><p [innerHTML]="'LOGIN.CREATE_OR_LOG.BAD_LOGIN' | translate"></p
  ></burddy-monorepo-alert-box>

  <burddy-monorepo-login-form
    (connectEvent)="userIsConnected()"
    [formGroup]="data[0]"
    [title]="'LOGIN.CREATE_OR_LOG.LOGIN_FORM_TITLE' | translate"
  ></burddy-monorepo-login-form>

  <h5 [innerHTML]="'LOGIN.CREATE_OR_LOG.YOU_HAVE_NO_ACCOUNT' | translate"></h5>

  <button (click)="goToCreateAccount()" class="tertiary medium">
    {{ 'LOGIN.CREATE_OR_LOG.CREATE_ACCOUNT_BUTTON' | translate }}
  </button>

  <ng-template #createLogin>
    <burddy-monorepo-card>
      <div
        class="-column center-content -padding24 -space-between -full-height -border-box"
      >
        <burddy-monorepo-login-form
          (connectEvent)="userIsConnected()"
          [formGroup]="data[2]"
          [isForCreation]="true"
          [title]="'LOGIN.CREATE_OR_LOG.LOGIN_FORM_TITLE' | translate"
        ></burddy-monorepo-login-form>
      </div>
    </burddy-monorepo-card>
  </ng-template>
</ng-container>
