<img
  alt="Cloud Pic"
  class="clouds slow right"
  src="assets/img/icons/cloud1.svg"
/>
<img
  alt="Cloud Pic"
  class="clouds very-slow left"
  src="assets/img/icons/cloud2.svg"
/>
<img
  alt="Cloud Pic"
  class="clouds slow left"
  src="assets/img/icons/cloud3.svg"
/>
<img alt="Cloud Pic" class="clouds right" src="assets/img/icons/cloud4.svg" />
<img
  alt="Cloud Pic"
  class="clouds very-slow right"
  src="assets/img/icons/cloud5.svg"
/>
<img
  alt="Cloud Pic"
  class="clouds slow left"
  src="assets/img/icons/cloud6.svg"
/>
