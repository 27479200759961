import { BookingType } from '../../booking';
import {
  B2B_MINIMUM_DAYS_BEFORE_EVENT,
  B2C_MINIMUM_DAYS_BEFORE_EVENT,
} from '../../constants';
import { formatDate } from '../../utils';

export function validateEventDateForCreation(
  eventDate: string,
  bookingType: BookingType,
) {
  const toDay = new Date();
  switch (bookingType) {
    case 'b2b':
      return (
        eventDate >
        formatDate(
          new Date(
            toDay.setDate(toDay.getDate() + B2B_MINIMUM_DAYS_BEFORE_EVENT),
          ),
        )
      );
    case 'b2c':
      return (
        eventDate >
        formatDate(
          new Date(
            toDay.setDate(toDay.getDate() + B2C_MINIMUM_DAYS_BEFORE_EVENT),
          ),
        )
      );
    default:
      return false;
  }
}
