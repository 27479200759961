import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { LogoutCustomer } from '@burddy-monorepo/front/shared/data';
import {
  ENVIRONNEMENT,
  IEnvironment,
} from '@burddy-monorepo/front/shared/interfaces';
import { Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { catchError, Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MainRoutes } from '../../../../../../apps/front/booking/src/routes';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    @Optional() @Inject(ENVIRONNEMENT) private environment: IEnvironment,
    private store: Store,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.store.dispatch([
            new Navigate([MainRoutes.LOGIN], undefined, { replaceUrl: true }),
            new LogoutCustomer(),
          ]);
        }
        throw err;
      }),
    );
  }
}
