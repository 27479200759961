export enum APIPathCustomers {
  MAIN = 'customers',
  LOG = 'login',
  GET_MAIN_INFO = 'get-main-info',
  REGISTER = 'register',
  MODIFY_PASSWORD = 'modify-password',
  FORGOT_PASSWORD = 'forgot-password',
  UPDATE_CUSTOMER_INFO = 'update-customer-info',
  GET_BILLING_ADDRESS = 'get-billing-address',
  UPDATE_BILLING_ADDRESS = 'update-billing-address',
}

export enum APIPathBooking {
  MAIN = 'bookings',
  MY_BOOKINGS = 'my-bookings',
  CHECK_PROMO_CODE = 'check-promo-code',
  CHECK_AVAILABLE_DATE = 'check-available-date',
  GET = 'get/:id',
  DOWNLOAD_INVOICE = 'download-invoice/:id',
  SAVE_CUSTOMIZATION = 'save-customization/:id',
  UPDATE_ADDRESS = 'update-address/:id',
  GET_WAREHOUSE_ADDRESS = 'get-warehouse-address',
  SAVE_CONFIG = 'save-config/:id',
  DELETE_FRAME = 'delete-frame/:id',
  GET_PAY_URL = 'get-pay-url/:id',
}
