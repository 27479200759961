import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'burddy-clouds',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './clouds.component.html',
  styleUrls: ['./clouds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloudsComponent {}
