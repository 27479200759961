import { FormControl, FormGroup } from '@angular/forms';
import { getValidatorsBasedOnRules } from '@burddy-monorepo/front/shared/validators';
import { getAddressRules } from '@burddy-monorepo/shared/shared-data';

export interface AddressFormGroupInitValues {
  postalCode: string;
  city: string;
  streetName: string;
  country?: string;
  complementInfo?: string;
}

export class AddressFormGroup extends FormGroup {
  constructor(init?: AddressFormGroupInitValues | undefined) {
    const rules = getAddressRules();
    super({
      [rules.streetName.fieldName]: new FormControl(
        init?.streetName,
        getValidatorsBasedOnRules(rules.streetName),
      ),
      [rules.postalCode.fieldName]: new FormControl(
        init?.postalCode,
        getValidatorsBasedOnRules(rules.postalCode),
      ),
      [rules.city.fieldName]: new FormControl(
        init?.city,
        getValidatorsBasedOnRules(rules.city),
      ),
      [rules.country.fieldName]: new FormControl(
        init?.country,
        getValidatorsBasedOnRules(rules.country),
      ),
      [rules.complement.fieldName]: new FormControl(
        init?.complementInfo,
        getValidatorsBasedOnRules(rules.complement),
      ),
    });
  }
}
