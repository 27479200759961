import { FormGroup } from '@angular/forms';

export class SetLoginForm {
  public static readonly type = '[Login] SetLoginForm';

  constructor(public data: FormGroup) {}
}

export class LogCustomerWithCredentials {
  public static readonly type = '[Login] LogCustomerWithCredentials';

  constructor(public email: string, public password: string) {}
}

export class ForgotPassword {
  public static readonly type = '[Login] ForgotPassword';
  constructor(public mail: string) {}

}

export class ResetPassword {
  public static readonly type = '[Login] ResetPassword';
  constructor(public params:any) {}

}

export class LogoutCustomer {
  public static readonly type = '[Login] LogoutCustomer';
}

export class SetCreateLoginForm {
  public static readonly type = '[Login] SetCreateLoginForm';

  constructor(public data: FormGroup) {}
}

export class RegisterUser {
  public static readonly type = '[Login] RegisterUser';

  constructor(public email: string, public password: string) {}
}
