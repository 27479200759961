import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'burddy-monorepo-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() size?: 'small' | 'md' | 'lg' = 'md';
  @Input() title? = '';
  @Input() showCloseButton? = true;
  cdrSubscription?: Subscription;
  @Output() closeEvent = new EventEmitter<void>();
  @Output() submitEvent = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  close(): void {
    if (this.showCloseButton) {
      this.forceClose();
    }
  }

  forceClose(): void {
    this.elementRef.nativeElement.remove();
    if (this.cdrSubscription) {
      this.cdrSubscription.unsubscribe();
      this.cdrSubscription = undefined;
    }
    this.closeEvent.emit();
  }
}
