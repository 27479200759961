export const B2C_DAYS_TO_DISABLED = [1, 2, 3, 4];
export const B2B_DAYS_TO_DISABLED = [];

export const B2C_ALLOWED_DAYS_NUMBER = 1;
export const B2B_ALLOWED_DAYS_NUMBER = 10;

export const B2C_MINIMUM_DAYS_BEFORE_EVENT = 4;
export const B2B_MINIMUM_DAYS_BEFORE_EVENT = 4;

// Number of days before a delivery date to prepare the order
export const PREPARATION_DAYS = 3;
