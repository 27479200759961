import { AbstractControl, ValidatorFn } from '@angular/forms';
import { VAT_REGEX } from '@burddy-monorepo/shared/shared-data';

export const vatValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }
    const match = control.value.match(VAT_REGEX);
    if (!match || match.length === 0) {
      return { INVALID_VAT: true };
    }

    return null;
  };
};
