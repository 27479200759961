import { AsyncPipe, Location, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  inject,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CardComponent } from '@burddy-monorepo/front/shared/components';
import {
  ClearData, CustomerProfileFormState,
  CustomerState,
  LogoutCustomer,
  PrefillCustomerInfo,
  PrepareBookingForm,
} from '@burddy-monorepo/front/shared/data';
import {
  BadgeComponent,
  ButtonComponent,
  HeaderComponent,
  ModalService,
} from '@burddy-monorepo/front/shared/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { BookingState } from '../../data/states';
import { MainRoutes } from '../../routes';
import { BookingHeaderData } from './booking-header-data.interface';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { CustomerProfileFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import { CustomerData } from '@burddy-monorepo/shared/shared-data';

@Component({
  selector: 'burddy-monorepo-booking-header',
  standalone: true,
  imports: [
    HeaderComponent,
    ButtonComponent,
    NgIf,
    TranslateModule,
    BadgeComponent,
    CardComponent,
    AsyncPipe,
  ],
  templateUrl: './booking-header.component.html',
  styleUrls: ['./booking-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHeaderComponent implements OnInit {
  @Input() data!: BookingHeaderData;

  @ViewChild('leavingAlert') leavingAlert!: TemplateRef<any>;
  public injector = inject(Injector);
  public isMenuVisible = false;
  public isLoggedIn = false;
  public token?: string;
  private _leavingAlertModal: ComponentRef<any> | undefined;
  private _store = inject(Store);
  private _modalService = inject(ModalService);
  private _location: Location = inject(Location);
  private _router = inject(Router);

  @Select(CustomerState.customerInfo)
  public customer$!: Observable<CustomerData>;

  ngOnInit() {
    this.isLoggedIn = this._store.selectSnapshot(
      CustomerState.hasConnectedUser,
    );
  }

  askQuitBooking() {
    this.toggleMenu();
    if (this._store.selectSnapshot(BookingState.bookingForm)?.pristine) {
      this.quitBooking();
    } else {
      if (this.leavingAlert && !this._leavingAlertModal) {
        this._leavingAlertModal = this._modalService.openModal({
          template: this.leavingAlert,
          options: {
            size: 'md',
            showCloseButton: false,
            injector: this.injector,
          },
        }) as ComponentRef<any>;
      }
    }
  }

  quitBooking() {
    this._store.dispatch([
      new ClearData(),
      new Navigate([MainRoutes.BOOKING]),
      new PrepareBookingForm(),
      new PrefillCustomerInfo(),
    ]);

    this.leaveAlert();
  }

  leaveAlert() {
    if (this._leavingAlertModal) {
      this._modalService.closeModal(this._leavingAlertModal);
      this._leavingAlertModal = undefined;
    }
  }

  goBack() {
    this.toggleMenu();
    this._location.back();
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
    this.token = this._store.selectSnapshot(CustomerState.customerInfo)?.token;
  }

  askGoBookings() {
    // TODO : refactor to avoid doublon
    if (
      this._store.selectSnapshot(BookingState.bookingForm)?.pristine ||
      this._router.url.split('/')[1] == 'profile' ||
      this._router.url.split('/')[1] == 'my-bookings' ||
      this._router.url.split('/')[1] == 'login'
    ) {
      this.goMyBookings();
    } else {
      if (this.leavingAlert && !this._leavingAlertModal) {
        this._leavingAlertModal = this._modalService.openModal({
          template: this.leavingAlert,
          options: {
            size: 'md',
            showCloseButton: false,
            injector: this.injector,
          },
        }) as ComponentRef<any>;
      }
    }
  }

  goMyBookings() {
    this.toggleMenu();
    this._store.dispatch(
      new Navigate([MainRoutes.MY_BOOKINGS], undefined, { replaceUrl: true }),
    );
  }

  goToBooking() {
    this.toggleMenu();
    this._store.dispatch(
      new Navigate([MainRoutes.BOOKING], undefined, { replaceUrl: true }),
    );
  }

  gotoMyProfile() {
    this.toggleMenu();
    this._store.dispatch(
      new Navigate([MainRoutes.PROFILE], undefined, { replaceUrl: true }),
    );
  }

  logout() {
    this._store
      .dispatch([
        new Navigate([MainRoutes.LOGIN], undefined, { replaceUrl: true }),
        new LogoutCustomer(),
      ])
      .subscribe(() => {
        location.reload();
      });
  }

  goTo(url:string){
    window.location.href = url;
  }
}
