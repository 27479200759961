import { inject, Injectable } from '@angular/core';
import {
  BookingService,
  CustomersService,
} from '@burddy-monorepo/front/shared/services';
import {
  AddressData,
  BookingData,
  CustomerData,
  DeliveryAvailableCountries,
  ICustomerMainInfo,
} from '@burddy-monorepo/shared/shared-data';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, Observable, of, switchMap } from 'rxjs';

import {
  ClearUserData,
  GetCustomerBillingAddress,
  GetCustomerMainInfo,
  LoadCustomerBookings, ReLoadCustomerBookings,
  SetBillingAddress,
  SetCurrentSiteVersion,
  SetCustomerInfo, SetLoadBooking,
} from '../actions';
import { CustomerStateModel } from '../models';

const NUMBER_OF_BOOKINGS_PER_PAGE = 10;

const getDefaultValue = () => {
  return {
    info: undefined,
    bookings: undefined,
    bookingsLoadedCurrentPage: 0,
    isLoadingBookings: false,
    hasMoreBookingsToLoad: true,
    currentSiteVersion: DeliveryAvailableCountries.FR,
    billingAddress: undefined,
  };
};

@State<CustomerStateModel>({
  name: 'customer',
  defaults: getDefaultValue(),
})
@Injectable()
export class CustomerState {
  private _customersService = inject(CustomersService);
  private _store = inject(Store);
  private _bookingService = inject(BookingService);

  @Selector()
  public static bookings(state: CustomerStateModel): BookingData[] | undefined {
    return state.bookings;
  }

  @Selector()
  public static currentSiteVersion(
    state: CustomerStateModel,
  ): DeliveryAvailableCountries | undefined {
    return state?.currentSiteVersion ?? DeliveryAvailableCountries.FR;
  }

  @Selector()
  public static isLoadingBookings(state: CustomerStateModel): boolean {
    return state.isLoadingBookings ?? false;
  }

  @Selector()
  public static hasConnectedUser(state: CustomerStateModel): boolean {
    return !!state.info;
  }

  @Selector()
  public static customerInfo(
    state: CustomerStateModel,
  ): CustomerData | undefined {
    return state?.info;
  }

  @Action(SetCustomerInfo)
  public setCustomerInfo(
    { patchState }: StateContext<CustomerStateModel>,
    { data }: SetCustomerInfo,
  ): void {
    patchState({ info: data });
  }

  @Selector()
  public static customerBillingAddress(
    state: CustomerStateModel,
  ): AddressData | undefined {
    return state?.billingAddress;
  }

  @Action(SetBillingAddress)
  public setBillingAddress(
    { patchState }: StateContext<CustomerStateModel>,
    { address }: SetBillingAddress,
  ): void {
    patchState({ billingAddress: address });
  }

  @Action(SetCurrentSiteVersion)
  public setCurrentSiteVersion(
    { patchState }: StateContext<CustomerStateModel>,
    { siteVersion }: SetCurrentSiteVersion,
  ): void {
    patchState({ currentSiteVersion: siteVersion });
  }

  // Get customer billing address
  @Action(GetCustomerBillingAddress)
  public getCustomerBillingAddress(): Observable<AddressData> {
    let infoToReturn: AddressData;
    return this._customersService.getBillingAddress().pipe(
      switchMap((data) => {
        infoToReturn = data;
        return this._store.dispatch(new SetBillingAddress(data)).pipe(
          switchMap(() => {
            return of(infoToReturn);
          }),
        );
      }),
    );
  }

  @Action(GetCustomerMainInfo)
  public getCustomerMainInfo(): Observable<ICustomerMainInfo> {
    let infoToReturn: ICustomerMainInfo;
    return this._customersService.getMainInfo().pipe(
      switchMap((data) => {
        infoToReturn = data;
        return this._store
          .dispatch(
            new SetCustomerInfo({
              id: data.id,
              names: data.userName,
              email: data.email,
              phone: data.phone,
              streetAndNumber: data.streetAndNumber,
              zipCode: data.zipCode,
              city: data.city,
              country: data.country,
              language: data.language,
              token : data.token
            }),
          )
          .pipe(
            switchMap(() => {
              return of(infoToReturn);
            }),
          );
      }),
    );
  }

  @Action(ClearUserData)
  public clearUserData({ setState }: StateContext<CustomerStateModel>): void {
    setState(getDefaultValue());
  }

  @Action(SetLoadBooking)
  public setLoadBooking(
    ctx: StateContext<CustomerStateModel>,
    { load }: SetLoadBooking,
  ): void {
    ctx.patchState({ isLoadingBookings: load });
  }
  @Action(LoadCustomerBookings)
  public loadCustomerBookings(ctx: StateContext<CustomerStateModel>): void {
    if (ctx.getState().hasMoreBookingsToLoad) {
      ctx.patchState({ isLoadingBookings: true });
      this._bookingService
        .loadMyBookings({
          numberPerPage: NUMBER_OF_BOOKINGS_PER_PAGE,
          currentPage: ctx.getState().bookingsLoadedCurrentPage ?? 0,
          withConfigs: true,
          withPrintInfo: true,
          withPropsInfo: true,
        })
        .pipe(
          catchError((error) => {
            ctx.patchState({ isLoadingBookings: false });
            throw error;
          }),
        )
        .subscribe((_response) => {
          const { bookings, bookingsLoadedCurrentPage } = ctx.getState();
          ctx.patchState({
            bookingsLoadedCurrentPage: (bookingsLoadedCurrentPage ?? 0) + 1,
            bookings: (bookings ?? []).concat(_response?.bookings ?? []),
            hasMoreBookingsToLoad: _response?.hasMore,
            isLoadingBookings: false,
          });
        });
    }
  }

  @Action(ReLoadCustomerBookings)
  public reloadCustomerBookings(ctx: StateContext<CustomerStateModel>): void {
      ctx.patchState({ isLoadingBookings: true });
      this._bookingService
        .loadMyBookings({
          numberPerPage: NUMBER_OF_BOOKINGS_PER_PAGE,
          currentPage: 0,
          withConfigs: true,
          withPrintInfo: true,
          withPropsInfo: true,
        })
        .pipe(
          catchError((error) => {
            ctx.patchState({ isLoadingBookings: false });
            throw error;
          }),
        )
        .subscribe((_response) => {
          ctx.patchState({
            bookingsLoadedCurrentPage: 0,
            bookings: _response?.bookings ?? [],
            hasMoreBookingsToLoad: _response?.hasMore,
            isLoadingBookings: false,
          });
        });
  }
}
