import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookiesEnum } from '@burddy-monorepo/front/shared/enums';
import { LoginFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import { CustomersService } from '@burddy-monorepo/front/shared/services';
import { getCookie } from '@burddy-monorepo/front/shared/utils';
import {
  DeliveryAvailableCountries,
  Languages,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

import {
  ClearBookingCustomizationData,
  ClearUserData, ForgotPassword,
  LogCustomerWithCredentials,
  LogoutCustomer,
  RegisterUser, ResetPassword,
  SetBookingData,
  SetCustomerInfo,
  SetLoginForm,
} from '../actions';
import { LoginStateModel } from '../models';
import { CustomerState } from './customer.state';

@State<LoginStateModel>({
  name: 'login',
  defaults: {
    loginForm: undefined,
    showBadCredentialsMessage: false,
    callingService: false,
    isSuccessRegistration: false,
    jwt: undefined,
  },
})
@Injectable()
export class LoginState {
  private _customersService = inject(CustomersService);
  private _store = inject(Store);
  private _cookieService = inject(CookieService);
  private _translateService = inject(TranslateService);

  @Selector()
  public static loginFormGroup(state: LoginStateModel): FormGroup | undefined {
    return state.loginForm?.get('loginForm') as LoginFormGroup | undefined;
  }

  @Selector()
  public static isSuccessRegistration(
    state: LoginStateModel,
  ): boolean | undefined {
    return state?.isSuccessRegistration;
  }

  @Selector()
  public static createLoginFormGroup(
    state: LoginStateModel,
  ): FormGroup | undefined {
    return state.loginForm?.get('createLoginForm') as
      | LoginFormGroup
      | undefined;
  }

  @Selector()
  public static showBadCredentialsMessage(
    state: LoginStateModel,
  ): boolean | undefined {
    return state.showBadCredentialsMessage;
  }

  @Selector()
  public static callingService(state: LoginStateModel): boolean | undefined {
    return state.callingService;
  }

  @Selector()
  public static jwt(state: LoginStateModel): string | undefined {
    return state.jwt || getCookie(CookiesEnum.CUSTOMER_JWT);
  }

  @Action(SetLoginForm)
  public setLoginForm(
    { patchState }: StateContext<LoginStateModel>,
    { data }: SetLoginForm,
  ): void {
    patchState({ loginForm: data });
  }

  @Action(LogoutCustomer)
  public LogoutCustomer(): void {
    this._store.dispatch([
      new ClearUserData(),
      new ClearBookingCustomizationData(),
      new SetBookingData({}),
      new SetCustomerInfo({}),
    ]);

    this._cookieService.delete(CookiesEnum.CUSTOMER_JWT);
    document.cookie = `${CookiesEnum.CUSTOMER_JWT}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    // remove all state
    this._store.reset({
      callingService: false,
      jwt: undefined,
      loginForm: undefined,
    });
  }

  @Action(ForgotPassword)
  public ForgotPassword(
    { patchState }: StateContext<LoginStateModel>,
    { mail }: ForgotPassword){
    this._customersService.forgotPassword(mail).subscribe()
  }

  @Action(ResetPassword)
  public ResetPassword(
    { patchState }: StateContext<LoginStateModel>,
    { params }: ResetPassword){
    this._customersService.modifyPassword({ oldPassword:params.password,newPassword:params.password,uuid:params.uuid }).subscribe();
  }



  @Action(RegisterUser)
  public registerUser(
    { patchState }: StateContext<LoginStateModel>,
    { email, password }: LogCustomerWithCredentials,
  ) {
    patchState({ callingService: true, isSuccessRegistration: false });
    this._customersService
      .register({
        email,
        password,
        language: this._translateService.currentLang.toUpperCase() as Languages,
        siteVersion: this._store.selectSnapshot(
          CustomerState.currentSiteVersion,
        ) as DeliveryAvailableCountries,
      })
      .pipe(
        tap(() => {
          patchState({ callingService: false, isSuccessRegistration: true });
        }),
      )
      .subscribe();
  }

  @Action(LogCustomerWithCredentials)
  public logCustomerWithCredentials(
    { patchState }: StateContext<LoginStateModel>,
    { email, password }: LogCustomerWithCredentials,
  ): Observable<void> {
    patchState({ showBadCredentialsMessage: false, callingService: true });
    return this._customersService.connectUser({ email, password }).pipe(
      tap((logInfo) => {
        patchState({ callingService: false, jwt: logInfo.jwt });
      }),
      catchError((err) => {
        if (err.status === 404) {
          patchState({
            showBadCredentialsMessage: true,
            callingService: false,
          });
        }
        return throwError(() => new Error(err));
      }),
      tap((_) => {
        if (_) {
          this._store.dispatch([
            new SetCustomerInfo({
              id: _.customer.id,
              email: _.customer.email,
              names: _.customer.userName,
              streetAndNumber: _.customer.streetAndNumber,
              zipCode: _.customer.zipCode,
              city: _.customer.city,
              country: _.customer.country,
              token:_.token
            }),
          ]);
        }
      }),
      map(() => undefined),
    );
  }
}
