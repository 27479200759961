import { FormGroup } from '@angular/forms';
import {
  AvailableOptions, BookingConfigData,
  BookingData,
  BookingType,
  PrintDto,
} from '@burddy-monorepo/shared/shared-data';

export type SetOptionsPayload = {
  option: AvailableOptions;
  quantity: number;
};

export class SetBookingType {
  public static readonly type = '[Booking] SetBookingType';

  constructor(public data: BookingType) {}
}

export class SetBookingFormGroup {
  public static readonly type = '[Booking] SetBookingFormGroup';

  constructor(public data: FormGroup) {}
}

export class AddReturnAddressFormGroup {
  public static readonly type = '[Booking] AddReturnAddressFormGroup';
}

export class SetReturnAddressFormGroup {
  public static readonly type = '[Booking] SetReturnAddressFormGroup';

  constructor(public returnAddress: any) {}
}

export class RemoveReturnAddressFormGroup {
  public static readonly type = '[Booking] RemoveReturnAddressFormGroup';
}

export class SetBookingStep {
  public static readonly type = '[Booking] SetBookingStep';

  constructor(public data: number) {}
}

export class VerifyAddress {
  public static readonly type = '[Booking] VerifyAddress';
}

export class GetWarehouseAddress {
  public static readonly type = '[Booking] GetWarehouseAddress';
}

export class DeleteFrame {
  public static readonly type = '[Booking] DeleteFrame';
  constructor(public frame: any) {}
}

export class UpdateFrame {
  public static readonly type = '[Booking] UpdateFrame';
}

export class GoNextStep {
  public static readonly type = '[Booking] GoNextStep';
}

export class ClickOnPrimaryButton {
  public static readonly type = '[Booking] ClickOnPrimaryButton';
}

export class InitBooking {
  public static readonly type = '[Booking] InitBooking';

  constructor(public bookingType?: BookingType) {}
}

export class VerifyDate {
  public static readonly type = '[Booking] VerifyDate';
}

export class SetOptions {
  public static readonly type = '[Booking] SetOptions';

  constructor(public data: SetOptionsPayload[]) {}
}


export class SelectStandardDelivery {
  public static readonly type = '[Booking] SelectStandardDelivery';

  constructor(public data: boolean) {}
}

export class UpdateForm {
  public static readonly type = '[Booking] UpdateForm';
}

export class SetPrints {
  public static readonly type = '[Booking] SetPrints';

  constructor(public selectedPrint: PrintDto) {}
}

export class SetIsLoading {
  public static readonly type = '[Booking] SetIsLoading';

  constructor(public isLoading: boolean) {}
}

export class SetPossibleDeliveryDates {
  public static readonly type = '[Booking] SetPossibleDeliveryDates';

  constructor(public dates: string[]) {}
}

export class SetPossibleReturnDates {
  public static readonly type = '[Booking] SetPossibleReturnDates';

  constructor(public dates: string[]) {}
}

export class SetDuration {
  public static readonly type = '[Booking] SetDuration';

  constructor(public duration: number) {}
}

export class SetMandatoryOptions {
  public static readonly type = '[Booking] SetMandatoryOptions';
}

export class ClearData {
  public static readonly type = '[Booking] ClearData';
}

export class DeleteBooking {
  public static readonly type = '[Booking] DeleteBooking';

  constructor(public bookingId:string ) {}
}

export class PrepareBookingForm {
  public static readonly type = '[Booking] PrepareBookingForm';

  constructor(public bookingType?: BookingType) {}
}

export class CheckPromoCode {
  public static readonly type = '[Booking] CheckPromoCode';

  constructor(public code: string) {}
}

export class GetIsActive {
  public static readonly type = '[Booking] GetIsActive';
}

export class SaveOrder {
  public static readonly type = '[Booking] SaveOrder';
}

export class PayOrder {
  public static readonly type = '[Booking] PayOrder';

  constructor(public bookingId: string) {}
}

export class RemovePromoCode {
  public static readonly type = '[Booking] RemovePromoCode';
}

export class PrefillCustomerInfo {
  public static readonly type = '[Booking] PrefillCustomerInfo';
}

export class InitWithBookingInfo {
  public static readonly type = '[Booking] InitWithBookingInfo';

  constructor(public bookingData: BookingData) {}
}

export class SetCurrentStep {
  public static readonly type = '[Booking] SetCurrentStep';

  constructor(public step: number) {}
}

export class SetBookingData {
  public static readonly type = '[Booking] SetBookingData';

  constructor(public bookingData: BookingData) {}
}
