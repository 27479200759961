import { Languages } from '../../enums';

export function generateRandomId(maxSize = 13, withoutDashes = false): string {
  const characters = '0123456789abcdef';
  let id = '';
  for (let i = 0; i < maxSize; i++) {
    id += characters[Math.floor(Math.random() * characters.length)];
    if (!withoutDashes && (i === 2 || i === 5 || i === 10)) {
      id += '-';
    }
  }
  return id;
}

export function generateGuid(): string {
  const characters = '0123456789abcdef';
  let id = '';
  for (let i = 0; i < 32; i++) {
    id += characters[Math.floor(Math.random() * characters.length)];
  }
  return id;
}

export function mapDBLanguageOnLanguage(language: string): Languages {
  switch (language.toLocaleLowerCase()) {
    case 'fr':
    case 'français':
    case 'french':
      return Languages.FR;
    case 'nl':
    case 'néerlandais':
    case 'dutch':
    case 'nederlands':
      return Languages.NL;
    case 'en':
    case 'anglais':
    case 'english':
      return Languages.EN;
    default:
      return Languages.FR;
  }
}
