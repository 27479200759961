import { IEnvironment } from '@burddy-monorepo/front/shared/interfaces';

export const environment: IEnvironment = {
  mainHostUrl: 'https://wpdev.burddy.*DOMAIN*/connected',
  bookingApiUrl: 'https://apidev.burddy.com/api-v2',
  production: false,
  apiConfigToolUrl: 'https://designdev.burddy.com',
  basePath: './',
  remoteConfigToolsScriptUrls: [
    '/js/fontfaceobserver.js',
    '/js/cadre.js?eeeezzredzeredeee',
  ],
};
