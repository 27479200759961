import { inject, Injectable } from '@angular/core';
import { BookingCustomizationFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import { BookingService } from '@burddy-monorepo/front/shared/services';
import { BookingConfigData } from '@burddy-monorepo/shared/shared-data';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError } from 'rxjs';

import {
  ClearBookingCustomizationData,
  InitBookingCustomizationFormGroup,
  Reset,
  SaveCustomization,
  SetConfigFromBooking,
  SetConfigFromBookingId,
} from '../actions';
import { BookingCustomizationStateModel } from '../models/booking-customization.model';

const getDefaultValues = (): BookingCustomizationStateModel => ({
  customizationFormGroup: undefined,
  relatedBookingId: undefined,
  callingService: false,
});

@State<BookingCustomizationStateModel>({
  name: 'bookingCustomization',
  defaults: getDefaultValues(),
})
@Injectable()
export class BookingCustomizationState {
  private _store = inject(Store);
  private _bookingService = inject(BookingService);

  @Selector()
  public static canSave(state: BookingCustomizationStateModel): boolean {
    return !!state?.canEdit && !state.callingService;
  }

  @Selector()
  public static customizationFormGroup(
    state: BookingCustomizationStateModel,
  ): BookingCustomizationFormGroup | undefined {
    return state.customizationFormGroup;
  }

  @Selector()
  public static callingService(
    state: BookingCustomizationStateModel,
  ): boolean | undefined {
    return state.callingService;
  }

  @Selector()
  public static canNavigateToEdition(
    state: BookingCustomizationStateModel,
  ): boolean {
    return !!state.relatedBookingId && !!state.configs;
  }

  @Selector()
  public static configs(
    state: BookingCustomizationStateModel,
  ): BookingConfigData[] | undefined {
    return state.configs;
  }

  @Selector()
  public static frames(
    state: BookingCustomizationStateModel,
  ): BookingConfigData[] | undefined {
    return state.configs?.filter((_) => !_.isOriginalConfig) ?? [];
  }

  @Selector()
  public static relatedBookingId(
    state: BookingCustomizationStateModel,
  ): string | undefined {
    return state?.relatedBookingId;
  }

  @Action(SetConfigFromBooking)
  public setConfigFromBooking(
    ctx: StateContext<BookingCustomizationStateModel>,
    action: SetConfigFromBooking,
  ): void {
    ctx.patchState({
      relatedBookingId: action.booking.id,
      configs: action.booking.configs,
      canEdit:
        (action.booking.selectedEventDate?.getTime() ?? 0) >=
        new Date().getTime(),
    });
    if (action.booking?.configs) {
      this._store.dispatch(
        new InitBookingCustomizationFormGroup(
          action.booking.configs?.find((_) => _.isOriginalConfig) ??
            action.booking.configs[0],
        ),
      );
    }
  }

  @Action(Reset)
  public reset(ctx: StateContext<BookingCustomizationStateModel>): void {
    ctx.setState(getDefaultValues());
  }

  @Action(InitBookingCustomizationFormGroup)
  public initBookingCustomizationFormGroup(
    ctx: StateContext<BookingCustomizationStateModel>,
    action: InitBookingCustomizationFormGroup,
  ): void {
    ctx.patchState({
      customizationFormGroup: new BookingCustomizationFormGroup(
        action.customization
          ? {
              eventName: action.customization.eventName ?? '',
              numberOfPrints: action.customization.numberOfPrints ?? 1,
              wantSendEmail: !!action.customization.wantEmail,
              mailObject: action.customization.mailObject ?? '',
              mailContent: action.customization.mailContent ?? '',
            }
          : undefined,
      ),
    });
  }

  @Action(ClearBookingCustomizationData)
  public clearBookingCustomizationData(
    ctx: StateContext<BookingCustomizationStateModel>,
  ): void {
    ctx.setState(getDefaultValues());
  }

  @Action(SetConfigFromBookingId)
  public setConfigFromBookingId(
    ctx: StateContext<BookingCustomizationStateModel>,
    { bookingId }: SetConfigFromBookingId,
  ): void {
    this._store.dispatch(new Reset()).subscribe(() => {
      ctx.patchState({
        callingService: true,
      });
      this._bookingService
        .loadMyBookings({ id: bookingId, withConfigs: true })
        .pipe(
          catchError((error) => {
            ctx.patchState({
              callingService: false,
            });
            throw error;
          }),
        )
        .subscribe((response) => {
          ctx.patchState({
            callingService: false,
          });
          if (response.bookings?.length) {
            ctx.dispatch(new SetConfigFromBooking(response.bookings[0]));
          }
        });
    });
  }

  @Action(SaveCustomization)
  public saveCustomization(
    ctx: StateContext<BookingCustomizationStateModel>,
  ): void {
    ctx.patchState({
      callingService: true,
    });
    this._bookingService
      .saveBookingCustomization(
        +(ctx.getState().relatedBookingId ?? 0),
        ctx.getState().customizationFormGroup?.value,
      )
      .pipe(
        catchError((error) => {
          ctx.patchState({
            callingService: false,
          });
          throw error;
        }),
      )
      .subscribe(() => {
        ctx.patchState({
          callingService: false,
        });
      });
  }
}
