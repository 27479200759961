import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  GetCustomerBillingAddress,
  GetCustomerMainInfo,
  GoNextStep,
  LoginState,
  PrefillCustomerInfo,
} from '@burddy-monorepo/front/shared/data';
import {
  AlertBoxComponent,
  CardComponent,
  ModalService,
} from '@burddy-monorepo/front/shared/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { catchError, combineLatest, Observable, of, tap } from 'rxjs';

import { LoginFormComponent } from '../../forms/login-form/login-form.component';

@Component({
  selector: 'burddy-monorepo-log-or-create',
  standalone: true,
  imports: [
    TranslateModule,
    AlertBoxComponent,
    LoginFormComponent,
    NgIf,
    AsyncPipe,
    CardComponent,
  ],
  templateUrl: './log-or-create.component.html',
  styleUrls: ['./log-or-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogOrCreateComponent implements OnInit {
  @Select(LoginState.loginFormGroup)
  public loginFormGroup$!: Observable<FormGroup>;

  @Select(LoginState.createLoginFormGroup)
  public createLoginFormGroup$!: Observable<FormGroup>;

  @Select(LoginState.showBadCredentialsMessage)
  showBadCredentialsMessage$!: Observable<boolean | undefined>;

  @Select(LoginState.isSuccessRegistration)
  isSuccessRegistration$!: Observable<boolean | undefined>;

  @ViewChild('createLogin') createLoginForm!: TemplateRef<LoginFormComponent>;

  @Input() showedInBookingFlow = false;

  public data$!: Observable<
    [FormGroup, boolean | undefined, FormGroup, boolean | undefined]
  >;

  private _store = inject(Store);
  private _modalForm!: ComponentRef<any> | undefined;
  private _modalService = inject(ModalService);

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.loginFormGroup$,
      this.showBadCredentialsMessage$,
      this.createLoginFormGroup$,
      this.isSuccessRegistration$,
    ]).pipe(
      tap((data) => {
        if (data[3] && this._modalForm) {
          this._modalService.closeModal(this._modalForm);
          this._modalForm = undefined;
        }
      }),
    );
  }

  userIsConnected() {
    if (this._modalForm) {
      this._modalService.closeModal(this._modalForm);
      this._modalForm = undefined;
    }

    if (this.showedInBookingFlow) {
      this._store
        .dispatch([new GetCustomerMainInfo(), new GetCustomerBillingAddress()])
        .pipe(
          catchError(() => {
            return of();
          }),
        )
        .subscribe(() => {
          this._store.dispatch([new PrefillCustomerInfo(), new GoNextStep()]);
        });
    } else {
      this._store.dispatch(new Navigate(['my-bookings'])); // TODO : migrate from lib
    }
  }

  goToCreateAccount() {
    this._modalForm = this._modalService.openModal({
      template: this.createLoginForm,
    }) as ComponentRef<any>;
  }
}
