import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BookingState } from 'apps/front/booking/src/data/states';
import { BookingRoutes } from 'apps/front/booking/src/features/booking/booking-routes.enum';
import { MainRoutes } from 'apps/front/booking/src/routes';

export const bookingTypeIsSelectedGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  if (!store.selectSnapshot(BookingState.bookingType)) {
    return router.createUrlTree([
      `${MainRoutes.BOOKING}/${BookingRoutes.SELECT_PRO_OR_PRIVATE}`,
    ]);
  }

  // TODO : uncomment this when booking flow is ready and fix bug navigation from card resume
  // if (!store.selectSnapshot(BookingState.currentStepIsValid)) {
  //   return router.createUrlTree([
  //     `${MainRoutes.BOOKING}/${BookingRoutes.SET_ADDRESS}`,
  //   ]);
  // }
  return true;
};
