import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowInputErrorDirective } from '@burddy-monorepo/front/shared/directives';
import { AddressFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import {
  DeliveryAvailableCountries,
  getAddressRules,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'burddy-monorepo-address-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    ShowInputErrorDirective,
    NgFor,
    FormsModule,
  ],
  providers: [FormGroupDirective, NgFor],
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  @Input()
  formGroup!: AddressFormGroup;

  @Input()
  showComplement?: boolean;

  public addressRules = getAddressRules();
  public availableCountries = Object.values(DeliveryAvailableCountries);

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (!this.formGroup) {
      throw new Error(`Form group not found`);
    }

    if (!(this.formGroup instanceof AddressFormGroup)) {
      throw new Error(`Form group is not an instance of AddressFormGroup`);
    }

    this.formGroup.addControl('fakeZipCode', new FormControl(this.formGroup.get(this.addressRules.postalCode.fieldName)?.value),{emitEvent:false});
    this.formGroup
      .get(this.addressRules.postalCode.fieldName)?.valueChanges.subscribe((value) => {
      this.formGroup
        .get('fakeZipCode')
        ?.setValue(value,{emitEvent:false});
    });
    this.formGroup
      .get('fakeZipCode')?.valueChanges.subscribe((value) => {
      this.formGroup
        .get(this.addressRules.postalCode.fieldName)
        ?.setValue(value.indexOf('-') > -1 ? value.split('-')[1] : value,{emitEvent:false});
    });
  }
}
