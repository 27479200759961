<div class="main-block">
  <burddy-monorepo-select-option-bloc
    (click)="clickBlock(print)"
    *ngFor="let print of availablePrintsNotCustomized"
    [isSelected]="selectedPrint?.id === print?.id"
    [price]="print.price"
    [title]="'PRINTS.' + print.name | translate"
    [description]="'PRINTS.' + print.desc | translate"
  ></burddy-monorepo-select-option-bloc>
  <div
    (click)="showCustomPrints()"
    *ngIf="morePrints"
    [ngClass]="{
      selected:
        (selectedPrint?.quantity ?? 0) > MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED
    }"
    class="-row clickable"
  >
    <span>{{
        'BOOKING.SET_OPTIONS.I_WANT_CUSTOMIZED_PRINTS' | translate : {
          prints: MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED,
        }
      }}</span>
    <burddy-monorepo-badge class="accent small">{{
        'BOOKING.SET_OPTIONS.FROM_250' | translate
      }}
    </burddy-monorepo-badge>
  </div>
</div>
