import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { CookiesEnum } from '@burddy-monorepo/front/shared/enums';
import {
  ENVIRONNEMENT,
  IEnvironment,
} from '@burddy-monorepo/front/shared/interfaces';
import {
  AddressData,
  APIPathCustomers,
  ICustomerMainInfo,
  LogCustomerInDto,
  LogCustomerOutDto,
  ModifyPasswordInDto,
  RegisterRequestInDto,
  UpdateCustomerInfoInDto,
} from '@burddy-monorepo/shared/shared-data';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private _http = inject(HttpClient);

  constructor(@Inject(ENVIRONNEMENT) private environment: IEnvironment) {}

  connectUser(data: LogCustomerInDto): Observable<LogCustomerOutDto> {
    return this._http
      .post<LogCustomerOutDto>(
        `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.LOG}`,
        data,
      )
      .pipe(
        tap((logInfo) => {
          document.cookie = `${CookiesEnum.CUSTOMER_JWT}=${
            logInfo.jwt
          }; expires=${new Date(
            logInfo.jwtExpirationDate,
          )}; SameSite=None; Secure; path=/`;
        }),
      );
  }

  register(data: RegisterRequestInDto): Observable<void> {
    return this._http.post<void>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.REGISTER}`,
      data,
    );
  }

  getMainInfo(): Observable<ICustomerMainInfo> {
    return this._http.get<ICustomerMainInfo>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.GET_MAIN_INFO}`,
    );
  }

  modifyPassword(data: ModifyPasswordInDto): Observable<void> {
    return this._http.put<void>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.MODIFY_PASSWORD}`,
      data,
    );
  }

  forgotPassword(mail:string): Observable<void> {
    return this._http.post<void>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.FORGOT_PASSWORD}`,
      { mail },
    );
  }

  updateCustomer(data: UpdateCustomerInfoInDto): Observable<void> {
    return this._http.put<void>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.UPDATE_CUSTOMER_INFO}`,
      data,
    );
  }

  getBillingAddress(): Observable<AddressData> {
    return this._http.get<AddressData>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.GET_BILLING_ADDRESS}`,
    );
  }

  updateBillingAddress(data: AddressData): Observable<void> {
    return this._http.put<void>(
      `${this.environment.bookingApiUrl}/${APIPathCustomers.MAIN}/${APIPathCustomers.UPDATE_BILLING_ADDRESS}`,
      data,
    );
  }
}
