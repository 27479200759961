import { NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'burddy-switch',
  standalone: true,
  imports: [NgIf],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SwitchComponent, multi: true },
  ],
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() public label!: string;
  @Input() public disabled?: boolean;
  @Input() public alignement?: 'left' | 'center' | 'right' = 'center';

  public checked = false;

  @ViewChild('input', { static: false }) input!: ElementRef;

  onChange!: (...arg: unknown[]) => unknown;
  onTouched!: (...arg: unknown[]) => unknown;

  writeValue(obj: any): void {
    this.checked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeValue() {
    this.checked = !this.checked;
    if (this.onChange) {
      this.onChange(this.checked);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
