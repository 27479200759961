import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { SetBookingStep } from '@burddy-monorepo/front/shared/data';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MainRoutes } from 'apps/front/booking/src/routes';

import { SetHeaderAction } from '../data/actions/header.action';
import { BookingHeaderData } from '../features';
import { BookingRoutes } from '../features/booking/booking-routes.enum';

const getHeaderDataBasedOnRoute = (
  route: ActivatedRouteSnapshot,
): BookingHeaderData => {
  if (route?.url?.length < 1) {
    return {};
  }
  switch (route.url[0].path) {
    case MainRoutes.LOGIN:
    case MainRoutes.PROFILE:
    case MainRoutes.CUSTOMIZE_BOOKING.split('/')[0]:
      return {
        isShown: true,
        leftSection: {
          showFullBurddyLogo: true,
        },
      };

    case MainRoutes.MY_BOOKINGS:
      return {
        isShown: true,
        leftSection: {
          showFullBurddyLogo: true,
        },
        rightSection: {
          showBookingButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SELECT_PRO_OR_PRIVATE:
      inject(Store).dispatch(new SetBookingStep(0));
      return {
        isShown: true,
        leftSection: {
          showFullBurddyLogo: true,
        },
        rightSection: {
          showBookingButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SET_ADDRESS:
      inject(Store).dispatch(new SetBookingStep(1));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SET_DATE:
      inject(Store).dispatch(new SetBookingStep(2));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SET_HOURS:
      inject(Store).dispatch(new SetBookingStep(2.5));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SET_OPTIONS:
      inject(Store).dispatch(new SetBookingStep(3));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.LOG_CUSTOMER:
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SET_CONTACTS:
      inject(Store).dispatch(new SetBookingStep(4));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.SET_INVOICE:
      inject(Store).dispatch(new SetBookingStep(5));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.CONFIRMATION:
      inject(Store).dispatch(new SetBookingStep(6));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    case BookingRoutes.DETAILS:
      inject(Store).dispatch(new SetBookingStep(7));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };

    case BookingRoutes.BOOKING_RESUME:
      inject(Store).dispatch(new SetBookingStep(8));
      return {
        isShown: true,
        leftSection: {
          showBackButton: true,
          title: inject(TranslateService).instant('BOOKING.BOOK_A_PHOTOBOOTH'),
        },
        rightSection: {
          showCloseButton: true,
          showMyBookingsButton: true,
        },
      };
    default:
      return { isShown: false };
  }
};
export const headerResolver: ResolveFn<boolean> = (route, state) => {
  inject(Store).dispatch(new SetHeaderAction(getHeaderDataBasedOnRoute(route)));
  return true;
};
