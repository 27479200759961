import { CustomerProfileFormGroupInitValues } from '@burddy-monorepo/front/shared/form-groups';

export class ModifyPassword {
  public static readonly type = '[CustomerProfileForm] ModifyPassword';
}

export class InitFormGroup {
  public static readonly type = '[CustomerProfileForm] InitFormGroup';

  constructor(public initData: CustomerProfileFormGroupInitValues) {}
}

export class SaveInfo {
  public static readonly type = '[CustomerProfileForm] SaveInfo';
}

export class SaveBillingAddress {
  public static readonly type = '[CustomerProfileForm] SaveBillingAddress';
}
