<ng-container *ngIf="data">
  <div *ngFor="let step of iterateArray" class="step">
    <div
      [ngClass]="{
        primary: step === data.currentStep,
        accent: step !== data.currentStep,
        passed: step < data.currentStep
      }"
      class="rectangle"
    ></div>
    <div class="-row center-content">
      <div
        [ngClass]="{
          primary: step === data.currentStep,
          accent: step !== data.currentStep,
          passed: step < data.currentStep
        }"
        class="stepNumber"
      >
        <ng-container *ngIf="step < data.currentStep; else stepNumber">
          <img src="assets/img/icons/check.svg" />
        </ng-container>
        <ng-template #stepNumber>
          <span>{{ step }}</span>
        </ng-template>
      </div>
      <div
        *ngIf="(data.stepLabels?.length ?? 0) >= step"
        class="-mobile-hidden"
      >
        <h5
          [innerHTML]="data.stepLabels ? data.stepLabels[step - 1] : ''"
          [ngClass]="{
            accent: step !== data.currentStep,
            passed: step < data.currentStep
          }"
        ></h5>
      </div>
    </div>
  </div>
</ng-container>
