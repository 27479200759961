import {
  AsyncPipe,
  DatePipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { compareDate, DateRange } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';

import { IncrementDecrementComponent } from '../increment-decrement/increment-decrement.component';

const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'burddy-monorepo-calendar',
  standalone: true,
  imports: [
    NgFor,
    DatePipe,
    AsyncPipe,
    NgIf,
    JsonPipe,
    NgClass,
    TranslateModule,
    IncrementDecrementComponent,
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CalendarComponent, multi: true },
  ],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() date = new Date();
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() maxNumberInRange?: number;
  @Input() weekDaysToDisable: number[] = [];
  @Input() isRange = false;
  @Input() rangeMinimumNumber = 1;
  @Input() selectedRangeInit?: DateRange;
  @Output() selectedRange = new EventEmitter<DateRange>();

  public days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  public calendarStartDay$ = new BehaviorSubject<Date | undefined>(undefined);
  public allDays$ = new BehaviorSubject<Array<Date>>([]);
  public calendarDays$ = new Subject<Array<Date>>();
  public startDate$ = new BehaviorSubject<Date | undefined>(undefined);
  public dateOfTheDay = new Date();
  public currentDaysInRange = 0;
  public onChange!: (...arg: unknown[]) => unknown;
  public onTouched!: (...arg: unknown[]) => unknown;

  private _destroyed$ = new Subject<void>();
  private _selectedRange: DateRange | undefined;

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['date']?.currentValue !== changes['date']?.previousValue) {
      this._startDateChanges();
    }
  }

  public writeValue(obj: DateRange): void {
    this._selectedRange = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public changeValue() {
    this.onChange(this._selectedRange);
    this.onTouched();
  }

  public clickOnDate(date: Date) {
    if (this.checkIsDisabled(date)) {
      return;
    }
    // if no range, create and select
    if (!this._selectedRange || !this.isRange) {
      this._selectedRange = {
        startDate: date,
        endDate: date,
      };
      this.currentDaysInRange = 1;
      return this.emitNewValue();
    }

    if (compareDate(this._selectedRange.startDate, date) === 0) {
      if (
        this._selectedRange.endDate ||
        compareDate(
          this._selectedRange.startDate,
          this._selectedRange.endDate,
        ) === 0
      ) {
        this._selectedRange = undefined;
        this.currentDaysInRange = 0;
        return this.emitNewValue();
      }
    }

    if (compareDate(this._selectedRange.startDate, date) === 0) {
      this._selectedRange.startDate = this._selectedRange.endDate;
      this.currentDaysInRange =
        Math.abs(
          compareDate(
            this._selectedRange.startDate,
            this._selectedRange.endDate,
          ),
        ) + 1;

      return this.emitNewValue();
    }

    if (compareDate(this._selectedRange.endDate, date) === 0) {
      this._selectedRange.endDate = this._selectedRange.startDate;
      this.currentDaysInRange =
        Math.abs(
          compareDate(
            this._selectedRange.startDate,
            this._selectedRange.endDate,
          ),
        ) + 1;
      return this.emitNewValue();
    }
    // Select a most recent date
    if (
      this._selectedRange.startDate &&
      compareDate(this._selectedRange.startDate, date) > 0
    ) {
      if (
        !this.maxNumberInRange ||
        (this.maxNumberInRange &&
          compareDate(this._selectedRange.startDate, date) <
            this.maxNumberInRange)
      ) {
        this._selectedRange.endDate = date;
      }
    } else {
      // Select an older date
      if (
        this._selectedRange.startDate &&
        compareDate(this._selectedRange.startDate, date) < 0
      ) {
        if (
          !this.maxNumberInRange ||
          compareDate(date, this._selectedRange.endDate) < this.maxNumberInRange
        ) {
          this._selectedRange.startDate = date;
        }
      }
    }
    this.currentDaysInRange =
      Math.abs(
        compareDate(this._selectedRange.startDate, this._selectedRange.endDate),
      ) + 1;
    this.emitNewValue();
  }

  public getDateClass(date: Date): string {
    const classToSet: string[] = [];

    // check if is current
    if (compareDate(date, new Date()) === 0) {
      classToSet.push('is-currentDate');
    }

    // check if selected
    if (
      this._selectedRange &&
      (compareDate(this._selectedRange?.startDate, date) === 0 ||
        compareDate(this._selectedRange?.endDate, date) === 0)
    ) {
      classToSet.push('is-selected');
      if (
        compareDate(
          this._selectedRange?.startDate,
          this._selectedRange.endDate,
        ) !== 0
      ) {
        if (compareDate(this._selectedRange?.startDate, date) === 0) {
          classToSet.push('selected-between-start');
        }
        if (compareDate(this._selectedRange?.endDate, date) === 0) {
          classToSet.push('selected-between-end');
        }
      }
    }

    // check if in range
    if (
      compareDate(
        this._selectedRange?.startDate,
        this._selectedRange?.endDate,
      ) !== 0
    ) {
      if (
        compareDate(this._selectedRange?.startDate, date) > 0 &&
        compareDate(this._selectedRange?.endDate, date) < 0
      ) {
        classToSet.push('selected-between');
      }
    }

    // check if disabled
    if (this.checkIsDisabled(date)) {
      classToSet.push('is-disabled');
    }

    return classToSet.join(' ');
  }

  public ngOnInit(): void {
    this.calendarStartDay$
      .pipe(
        takeUntil(this._destroyed$),
        tap((_) => {
          this.allDays$.next(this._getAllDays(_));
        }),
        tap((_) => {
          this.calendarDays$.next(this._getCalendarDays(_));
        }),
      )
      .subscribe();
    this.startDate$.pipe(takeUntil(this._destroyed$)).subscribe((_) => {
      this.date = _ ?? new Date();
      this._startDateChanges();
    });
    if (this.selectedRangeInit) {
      this.date = new Date(this.selectedRangeInit.startDate);
      this.currentDaysInRange =
        Math.abs(
          compareDate(
            this.selectedRangeInit.startDate,
            this.selectedRangeInit.endDate,
          ),
        ) + 1;
      // this.writeValue({ ...this.selectedRangeInit });
    } else {
      this.writeValue({ startDate: this.date, endDate: this.date });
    }
    this.startDate$.next(this.date);
    console.log(this.weekDaysToDisable);
    console.log(this.maxDate);
  }

  public setMonth(inc: number) {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
  }

  public isSameMonth(date: Date) {
    return date.getMonth() === this.date.getMonth();
  }

  public addMonthToStartDate(numberToAdd: number): void {
    const currentDate = this.startDate$.value;
    if (currentDate) {
      currentDate.setMonth(currentDate.getMonth() + numberToAdd);
      this.startDate$.next(currentDate);
    }
  }

  public updateDaysInRange(numberOfDays: number): void {
    if (this._selectedRange) {
      this._selectedRange.endDate = new Date(
        this._selectedRange.startDate.getTime() + (numberOfDays - 1) * DAY_MS,
      );
      this.emitNewValue();
    }
  }

  public getDayNameClass(name: string): string {
    switch (name) {
      case 'Mon':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(1)) {
          return 'is-disabled';
        }
        return '';
      case 'Tue':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(2)) {
          return 'is-disabled';
        }
        return '';
      case 'Wed':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(3)) {
          return 'is-disabled';
        }
        return '';
      case 'Thu':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(4)) {
          return 'is-disabled';
        }
        return '';
      case 'Fri':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(5)) {
          return 'is-disabled';
        }
        return '';
      case 'Sat':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(6)) {
          return 'is-disabled';
        }
        return '';
      case 'Sun':
        if (this.weekDaysToDisable && this.weekDaysToDisable.includes(0)) {
          return 'is-disabled';
        }
        return '';
      default:
        return '';
    }
  }

  private emitNewValue() {
    this.selectedRange.emit(this._selectedRange);
    this.onChange(this._selectedRange);
  }

  private _startDateChanges() {
    this.calendarStartDay$.next(this._getCalendarStartDay(this.date));
  }

  private _getAllDays(calendarStartDate: Date | undefined) {
    calendarStartDate?.setHours(12); // to avoid bug on change hours on change hours
    const calStartTime = calendarStartDate?.getTime() ?? 0;
    return this.range(0, 41).map((num) => {
      const dateToSet = new Date(calStartTime + DAY_MS * num);
      return dateToSet;
    });
  }

  private _getCalendarDays(calendarStartDate: Date | undefined) {
    const calendarStartTime = calendarStartDate?.getTime() ?? 0;

    return this.range(0, 41).map((num) => {
      const dateToSet = new Date(calendarStartTime + DAY_MS * num);
      return dateToSet;
    });
  }

  private _getCalendarStartDay(date: Date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map((num) => {
        const dateToSet = new Date(firstDayOfMonth - DAY_MS * num);
        return dateToSet;
      })
      .find((dt) => dt.getDay() === 1);
  }

  private checkIsDisabled(date: Date): boolean {
    return (
      this.weekDaysToDisable.includes(date.getDay()) ||
        //after tuesday not able to register within 5days
      (this.weekDaysToDisable.length!==0 && (compareDate(this.dateOfTheDay, date) < 0 ||(this.dateOfTheDay.getDay()>2 && compareDate(this.dateOfTheDay, date) < 5))) ||
      (this.weekDaysToDisable.length===0 && ((this.dateOfTheDay.getDay()<=2 &&compareDate(this.dateOfTheDay, date) < 3 )||(this.dateOfTheDay.getDay()>2 && compareDate(this.dateOfTheDay, date) < 5))) ||
      // (!!this.minDate && compareDate(this.dateOfTheDay, this.minDate) <= 0) ||
      (!!this.maxDate && compareDate(this.maxDate, date) > 0)
    );
  }

  private range(start: number, end: number, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i);
  }
}
