import { FormControl, FormGroup } from '@angular/forms';
import {
  getValidatorsBasedOnRules,
  sameValueValidator,
} from '@burddy-monorepo/front/shared/validators';
import { getLoginFormRules } from '@burddy-monorepo/shared/shared-data';

export interface LoginFormGroupInitValues {
  login: string;
  password: string;
  confirmPassword: string;
}

export class LoginFormGroup extends FormGroup {
  constructor(
    init?: LoginFormGroupInitValues,
    isForCreation?: boolean,
    isForModification?: boolean,
  ) {
    const rules = getLoginFormRules(!!isForCreation, !!isForModification);
    super({
      [rules.login.fieldName]: new FormControl(
        init?.login,
        getValidatorsBasedOnRules(rules.login),
      ),
      [rules.password.fieldName]: new FormControl(
        init?.password,
        getValidatorsBasedOnRules(rules.password),
      ),
      ...(isForCreation || isForModification
        ? {
            [rules.confirmPassword.fieldName]: new FormControl(
              init?.confirmPassword,
              getValidatorsBasedOnRules(rules.confirmPassword),
            ),
          }
        : {}),
    });

    if (isForCreation || isForModification) {
      this.addValidators([
        sameValueValidator([
          rules.password.fieldName,
          rules.confirmPassword.fieldName,
        ]),
      ]);
    }
  }
}
