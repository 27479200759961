import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CustomerState, ForgotPassword,
  LogCustomerWithCredentials,
  LoginState,
  RegisterUser,
} from '@burddy-monorepo/front/shared/data';
import { ShowInputErrorDirective } from '@burddy-monorepo/front/shared/directives';
import { LoginFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import {
  ButtonComponent,
  SpinnerComponent,
} from '@burddy-monorepo/front/shared/ui-kit';
import {
  getLoginFormRules,
  LOGIN,
  MIN_8_CHARACTERS_REGEX,
  PASSWORD,
  WITH_AT_LEAST_1_NUMBER_REGEX,
  WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX,
  WITH_AT_LEAST_1_UPPERCASE_REGEX,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import { MainRoutes } from '../../../../../../../../apps/front/booking/src/routes';
import { LoginRoutes } from '../../../../../../../../apps/front/booking/src/features/login/login-routes.enum';

@Component({
  selector: 'burddy-monorepo-login-form',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    ShowInputErrorDirective,
    ButtonComponent,
    SpinnerComponent,
    AsyncPipe,
    NgClass,
  ],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Select(LoginState.callingService)
  public callingService$!: Observable<boolean | undefined>;

  @Input() title?: string;
  @Input() formGroup!: LoginFormGroup;
  @Input() isForCreation = false;
  @Output() connectEvent = new EventEmitter<void>();
  public loginRules = getLoginFormRules(false);
  public data$!: Observable<[boolean | undefined]>;
  MIN_8_CHARACTERS_REGEX = MIN_8_CHARACTERS_REGEX;
  WITH_AT_LEAST_1_UPPERCASE_REGEX = WITH_AT_LEAST_1_UPPERCASE_REGEX;
  WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX =
    WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX;
  WITH_AT_LEAST_1_NUMBER_REGEX = WITH_AT_LEAST_1_NUMBER_REGEX;
  private _store = inject(Store);
  fbUrl!:string;
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.goToLogin(event);
  }

  ngOnInit(): void {
    this.fbUrl = 'https://www.facebook.com/privacy/consent/gdp/?params[app_id]=183565345458681&params[kid_directed_site]=false&params[logger_id]="6e4c602a-0ba9-49fc-bc7b-7fb57de73f6b"&params[next]="confirm"&params[redirect_uri]="https:\\/\\/www.burddy.fr\\/connected\\/\\/login"&params[return_scopes]=false&params[scope]=["email"]&params[sdk]="php-sdk-4.0.7"&params[state]="e99fa184ba4e89c747949d3751915edd"&params[steps]={}&params[tp]="unspecified"&params[versioned_sdk]="php-sdk-4.0.7"&params[cui_gk]="[PASS]:"&params[is_limited_login_shim]=false&source=gdp_delegated';
    this.initForm();
    this.data$ = combineLatest([this.callingService$]);
  }

  initForm() {
    if (!this.formGroup) {
      throw new Error(`Form group not found`);
    }

    if (!(this.formGroup instanceof LoginFormGroup)) {
      throw new Error(`Form group is not an instance of LoginFormGroup`);
    }
  }

  goToPasswordRecovery() {
     this._store.dispatch(
      new Navigate([`${MainRoutes.LOGIN}/${LoginRoutes.FORGOT_PASSWORD}`]),
    );
  }

  connect() {
    this._store
      .dispatch(
        new LogCustomerWithCredentials(
          this.formGroup.get(LOGIN.FIELD_NAME)?.value,
          this.formGroup.get(PASSWORD.FIELD_NAME)?.value,
        ),
      )
      .subscribe(() => {
        if (this._store.selectSnapshot(CustomerState.hasConnectedUser)) {
          this.connectEvent.emit();
        }
      });
  }

  register() {
    this._store.dispatch(
      new RegisterUser(
        this.formGroup.get(LOGIN.FIELD_NAME)?.value,
        this.formGroup.get(PASSWORD.FIELD_NAME)?.value,
      ),
    );
  }

  clickPrimaryButton() {
    if (this.isForCreation) {
      this.register();
    } else {
      this.connect();
    }
  }

  goToLogin(event?: KeyboardEvent) {
    if (event) {
      event.preventDefault();
    }
    this.connect();
  }
}
