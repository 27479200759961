import { Router } from '@angular/router';
import {
  GetCustomerBillingAddress,
  GetCustomerMainInfo,
  PrefillCustomerInfo,
} from '@burddy-monorepo/front/shared/data';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { switchMap, take } from 'rxjs';

export function initializeFromCookies(
  cookieService: CookieService,
  translateService: TranslateService,
  store: Store,
  router: Router,
) {
  return () =>
    new Promise<void>((resolve) => {
      if (cookieService.get('customer_jwt')) {
        store
          .dispatch([
            new GetCustomerMainInfo(),
            new GetCustomerBillingAddress(),
          ])
          .pipe(
            switchMap(() => {
              return store.dispatch(new PrefillCustomerInfo());
            }),
            take(1),
          )
          .subscribe((_) => {
            if (_?.customer?.info?.language) {
              translateService.currentLang =
                _?.customer?.info?.language.toLowerCase();
            }
            router.initialNavigation();
            resolve();
          });
      } else {
        translateService.currentLang = 'fr';
        router.initialNavigation();
        resolve();
      }
    });
}
