import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrintDto } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

import { BadgeComponent } from '../../containers';
import { ButtonComponent } from '../../contents';

@Component({
  selector: 'burddy-monorepo-set-custom-prints',
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    NgFor,
    ButtonComponent,
    BadgeComponent,
  ],
  templateUrl: './set-custom-prints.component.html',
  styleUrls: ['./set-custom-prints.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetCustomPrintsComponent {
  @Input() selectedPrint?: PrintDto;
  @Input() availablePrints!: PrintDto[];
  @Input() confirmCallBack?: (selectedPrint: PrintDto) => void;

  clickPrimaryButton() {
    if (this.selectedPrint && this.confirmCallBack) {
      this.confirmCallBack(this.selectedPrint);
    }
  }
}
