import { Route } from '@angular/router';
import { BookingAuthCustomerGuard } from '@burddy-monorepo/front/shared/guards';
import { bookingTypeIsSelectedGuard } from 'apps/front/booking/src/guards';

import { headerResolver } from '../../../route-resolvers';
import { loadBookingResolve } from '../../../route-resolvers/load-booking.resolver';
import { BookingRoutes } from '../booking-routes.enum';

export const bookingRoutes: Route[] = [
  {
    path: BookingRoutes.SELECT_PRO_OR_PRIVATE,
    loadComponent: () =>
      import('./select-private-or-pro/select-private-or-pro.component').then(
        (m) => m.SelectPrivateOrProComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_ADDRESS,
    canActivate: [bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./set-address/set-address.component').then(
        (m) => m.SetAddressComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_DATE,
    canActivate: [bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./set-date/set-date.component').then((m) => m.SetDateComponent),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_OPTIONS,
    canActivate: [bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./set-options/set-options.component').then(
        (m) => m.SetOptionsComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.LOG_CUSTOMER,
    canActivate: [bookingTypeIsSelectedGuard],
    data: {
      showedInBookingFlow: true,
    },
    loadComponent: () =>
      import('./booking-login/booking-login.component').then(
        (m) => m.BookingLoginComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_CONTACTS,
    canActivate: [BookingAuthCustomerGuard, bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./set-contacts/set-contacts.component').then(
        (m) => m.SetContactsComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_INVOICE,
    canActivate: [BookingAuthCustomerGuard, bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./set-invoice/set-invoice.component').then(
        (m) => m.SetInvoiceComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.CONFIRMATION,
    canActivate: [BookingAuthCustomerGuard, bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./confirmation-page/confirmation-page.component').then(
        (m) => m.ConfirmationPageComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.DETAILS,
    canActivate: [BookingAuthCustomerGuard, bookingTypeIsSelectedGuard],
    loadComponent: () =>
      import('./booking-details/booking-details.component').then(
        (m) => m.BookingDetailsComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_HOURS,
    loadComponent: () =>
      import('./set-hours/set-hours.component').then(
        (m) => m.SetHoursComponent,
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.BOOKING_RESUME + '/:id/:is-success?',
    loadComponent: () =>
      import('./booking-resume/booking-resume.component').then(
        (m) => m.BookingResumeComponent,
      ),
    resolve: { isHeaderResolve: headerResolver, booking: loadBookingResolve },
  },
  {
    path: '**',
    redirectTo: BookingRoutes.SELECT_PRO_OR_PRIVATE,
    pathMatch: 'full',
  },
];
