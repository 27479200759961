<burddy-monorepo-card>
  <div class="booking_card_content">
    <div class="main_info">
      <!-- BADGE SATUS SECTION -->
      <burddy-monorepo-badge
        *ngIf="!isPassedOrder"
        [ngClass]="classOfStatus"
        class="very-small"
      >
        <img
          alt="calendar"
          src="assets/img/icons/calendar_{{ classOfStatus }}.svg"
        />
        {{
          'COMPONENTS.BOOKING_CARD.ORDER_STATUS.' + booking.status | translate
        }}
      </burddy-monorepo-badge>
      <burddy-monorepo-badge *ngIf="isPassedOrder" class="very-small error">
        <img alt="calendar" src="assets/img/icons/calendar_error.svg" />
        {{ 'COMPONENTS.BOOKING_CARD.ORDER_STATUS.PASSED' | translate }}
      </burddy-monorepo-badge>

      <!-- DATE TITLE SECTION -->
      <h3>{{ dateTitle }}</h3>
      <h4>{{ eventName }}</h4>
      <hr />

      <!-- PRICE INFO SECTION -->
      <p class="bold">
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.TOTAL' | translate }} :
          {{ booking.totalAmount }} €
        </small>
      </p>
      <p>
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.DURATION' | translate }} :
          {{ booking.duration }}</small
        >
      </p>
      <p>
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.OPTIONS' | translate }} :
          {{ optionsQuantity }}</small
        >
      </p>
      <p *ngIf="printValue">
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.PRINTS' | translate }} :
          {{ printValue }}</small
        >
      </p>
    </div>
    <div class="footer -column">
      <burddy-monorepo-button
        (click)="downloadInvoice()"
        [class]="'large tertiary'"
        *ngIf="!isPassedOrder && !isPending"
        [disabled]="isLoading"
      >
        {{ 'COMPONENTS.BOOKING_CARD.DOWNLOAD_INVOICE_BUTTON' | translate }}
        <burddy-monorepo-spinner *ngIf="isLoading"></burddy-monorepo-spinner>
      </burddy-monorepo-button>
      <burddy-monorepo-button
        (click)="editConfig()"
        *ngIf="!isPassedOrder && !isPending"
        [class]="'large primary'"
      >
        {{ 'COMPONENTS.BOOKING_CARD.EDIT_CONFIG_BUTTON' | translate }}
      </burddy-monorepo-button>
      <burddy-monorepo-button
        *ngIf="!isComplete"
        (click)="deleteBooking()"
        [class]="'large error'"
        [disabled]="isLoading"
      >
        {{ 'COMPONENTS.BOOKING_CARD.DELETE_BOOKING_BUTTON' | translate }}
        <burddy-monorepo-spinner *ngIf="isLoading"></burddy-monorepo-spinner>
      </burddy-monorepo-button>
            <burddy-monorepo-button
              *ngIf="!isComplete"
              (click)="payBooking()"
              [class]="'large primary'"
              [disabled]="isLoading"
            >
              {{ 'COMPONENTS.BOOKING_CARD.PAY_BOOKING' | translate }}
              <burddy-monorepo-spinner *ngIf="isLoading"></burddy-monorepo-spinner>
            </burddy-monorepo-button>
    </div>
  </div>
</burddy-monorepo-card>
