import { inject, Injectable } from '@angular/core';
import {
  AddressFormGroup,
  ContactFormGroup,
  CustomerProfileFormGroup,
} from '@burddy-monorepo/front/shared/form-groups';
import { CustomersService } from '@burddy-monorepo/front/shared/services';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  addressFormGroupToAddressData,
  contactFormGroupToContactData,
} from '@burddy-monorepo/front/shared/utils';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError } from 'rxjs';

import {
  InitFormGroup,
  ModifyPassword,
  SaveBillingAddress,
  SaveInfo,
} from '../actions';
import { CustomerProfileFormModel } from '../models';

const getDefaultValue = () => {
  return {
    callingService: false,
  };
};

@State<CustomerProfileFormModel>({
  name: 'customerProfileForm',
  defaults: getDefaultValue(),
})
@Injectable()
export class CustomerProfileFormState {
  private _customerService = inject(CustomersService);

  @Selector()
  public static callingService(state: CustomerProfileFormModel): boolean {
    return !!state.callingService;
  }

  @Selector()
  public static formGroup(state: CustomerProfileFormModel) {
    return state.formGroup;
  }

  @Action(SaveInfo)
  public saveInfo(ctx: StateContext<CustomerProfileFormModel>) {
    ctx.patchState({ callingService: true });
    const formGroup = ctx.getState().formGroup;
    this._customerService
      .updateCustomer({
        customerAddress: addressFormGroupToAddressData(
          formGroup?.get('customerAddress') as AddressFormGroup,
        ),
        mainInfo: contactFormGroupToContactData(
          formGroup?.get('mainInfo') as ContactFormGroup,
        ),
      })
      .pipe(
        catchError((error) => {
          ctx.patchState({ callingService: false });
          throw error;
        }),
      )
      .subscribe(() => {
        ctx.patchState({ callingService: false });
      });
  }

  @Action(SaveBillingAddress)
  public saveBillingAddress(ctx: StateContext<CustomerProfileFormModel>) {
    ctx.patchState({ callingService: true });
    const formGroup = ctx.getState().formGroup;
    this._customerService
      .updateBillingAddress(
        addressFormGroupToAddressData(
          formGroup?.get('invoiceAddress') as AddressFormGroup,
        ),
      )
      .pipe(
        catchError((error) => {
          ctx.patchState({ callingService: false });
          throw error;
        }),
      )
      .subscribe(() => {
        ctx.patchState({ callingService: false });
      });
  }

  @Action(ModifyPassword)
  public modifyPassword(ctx: StateContext<CustomerProfileFormModel>) {
    ctx.patchState({ callingService: true });
    const formGroup = ctx.getState().formGroup;
    this._customerService
      .modifyPassword({
        oldPassword: formGroup?.get('passwordModification.current_password')
          ?.value,
        newPassword: formGroup?.get('passwordModification.password')?.value,
      })
      .pipe(
        catchError((error) => {
          ctx.patchState({ callingService: false });
          throw error;
        }),
      )
      .subscribe(() => {
        ctx.patchState({ callingService: false });
      });
  }

  @Action(InitFormGroup)
  public initFormGroup(
    ctx: StateContext<CustomerProfileFormModel>,
    action: InitFormGroup,
  ) {
    ctx.patchState({
      formGroup: new CustomerProfileFormGroup(action.initData),
    });
  }
}
