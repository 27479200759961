import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BadgeComponent } from '../badge/badge.component';

/**
 * Component for displaying a select option bloc.
 */
@Component({
  selector: 'burddy-monorepo-select-option-bloc',
  standalone: true,
  imports: [TranslateModule, NgIf, BadgeComponent, NgClass],
  templateUrl: './select-option-bloc.component.html',
  styleUrls: ['./select-option-bloc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionBlocComponent {
  /**
   * The image path for the select option bloc.
   */
  @Input() imagePath?: string;
  /**
   * The title of the select option bloc.
   */
  @Input() title?: string;
  /**
   * The description of the select option bloc.
   */
  @Input() description?: string;
  /**
   * The price of the select option bloc.
   */
  @Input() price?: number;
  /**
   * Indicates whether the select option bloc is selected.
   */
  @Input() isSelected?: boolean;
  /**
   * Event emitted when the selection state of the select option bloc changes.
   */
  @Output() isSelectedChange = new EventEmitter<boolean>();
}
