<h5 *ngIf="title" [innerHTML]="title"></h5>
<ng-container [formGroup]="formGroup">
  <ng-container *ngIf="formGroup">
    <div class="-form-line">
      <div class="-form-item">
        <label for="names">{{
            (isFormCompany
                ? 'FORM.CONTACT.COMPANY_NAME'
                : 'FORM.CONTACT.FIRST_NAME_LAST_NAME'
            ) | translate
          }}</label>
        <input
          [class.mandatory-field]="formGroup.controls[contactRules.names.fieldName].errors?.['required']"
          [formControlName]="contactRules.names.fieldName"
          [maxlength]="+(contactRules.names.maxLength ?? 99999)"
          [minlength]="+(contactRules.names.minLength ?? 0)"
          [placeholder]="'Ex: Sébastien Durand'"
          name="names"
          type="text"
        />
      </div>
    </div>

    <div class="-form-line hidden">
      <div class="-form-item">
        <label for="email">{{ 'FORM.CONTACT.EMAIL' | translate }}</label>
        <input
          [class.mandatory-field]="formGroup.controls[contactRules.email.fieldName].errors?.['required']"
          [formControlName]="contactRules.email.fieldName"
          [maxlength]="+(contactRules.email.maxLength ?? 99999)"
          [minlength]="+(contactRules.email.minLength ?? 0)"
          [placeholder]="'Ex: Sébastien@gmail.com'"
          name="email"
          type="email"
        />
      </div>
    </div>

    <div class="-form-line">
      <div class="-form-item">
        <label>{{ 'FORM.CONTACT.PHONE' | translate }}</label>
        <burddy-monorepo-input-tel
          [formControlName]="contactRules.phone.fieldName"
        ></burddy-monorepo-input-tel>
      </div>
    </div>
  </ng-container>
</ng-container>
