<div (click)="close()" class="modal-backdrop"></div>
<div class="modal {{ size }}">
  <div *ngIf="title || showCloseButton" class="header">
    {{ title }}
    <span (click)="close()" *ngIf="showCloseButton" class="modal-close"
    ><img alt="cross" src="assets/img/icons/cross.svg"
    /></span>
  </div>
  <div class="body">
    <ng-content #modalContent></ng-content>
  </div>
</div>
