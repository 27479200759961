import {
  MIN_8_CHARACTERS_REGEX,
  WITH_AT_LEAST_1_NUMBER_REGEX,
  WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX,
  WITH_AT_LEAST_1_UPPERCASE_REGEX,
} from '../constants';
import { BaseValidation } from './base.validation';

export const OLD_PASSWORD_MIN_LENGTH = 4;

export const LOGIN = {
  FIELD_NAME: 'login',
  MIN_LENGTH: 3,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const CURRENT_PASSWORD = {
  FIELD_NAME: 'current_password',
  MIN_LENGTH: OLD_PASSWORD_MIN_LENGTH,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const PASSWORD = {
  FIELD_NAME: 'password',
  MIN_LENGTH: 8,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const CONFIRM_PASSWORD = {
  FIELD_NAME: 'confirmPassword',
  MIN_LENGTH: 8,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const PASSWORD_REGEXS = [
  MIN_8_CHARACTERS_REGEX,
  WITH_AT_LEAST_1_UPPERCASE_REGEX,
  WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX,
  WITH_AT_LEAST_1_NUMBER_REGEX,
];

export function getLoginFormRules(
  forCreation: boolean,
  forModification = false,
): LoginFormRules {
  return {
    login: {
      minLength: LOGIN.MIN_LENGTH,
      maxLength: LOGIN.MAX_LENGTH,
      required: LOGIN.REQUIRED,
      fieldName: LOGIN.FIELD_NAME,
      email: true,
    },
    password: {
      minLength:
        forCreation || forModification
          ? PASSWORD.MIN_LENGTH
          : OLD_PASSWORD_MIN_LENGTH, // use 4 to allow old password
      maxLength: PASSWORD.MAX_LENGTH,
      required: PASSWORD.REQUIRED,
      fieldName: PASSWORD.FIELD_NAME,
      pattern: [...(forCreation || forModification ? PASSWORD_REGEXS : [])],
    },
    currentPassword: {
      minLength: forModification
        ? CURRENT_PASSWORD.MIN_LENGTH
        : OLD_PASSWORD_MIN_LENGTH, // use 4 to allow old password
      maxLength: CURRENT_PASSWORD.MAX_LENGTH,
      required: forModification ? CURRENT_PASSWORD.REQUIRED : false,
      fieldName: CURRENT_PASSWORD.FIELD_NAME,
      pattern: [...(forCreation || forModification ? PASSWORD_REGEXS : [])],
    },
    confirmPassword: {
      minLength: CONFIRM_PASSWORD.MIN_LENGTH,
      maxLength: CONFIRM_PASSWORD.MAX_LENGTH,
      required:
        forCreation || forModification ? CONFIRM_PASSWORD.REQUIRED : false,
      fieldName: CONFIRM_PASSWORD.FIELD_NAME,
      pattern: PASSWORD_REGEXS,
    },
  };
}

// INTERFACE
export interface LoginFormRules {
  login: BaseValidation;
  currentPassword?: BaseValidation;
  password: BaseValidation;
  confirmPassword: BaseValidation;
}
