<ng-container [formGroup]="formGroup">
  <ng-container *ngIf="formGroup">
    <div class="-form-line">
      <div class="-form-item">
        <label for="street">{{ 'FORM.ADDRESS.STREET' | translate }}</label>
        <input
          [class.mandatory-field]="formGroup.controls[addressRules.streetName.fieldName].errors?.['required']"
          [formControlName]="addressRules.streetName.fieldName"
          [maxlength]="+(addressRules.streetName.maxLength ?? 99999)"
          [minlength]="+(addressRules.streetName.minLength ?? 0)"
          [placeholder]="'FORM.ADDRESS.STREET_PLACEHOLDER' | translate"
          name="streetName"
          type="text"
        />
      </div>
    </div>

    <div class="-form-line">
      <div class="-form-item">
        <label for="postalCode">{{ 'FORM.ADDRESS.ZIPCODE' | translate }}</label>
        <input
          [class.mandatory-field]="formGroup.controls[addressRules.postalCode.fieldName].errors?.['required']"
          formControlName="fakeZipCode"
          [maxlength]="+(addressRules.postalCode.maxLength ?? 99999)"
          [minlength]="+(addressRules.postalCode.minLength ?? 0)"
          [placeholder]="'FORM.ADDRESS.ZIPCODE_PLACEHOLDER' | translate"
          name="postalCode"
          type="text"
        />
      </div>
      <div class="-form-item">
        <label for="city">{{ 'FORM.ADDRESS.CITY' | translate }}</label>
        <input
          [class.mandatory-field]="formGroup.controls[addressRules.city.fieldName].errors?.['required']"
          [formControlName]="addressRules.city.fieldName"
          [maxlength]="+(addressRules.city.maxLength ?? 99999)"
          [minlength]="+(addressRules.city.minLength ?? 0)"
          [placeholder]="'FORM.ADDRESS.CITY_PLACEHOLDER' | translate"
          name="city"
          type="text"
        />
      </div>
    </div>

    <div class="-form-line">
      <div class="-form-item">
        <label for="country">{{ 'FORM.ADDRESS.COUNTRY' | translate }}</label>
        <select
          [class.mandatory-field]="formGroup.controls[addressRules.country.fieldName].errors?.['required']"
          [formControlName]="addressRules.country.fieldName"
          name="country"
        >
          <option disabled selected value="">
            {{ 'FORM.ADDRESS.COUNTRY_PLACEHOLDER' | translate }}
          </option>
          <option *ngFor="let country of availableCountries" [value]="country">
            {{ 'SHARED.COUNTRIES.' + country | translate }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="showComplement" class="-form-line">
      <div class="-form-item">
        <label for="complement"
        >{{ 'FORM.ADDRESS.COMPLEMENT' | translate }}
          <span class="light-grey1">{{
              'FORM.FACULTATIVE' | translate
            }}</span></label
        >
        <textarea
          [formControlName]="addressRules.complement.fieldName"
          [maxlength]="+(addressRules.complement.maxLength ?? 99999)"
          [minlength]="+(addressRules.complement.minLength ?? 0)"
          [placeholder]="'FORM.ADDRESS.COMPLEMENT_PLACEHOLDER' | translate"
          name="complement"
        ></textarea>
      </div>
    </div>
  </ng-container>
</ng-container>
