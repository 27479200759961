<div [ngClass]="{ selected: isSelected }" class="main-block">
  <div
    *ngIf="imagePath"
    [style.background-image]="'url(' + imagePath + ')'"
    class="image-block"
  ></div>
  <div class="text-block">
    <h3 *ngIf="title" [innerHTML]="title | translate"></h3>
    <p *ngIf="description" [innerHTML]="description | translate" style="margin: 0px;"></p>
    <burddy-monorepo-badge
      *ngIf="price !== undefined && price !== null"
      class="accent small"
    >
      <span class="bold">{{ price }} €</span>
    </burddy-monorepo-badge>
  </div>
</div>
