import { Route } from '@angular/router';

import { ProfileRoutes } from './profile-routes.enum';

export const profileRoutes: Route[] = [
  {
    path: ProfileRoutes.MAIN_INFO,
    loadComponent: () =>
      import('./profile-main-info/profile-main-info.component').then(
        (m) => m.ProfileMainInfoComponent,
      ),
  },
  {
    path: ProfileRoutes.PASSWORD,
    loadComponent: () =>
      import(
        './profile-modify-password/profile-modify-password.component'
      ).then((m) => m.ProfileModifyPasswordComponent),
  },
  {
    path: ProfileRoutes.INVOICE_INFO,
    loadComponent: () =>
      import(
        './profile-billing-address/profile-billing-address.component'
      ).then((m) => m.ProfileBillingAddressComponent),
  },
  {
    path: '**',
    redirectTo: ProfileRoutes.MAIN_INFO,
    pathMatch: 'full',
  },
];
