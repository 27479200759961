import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { BookingHeaderData, BookingHeaderSectionData } from '../../features';
import { SetHeaderAction } from '../actions/header.action';

@State<BookingHeaderData>({
  name: 'bookingHeader',
  defaults: {
    isShown: false,
    leftSection: undefined,
    rightSection: undefined,
  },
})
@Injectable()
export class HeaderState {
  @Selector()
  public static headerData(state: BookingHeaderData): BookingHeaderData {
    return state;
  }

  @Selector()
  public static isShown(state: BookingHeaderData): boolean {
    return !!state.isShown;
  }

  @Selector()
  public static leftSection(
    state: BookingHeaderData,
  ): BookingHeaderSectionData | undefined {
    return state.leftSection;
  }

  @Selector()
  public static rightSection(
    state: BookingHeaderData,
  ): BookingHeaderSectionData | undefined {
    return state.rightSection;
  }

  @Action(SetHeaderAction)
  public setHeaderAction(
    { patchState }: StateContext<BookingHeaderData>,
    { data }: SetHeaderAction,
  ): void {
    patchState(data);
  }
}
