export enum PossibleDeliveryHours {
  H_8_10 = '8h-10h',
  H_9_11 = '9h-11h',
  H_10_12 = '10h-12h',
  H_11_13 = '11h-13h',
  H_12_14 = '12h-14h',
  H_13_15 = '13h-15h',
  H_14_16 = '14h-16h',
  H_15_17 = '15h-17h',
  H_16_18 = '16h-18h',
  H_17_19 = '17h-19h',
  H_18_20 = '18h-20h',
  H_20_22 = '20h-22h',
  H_22_00 = '22h-00h',
}
