<div class="main-block">
  <burddy-monorepo-select-option-bloc
    (click)="clickBlock(option)"
    *ngFor="let option of listOfOptions"
    [description]="'OPTIONS.DESCRIPTIONS.' + option | translate"
    [imagePath]="'./assets/img/options/' + option + '.png'"
    [isSelected]="selectedOptions.includes(option)"
    [price]="PRICES[option]"
    [title]="'OPTIONS.' + option | translate"
  ></burddy-monorepo-select-option-bloc>
</div>
