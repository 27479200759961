import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'burddy-monorepo-card',
  standalone: true,
  imports: [],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() insideClass?: string;
}
