<div class="-column center-content center">
  <div>
    <img
      height="50"
      src="assets/img/icons/print.svg"
      style="border-radius: 50%"
      width="50"
    />
  </div>
  <h3 [innerHTML]="'BOOKING.SET_OPTIONS.CUSTOM_PRINTS.TITLE' | translate"></h3>
  <h5
    [innerHTML]="'BOOKING.SET_OPTIONS.CUSTOM_PRINTS.SUB_TITLE' | translate"
  ></h5>

  <div class="-form-line">
    <div class="-form-item">
      <label for="customPrint">{{
          'BOOKING.SET_OPTIONS.CUSTOM_PRINTS.SELECT_LABEL' | translate
        }}</label>
      <select [(ngModel)]="selectedPrint" name="customPrint">
        <option *ngFor="let print of availablePrints" [ngValue]="print">
          {{ 'PRINTS.' + print.name | translate }}
        </option>
      </select>
    </div>
  </div>
  <burddy-monorepo-badge class="accent">
    <span left>{{
        'BOOKING.SET_OPTIONS.CUSTOM_PRINTS.TOTAL' | translate
      }}</span>
    <span right>{{ selectedPrint?.price ?? 0 }}€</span>
  </burddy-monorepo-badge>

  <burddy-monorepo-button
    (clickEvent)="clickPrimaryButton()"
    [class]="'primary medium'"
    [disabled]="!selectedPrint"
  >{{
      'BOOKING.SET_OPTIONS.CUSTOM_PRINTS.BUTTON_TEXT' | translate
    }}
  </burddy-monorepo-button
  >
</div>
