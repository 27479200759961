import { VAT_REGEX } from '../constants';
import { BaseValidation } from './base.validation';

// CONSTANT RULES
export const INVOICE_INFO_VAT = {
  FIELD_NAME: 'vat',
  REQUIRED: true,
};
export const INVOICE_INFO_COMPANY_NAME = {
  FIELD_NAME: 'companyName',
  MIN_LENGTH: 3,
  MAX_LENGTH: 100,
  REQUIRED: true,
};

export function getInvoiceInfoRules(): InvoiceInfoRules {
  return {
    companyName: {
      minLength: INVOICE_INFO_COMPANY_NAME.MIN_LENGTH,
      maxLength: INVOICE_INFO_COMPANY_NAME.MAX_LENGTH,
      required: INVOICE_INFO_COMPANY_NAME.REQUIRED,
      fieldName: INVOICE_INFO_COMPANY_NAME.FIELD_NAME,
    },
    vat: {
      required: INVOICE_INFO_VAT.REQUIRED,
      fieldName: INVOICE_INFO_VAT.FIELD_NAME,
      pattern: [VAT_REGEX],
    },
  };
}

// INTERFACE
export interface InvoiceInfoRules {
  companyName: BaseValidation;
  vat: BaseValidation;
}
