<ng-container *ngIf="data$ | async as data">
  <ng-container [formGroup]="formGroup">
    <ng-container *ngIf="formGroup">
      <div class="-form-line">
        <div class="-form-item">
          <label for="currentPassword">{{
              'FORM.PASSWORD_MODIFICATION.CURRENT_PASSWORD_LABEL' | translate
            }}</label>
          <input
            #currentPassword
            [formControlName]="formRules.currentPassword?.fieldName ?? ''"
            name="currentPassword"
            type="password"
          />
        </div>
      </div>

      <hr />

      <h5 class="-upper-case">
        {{ 'FORM.PASSWORD_MODIFICATION.EXPLANATION_TITLE' | translate }}
      </h5>
      <p>{{ 'FORM.PASSWORD_MODIFICATION.EXPLANATION_TEXTE' | translate }}</p>

      <div class="-form-line">
        <div class="-form-item">
          <label for="newPassword">{{
              'FORM.PASSWORD_MODIFICATION.NEW_PASSWORD_LABEL' | translate
            }}</label>
          <input
            #newPassword
            [formControlName]="formRules.password.fieldName"
            name="newPassword"
            type="password"
          />
        </div>
      </div>

      <div class="-form-line">
        <div class="-form-item">
          <label for="confirmPassword">{{
              'FORM.PASSWORD_MODIFICATION.CONFIRM_PASSWORD_LABEL' | translate
            }}</label>
          <input
            #confirmPassword
            [formControlName]="formRules.confirmPassword.fieldName"
            name="confirmPassword"
            type="password"
          />
        </div>
      </div>

      <div class="-row -center -full-width">
        <burddy-monorepo-button
          (clickEvent)="clickPrimaryButton()"
          [class]="'primary medium'"
          [disabled]="!formGroup.valid || data[0] || !formGroup.dirty"
        >
          <span *ngIf="!data[0]; else modifying">
            {{ 'FORM.PASSWORD_MODIFICATION.MODIFY_BUTTON' | translate }}
          </span>
          <ng-template #modifying>
            <span>
              {{ 'FORM.PASSWORD_MODIFICATION.MODIFYING_BUTTON' | translate }}
            </span>
          </ng-template>
          <burddy-monorepo-spinner
            *ngIf="data[0]"
            right
          ></burddy-monorepo-spinner>
        </burddy-monorepo-button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
