import {
  BookingConfigData,
  BookingData,
} from '@burddy-monorepo/shared/shared-data';

export class InitBookingCustomizationFormGroup {
  public static readonly type = '[BookingCustomization] InitFormGroup';

  constructor(public readonly customization: BookingConfigData) {}
}

export class SetConfigFromBooking {
  public static readonly type = '[BookingCustomization] SetConfigFromBooking';

  constructor(public readonly booking: BookingData) {}
}

export class Reset {
  public static readonly type = '[BookingCustomization] Reset';
}

export class SetConfigFromBookingId {
  public static readonly type = '[BookingCustomization] SetConfigFromBookingId';

  constructor(public readonly bookingId: number) {}
}

export class ClearBookingCustomizationData {
  public static readonly type = '[BookingCustomization] ClearData';
}

export class SaveCustomization {
  public static readonly type = '[BookingCustomization] SaveCustomization';
}
