import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  provideRouter,
  Router,
  withDisabledInitialNavigation,
} from '@angular/router';
import {
  BookingCustomizationState,
  CustomerProfileFormState,
  CustomerState,
  LoginState,
} from '@burddy-monorepo/front/shared/data';
import {
  HeaderInterceptor,
  ResponseInterceptor,
} from '@burddy-monorepo/front/shared/interceptors';
import { ENVIRONNEMENT } from '@burddy-monorepo/front/shared/interfaces';
import { initializeFromCookies } from '@burddy-monorepo/front/shared/utils';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { BookingState, HeaderState } from './data/states';
import { environment } from './environments/environment';

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    importProvidersFrom(HttpClientModule),
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    importProvidersFrom(
      NgxsModule.forRoot([
        HeaderState,
        BookingState,
        LoginState,
        CustomerState,
        BookingCustomizationState,
        CustomerProfileFormState,
      ]),
    ),
    importProvidersFrom(NgxsReduxDevtoolsPluginModule.forRoot()),
    importProvidersFrom(NgxsRouterPluginModule.forRoot()),
    provideRouter(appRoutes, withDisabledInitialNavigation()),
    {
      provide: ENVIRONNEMENT,
      useValue: environment,
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFromCookies,
      deps: [CookieService, TranslateService, Store, Router],
      multi: true,
    },
  ],
}).catch((err) => console.error(err));
