import { AbstractControl, ValidatorFn } from '@angular/forms';

export const sameValueValidator = (controlNames: string[]): ValidatorFn => {
  return (group: AbstractControl): { [key: string]: boolean } | null => {
    const formControls: AbstractControl[] =
      controlNames.map((controlName) => group.get(controlName)?.value) || [];

    const value: string[] = [];

    formControls.forEach((form) => {
      if (form) {
        value.push(form as any);
      }
    });

    if ([...new Set(value)].length > 1) {
      return { SAME_VALUE: true };
    }
    return null;
  };
};
