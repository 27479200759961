import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CustomerProfileFormState,
  ModifyPassword,
} from '@burddy-monorepo/front/shared/data';
import { PasswordModificationFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import {
  ButtonComponent,
  SpinnerComponent,
} from '@burddy-monorepo/front/shared/ui-kit';
import { getLoginFormRules } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'burddy-monorepo-password-modification',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    AsyncPipe,
    ReactiveFormsModule,
    ButtonComponent,
    SpinnerComponent,
  ],
  templateUrl: './password-modification.component.html',
  styleUrls: ['./password-modification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordModificationComponent implements OnInit {
  @Select(CustomerProfileFormState.callingService)
  public callingService$!: Observable<boolean>;

  @Input() title?: string;
  @Input() formGroup!: PasswordModificationFormGroup;

  public data$!: Observable<[boolean]>;
  public formRules = getLoginFormRules(false, true);

  private _store = inject(Store);

  ngOnInit(): void {
    this.initForm();
    this.data$ = combineLatest([this.callingService$]);
  }

  initForm() {
    if (!this.formGroup) {
      throw new Error(`Form group not found`);
    }

    if (!(this.formGroup instanceof PasswordModificationFormGroup)) {
      throw new Error(`Form group is not an instance of LoginFormGroup`);
    }
  }

  clickPrimaryButton() {
    this._store.dispatch(new ModifyPassword());
  }
}
