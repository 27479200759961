<div (click)="changeValue()" [class]="alignement" class="clickable">
  <input
    [checked]="checked"
    [disabled]="disabled"
    class="clickable"
    class="switch"
    type="checkbox"
  />
  <span *ngIf="label" [innerHtml]="label"></span>
</div>
