import { Route } from '@angular/router';

import { CustomizeBookingRoutes } from '../customize-booking-routes.enum';

export const customizeRoutes: Route[] = [
  {
    path: CustomizeBookingRoutes.MAIN_INFO,
    loadComponent: () =>
      import('./customize-main-info/customize-main-info.component').then(
        (m) => m.CustomizeMainInfoComponent,
      ),
  },
  {
    path: CustomizeBookingRoutes.FRAMES,
    loadComponent: () =>
      import(
        './customize-frames-selection/customize-frames-selection.component'
      ).then((m) => m.CustomizeFramesSelectionComponent),
  },
  {
    path: CustomizeBookingRoutes.UPDATE_ADDRESS,
    loadComponent: () =>
      import('./update-address/update-address.component').then(
        (m) => m.UpdateAddressComponent,
      ),
  },
  {
    path: CustomizeBookingRoutes.CREATE_FRAMES,
    loadComponent: () =>
      import('./add-frame/add-frame.component').then(
        (m) => m.AddFrameComponent,
      ),
  },
  {
    path: '**',
    redirectTo: CustomizeBookingRoutes.MAIN_INFO,
    pathMatch: 'full',
  },
];
