import { Injectable } from '@angular/core';

declare var dataLayer: any;  // Déclaration de la variable globale

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor() {}

  public pushEvent(eventData: any = {}): void {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push(eventData);
    } else {
      console.warn('GTM non initialisé');
    }
  }
}
