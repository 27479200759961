<div [class]="'-' + type" class="component-content">
  <div class="left">
    <div *ngIf="showIcon">
      <span *ngIf="!customIcon" [ngSwitch]="type">
        <img
          *ngSwitchCase="'error'"
          alt="error icon"
          src="assets/img/icons/attention.svg"
        />
        <img
          *ngSwitchCase="'warning'"
          alt="alert icon"
          src="assets/img/icons/alert.svg"
        />
        <img
          *ngSwitchCase="'success'"
          alt="alert icon"
          src="assets/img/icons/success.svg"
        />
      </span>
      <span *ngIf="customIcon">
        <img
          [alt]="customIcon"
          [src]="'assets/img/icons/' + customIcon + '.svg'"
        />
      </span>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
