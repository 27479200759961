<burddy-monorepo-header *ngIf="data?.isShown">
  <div *ngIf="!!data?.leftSection" class="-row" left>
    <burddy-monorepo-button
      (clickEvent)="askQuitBooking()"
      *ngIf="data?.leftSection?.showCloseButton"
      class="close border tertiary"
    >
      <img alt="cross" src="assets/img/icons/cross.svg"
      /></burddy-monorepo-button>
    <burddy-monorepo-button
      (clickEvent)="goBack()"
      *ngIf="data?.leftSection?.showBackButton"
      class="border tertiary"
    >
      <
    </burddy-monorepo-button>
    <a *ngIf="data?.leftSection?.showFullBurddyLogo" routerLink="/">
      <img alt="Burddy" src="assets/img/logo/logo-with-text.svg" />
    </a>
    <h3 *ngIf="data?.leftSection?.title" class="-margin8">
      {{ data.leftSection?.title ?? '' | translate }}
    </h3>
  </div>
  <div *ngIf="!!data?.rightSection" class="-row" right>
    <img
      src="assets/img/icons/menu.svg"
      alt="menu"
      class="hamburger-menu"
      (click)="toggleMenu()"
    />
    <div *ngIf="isMenuVisible" class="menu-items">
      <ul>
        <li
          (click)="goToBooking()"
          *ngIf="data?.rightSection?.showBookingButton"
        >
          {{ 'HEADER.BOOK_BUTTON' | translate }}
        </li>
        <li (click)="goTo('https://www.burddy.fr/devis/')">
          {{ 'HEADER.DEVIS' | translate }}
        </li>
        <li
          (click)="askGoBookings()"
          *ngIf="data?.rightSection?.showMyBookingsButton"
        >
          {{ 'HEADER.MY_BOOKINGS' | translate }}
        </li>
        <li *ngIf="customer$ | async as customer" (click)="goTo('https://gallery.burddy.com/fr/home/Login?id='+customer.token)">
          {{ 'HEADER.MY_GALLERY' | translate }}
        </li>
        <li (click)="gotoMyProfile()">
          {{ 'HEADER.MY_PROFILE' | translate }}
        </li>
        <li *ngIf="isLoggedIn" (click)="logout()">
          {{ 'HEADER.LOGOUT' | translate }}
        </li>
      </ul>
    </div>
    <burddy-monorepo-button
      *ngIf="isLoggedIn"
      (click)="logout()"
      [class]="'tertiary -margin8 hide-on-mobile'"
    >
      {{ 'HEADER.LOGOUT' | translate }}
    </burddy-monorepo-button>
    <burddy-monorepo-button
      (click)="gotoMyProfile()"
      [class]="'tertiary -margin8 hide-on-mobile'"
    >
      <span left>
        <img alt="user" src="assets/img/icons/user.svg" />
      </span>
      {{ 'HEADER.MY_PROFILE' | translate }}
    </burddy-monorepo-button>
    <burddy-monorepo-button
      (click)="askGoBookings()"
      *ngIf="data?.rightSection?.showMyBookingsButton"
      [class]="'tertiary -margin8 hide-on-mobile'"
    >
      {{ 'HEADER.MY_BOOKINGS' | translate }}
    </burddy-monorepo-button
    >
    <ng-container
      *ngIf="customer$ | async as customer"
    >
    <burddy-monorepo-button
      [class]="'tertiary -margin8 hide-on-mobile'"
      *ngIf="data?.rightSection?.showMyBookingsButton"

    >
      <a [href]="'https://gallery.burddy.com/fr/home/Login?id='+customer.token">{{ 'HEADER.MY_GALLERY' | translate }}</a>
    </burddy-monorepo-button
    >
    </ng-container>
    <burddy-monorepo-button
      *ngIf="data?.rightSection?.showMyBookingsButton"
      [class]="'tertiary -margin8 hide-on-mobile devis-btn'"
    >
      <a href="https://www.burddy.fr/devis/">{{ 'HEADER.DEVIS' | translate }}</a>
    </burddy-monorepo-button
    >
    <burddy-monorepo-button
      (click)="goToBooking()"
      *ngIf="data?.rightSection?.showBookingButton"
      [class]="'-margin8 primary hide-on-mobile'"
    >
      <span left>
        <img alt="calendar" src="assets/img/icons/calendar.svg" />
      </span>
      {{ 'HEADER.BOOK_BUTTON' | translate }}
    </burddy-monorepo-button
    >
    <img
      *ngIf="data?.rightSection?.showBurddyLogo"
      alt="Burddy"
      [class]="'tertiary -margin8'"
      src="assets/img/logo/logo_only.svg"
    />
    <burddy-monorepo-button
      (clickEvent)="askQuitBooking()"
      *ngIf="data?.rightSection?.showCloseButton"
      [class]="'tertiary -margin8 close border'"
    >
      <img alt="cross" src="assets/img/icons/cross.svg"
      />
    </burddy-monorepo-button>
  </div>
</burddy-monorepo-header>

<ng-template #leavingAlert>
  <burddy-monorepo-card>
    <div
      class="-column center-content -padding24 -space-between -full-height -border-box"
    >
      <burddy-monorepo-badge>
        <img alt="error" class="icon-resize" src="assets/img/icons/attention.svg" />
      </burddy-monorepo-badge>
      <div class="center-text">
        <h3>{{ 'BOOKING.LEAVING_ALERT.TITLE' | translate }}</h3>
        <p>{{ 'BOOKING.LEAVING_ALERT.EXPLANATION' | translate }}</p>
      </div>
      <div>
        <burddy-monorepo-button
          (clickEvent)="quitBooking()"
          [class]="'secondary -margin8'"
        >{{
            'BOOKING.LEAVING_ALERT.PRIMARY_BUTTON' | translate
          }}
        </burddy-monorepo-button
        >
        <burddy-monorepo-button
          (clickEvent)="leaveAlert()"
          [class]="'tertiary -margin8'"
        >{{
            'BOOKING.LEAVING_ALERT.SECONDARY_BUTTON' | translate
          }}
        </burddy-monorepo-button
        >
      </div>
    </div>
  </burddy-monorepo-card>
</ng-template>
