import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONNEMENT,
  IEnvironment,
} from '@burddy-monorepo/front/shared/interfaces';
import {
  APIPathBooking,
  BookingData,
  CheckAvailabilityRequest,
  DeliveryAvailableCountries,
  ICheckPromoCodeResponse,
  ICreateOrderRequest,
  IGetCustomerOrdersResponse,
  IGetOrdersRequest,
  IVerifyAddressRequest,
  VerifyDateResponse,
} from '@burddy-monorepo/shared/shared-data';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    @Inject(ENVIRONNEMENT) private environment: IEnvironment,
    private httpService: HttpClient,
  ) {}

  verifyDate(
    request: CheckAvailabilityRequest,
  ): Observable<VerifyDateResponse> {
    let params = new HttpParams();
    if (request) {
      Object.keys(request).forEach((key) => {
        params = params.set(key, encodeURI((request as any)[key] + ''));
      });
    }
    return this.httpService.get<VerifyDateResponse>(
      `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}/${APIPathBooking.CHECK_AVAILABLE_DATE}`,
      { params },
    );
  }

  // getWarehouseAddress
  getWarehouseAddress(
    country: DeliveryAvailableCountries,
    postalCode: string,
    eventDate?: Date,
  ): Observable<any> {
    return this.httpService.get<any>(
      `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}/${APIPathBooking.GET_WAREHOUSE_ADDRESS}?country=${country}&postalCode=${postalCode}&eventDate=${eventDate}`,
    );
  }

  checkPromoCode(code: string): Observable<ICheckPromoCodeResponse> {
    return this.httpService.get<ICheckPromoCodeResponse>(
      `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}/${APIPathBooking.CHECK_PROMO_CODE}?code=${code}`,
    );
  }

  saveOrder(orderToSave: ICreateOrderRequest): Observable<string> {
    if (orderToSave.options) {
      const serializedOptionMap = {} as any;
      orderToSave?.options?.forEach((value, key) => {
        serializedOptionMap[key] = value;
      });

      orderToSave.options = serializedOptionMap as any;
    }

    return this.httpService
      .post<string>(
        `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}`,
        orderToSave,
      );
  }

  payBooking(
    bookingId: string
  ): Observable<any> {
    return this.httpService.get<any>(
      `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}/${APIPathBooking.GET_PAY_URL.replace(':id',bookingId)}`,
    );
  }

  getBooking(id: number): Observable<any> {
    return this.httpService
      .get<BookingData>(
        `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}/${APIPathBooking.GET.replace(':id', id.toString())}`
      )
      .pipe(
        switchMap((bookingData) =>
          this.httpService
            .get<any>(`${this.environment.apiConfigToolUrl}/api/frame/result/${id}`)
            .pipe(
              map((frames) => ({
                ...bookingData,
                selectedEventDate: new Date(bookingData.selectedEventDate + ''),
                options: new Map(Object.entries(bookingData.options as any)),
                selectedPrints: {
                  ...bookingData.selectedPrints,
                  name: 'PRINTS_' + (bookingData.selectedPrints?.name ?? ''),
                },
                typeImp: (bookingData?.configs && bookingData?.configs[0]?.typeImp) || 0,
                configs: frames.result.length===0 ?
                  bookingData.configs?.filter(c=>c.frameToolDemoImageUrl && c.frameToolDemoImageUrl?.length >0)
                  : frames.result.map((f:any)=>({
                    personnalBackgroundPath: f.url,
                    new_config_tool_delete_url: f.delete_url,
                    new_config_tool_id: f.id,
                    isPersonnalBackground: true,
                    frameToolDemoImageUrl: f.url_client,
                    frame_format: f.frame_name,
                  })),
              })),
              catchError((error) => {
                console.error('Erreur lors de la récupération des frames:', error);
                return of({
                  ...bookingData,
                  selectedEventDate: new Date(bookingData.selectedEventDate + ''),
                  options: new Map(Object.entries(bookingData.options as any)),
                  selectedPrints: {
                    ...bookingData.selectedPrints,
                    name: 'PRINTS_' + (bookingData.selectedPrints?.name ?? ''),
                  },
                  typeImp: (bookingData?.configs && bookingData?.configs[0]?.typeImp) || 0,
                  configs:
                    bookingData.configs?.filter(c=>c.frameToolDemoImageUrl && c.frameToolDemoImageUrl?.length >0),
                });
              })
            ))
      );
  }

  loadMyBookings(
    data: IGetOrdersRequest,
  ): Observable<IGetCustomerOrdersResponse> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => {
        params = params.set(key, encodeURI((data as any)[key] + ''));
      });
    }
    return this.httpService
      .get<IGetCustomerOrdersResponse>(
        `${this.environment.bookingApiUrl}/${APIPathBooking.MAIN}/${APIPathBooking.MY_BOOKINGS}`,
        { params },
      )
      .pipe(
        map((_) => {
          return {
            ..._,
            bookings: _.bookings.map((booking) => {
              return {
                ...booking,
                selectedEventDate: new Date(booking.selectedEventDate + ''),
                options: new Map(Object.entries(booking.options as any)),
              };
            }),
          };
        }),
      );
  }

  getInvoiceAPIUrl(id: string): Observable<any> {
    return this.httpService
      .get(
        `${this.environment.bookingApiUrl}/${
          APIPathBooking.MAIN
        }/${APIPathBooking.DOWNLOAD_INVOICE.replace(':id', id.toString())}`,
      )
      .pipe(
        tap((data: any) => {
          return data;
        }),
        catchError((err) => {
          console.error('downloadInvoiceFile err', err);
          throw err;
        }),
      );
  }

  downloadInvoiceFile(
    id: string,
    url: string,
    token: string,
  ): Observable<unknown> {
    return this.httpService
      .get(url, {
        headers: {
          Authorization: token,
        },
        responseType: 'blob',
      })
      .pipe(
        tap((data: any) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = `invoice_${id}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }),
        catchError((err) => {
          console.error('downloadInvoiceFile err', err);
          throw err;
        }),
      );
  }

  saveBookingCustomization(
    bookingId: number,
    customization: {
      eventName: string;
      numberOfPrints: number;
      mailObject: string;
      wantSendEmail: boolean;
      mailContent: string;
    },
  ): Observable<void> {
    return this.httpService.post<void>(
      `${this.environment.bookingApiUrl}/${
        APIPathBooking.MAIN
      }/${APIPathBooking.SAVE_CUSTOMIZATION.replace(
        ':id',
        bookingId.toString(),
      )}`,
      customization,
    );
  }

  updateBookingAddresses(
    bookingId: number,
    address: {
      deliveryAddress: IVerifyAddressRequest;
      returnAddress: IVerifyAddressRequest;
    },
  ): Observable<void> {
    return this.httpService.put<void>(
      `${this.environment.bookingApiUrl}/${
        APIPathBooking.MAIN
      }/${APIPathBooking.UPDATE_ADDRESS.replace(':id', bookingId.toString())}`,
      address,
    );
  }

  deleteFrame(frame:any): Observable<boolean> {
     this.httpService
      .get<any>(
        `${this.environment.bookingApiUrl}/${
          APIPathBooking.MAIN
        }/${APIPathBooking.DELETE_FRAME.replace(':id', frame.new_config_tool_id?.toString() ?? '')}`).subscribe();
    return this.httpService.get<boolean>(frame.new_config_tool_delete_url);
  }

  deleteBooking(bookingId:string): Observable<boolean> {
    return this.httpService
      .delete<any>(
        `${this.environment.bookingApiUrl}/${
          APIPathBooking.MAIN
        }/`+bookingId);
  }


  updateFrame(bookingData:BookingData){

    return this.httpService
      .get<any>(`${this.environment.apiConfigToolUrl}/api/frame/result/${bookingData.id}`)

      .pipe(
        map((frames) => {
          this.httpService.post<void>(
            `${this.environment.bookingApiUrl}/${
              APIPathBooking.MAIN
            }/${APIPathBooking.SAVE_CONFIG.replace(':id', bookingData.id?.toString() ?? '')}`,
            frames.result.map((f:any)=>({
              personnalBackgroundPath: f.url,
              new_config_tool_delete_url: f.delete_url,
              new_config_tool_id: f.id,
              isPersonnalBackground: true,
              frameToolDemoImageUrl: f.url_client,
              frame_format: f.frame_name,
            })),
          ).subscribe();
          return ({
            ...bookingData,
            selectedEventDate: new Date(bookingData.selectedEventDate + ''),
            options: new Map(Object.entries(bookingData.options as any)),
            selectedPrints: {
              ...bookingData.selectedPrints,
              name: 'PRINTS_' + (bookingData.selectedPrints?.name ?? ''),
            },
            configs: frames.result.length===0 ?
              bookingData.configs?.filter(c=>c.frameToolDemoImageUrl && c.frameToolDemoImageUrl?.length >0)
              : frames.result.map((f:any)=>({
                personnalBackgroundPath: f.url,
                new_config_tool_delete_url: f.delete_url,
                new_config_tool_id: f.id,
                isPersonnalBackground: true,
                frameToolDemoImageUrl: f.url_client,
                frame_format: f.frame_name,
              })),
          })
        })
      )
  }
}
