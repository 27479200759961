import { BaseValidation } from './base.validation';

export const STREET_NAME = {
  FIELD_NAME: 'streetName',
  MIN_LENGTH: 3,
  MAX_LENGTH: 100,
  REQUIRED: true,
};

export const POSTAL_CODE = {
  FIELD_NAME: 'postalCode',
  MIN_LENGTH: 4,
  MAX_LENGTH: 10,
  REQUIRED: true,
};

export const CITY = {
  FIELD_NAME: 'city',
  MIN_LENGTH: 3,
  MAX_LENGTH: 100,
  REQUIRED: true,
};

export const COUNTRY = {
  FIELD_NAME: 'country',
  REQUIRED: true,
};

export const COMPLEMENT = {
  FIELD_NAME: 'complementInfo',
  REQUIRED: false,
  MAX_LENGTH: 500,
  MIN_LENGTH: 3,
};

export function getAddressRules(): AddressRules {
  return {
    streetName: {
      minLength: STREET_NAME.MIN_LENGTH,
      maxLength: STREET_NAME.MAX_LENGTH,
      required: STREET_NAME.REQUIRED,
      fieldName: STREET_NAME.FIELD_NAME,
    },
    postalCode: {
      minLength: POSTAL_CODE.MIN_LENGTH,
      maxLength: POSTAL_CODE.MAX_LENGTH,
      required: POSTAL_CODE.REQUIRED,
      fieldName: POSTAL_CODE.FIELD_NAME,
    },
    city: {
      minLength: CITY.MIN_LENGTH,
      maxLength: CITY.MAX_LENGTH,
      required: CITY.REQUIRED,
      fieldName: CITY.FIELD_NAME,
    },
    country: {
      required: COUNTRY.REQUIRED,
      fieldName: COUNTRY.FIELD_NAME,
    },
    complement: {
      required: COMPLEMENT.REQUIRED,
      maxLength: COMPLEMENT.MAX_LENGTH,
      minLength: COMPLEMENT.MIN_LENGTH,
      fieldName: COMPLEMENT.FIELD_NAME,
    },
  };
}

// INTERFACE
export interface AddressRules {
  streetName: BaseValidation;
  postalCode: BaseValidation;
  city: BaseValidation;
  country: BaseValidation;
  complement: BaseValidation;
}
