import { inject, Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AddReturnAddressFormGroup,
  CheckPromoCode,
  ClearData,
  ClickOnPrimaryButton,
  CustomerState,
  DeleteBooking,
  DeleteFrame,
  GetIsActive,
  GetWarehouseAddress,
  GoNextStep,
  InitBooking,
  InitWithBookingInfo,
  LoadCustomerBookings,
  LoginState,
  PrefillCustomerInfo,
  PrepareBookingForm,
  RemovePromoCode,
  RemoveReturnAddressFormGroup,
  SaveOrder,
  SelectStandardDelivery,
  SetBookingData,
  SetBookingFormGroup,
  SetBookingStep,
  SetBookingType,
  SetCurrentStep,
  SetDuration,
  SetIsLoading,
  SetLoadBooking,
  SetMandatoryOptions,
  SetOptions,
  SetOptionsPayload,
  SetPossibleDeliveryDates,
  SetPossibleReturnDates,
  SetPrints,
  SetReturnAddressFormGroup,
  UpdateFrame,
  UpdateForm,
  VerifyAddress,
  VerifyDate,
  PayOrder, ReLoadCustomerBookings,
} from '@burddy-monorepo/front/shared/data';
import {
  AddressFormGroup,
  ContactFormGroup,
} from '@burddy-monorepo/front/shared/form-groups';
import {
  AddressService,
  BookingService,
} from '@burddy-monorepo/front/shared/services';
import {
  addressDataToAddressFormGroupInitValue,
  addressFormGroupToAddressData,
} from '@burddy-monorepo/front/shared/utils';
import {
  minRangeDateValidator,
  uniqueContactValidator,
  vatValidator,
} from '@burddy-monorepo/front/shared/validators';
import {
  addDaysToDate,
  addressAreSame,
  AddressData,
  AVAILABLE_PRINTS,
  AvailableOptions,
  BookingConfigData,
  BookingType,
  calculateBookingPrice,
  compareDate,
  DateRange,
  DeliveryAvailableCountries,
  EMAIL,
  filterOptionBasedOnBookingType,
  formatDate,
  getAddressRules,
  getContactPersonRules,
  getMinimumDate,
  getPossibleDeliveryDateForB2BEventDate,
  getPossibleDeliveryDateForB2CEventDate,
  getPossibleReturnDateForB2BEventDate,
  getPossibleReturnDateForB2CEventDate,
  getTVAToApply,
  ICheckPromoCodeResponse,
  IContactPerson,
  ICreateOrderRequest,
  InfoForUser,
  initNewBooking,
  IVerifyAddressRequest,
  Languages,
  MANDATORY_OPTIONS,
  NAMES,
  NIGHT_HOURS,
  NOT_SELECTABLE_OPTIONS,
  ONLY_FOR_B2B_OPTIONS,
  ONLY_FOR_B2C_OPTIONS,
  OrderPaymentMethod,
  PHONE,
  PossibleDeliveryHours,
  PRICES,
  PrintDto,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { BookingRoutes } from 'apps/front/booking/src/features/booking/booking-routes.enum';
import { MainRoutes } from 'apps/front/booking/src/routes';
import { catchError, of, Subscription, take, tap } from 'rxjs';

import { Booking, EventDateAndDuration, StepMessage } from '../models';
import { PaymentMethods } from '../../../../../back/booking-api/src/db/entities/paymentMethods';
import { GtmService } from '../../services/gtm.service';

// TODO : migrate in a lib !!
const getDefaultValues = (): Booking => ({
  bookingForm: undefined,
  bookingData: undefined,
  currentStep: undefined,
  currentStepMessages: undefined,
  isLoading: false,
  availableOptions: undefined,
  availablePrints: undefined,
  possibleDeliveryDates: undefined,
  possibleReturnDates: undefined,
  type: undefined,
  isActive: undefined,
  promoCode: undefined,
  stripeUrl: null
});

@State<Booking>({
  name: 'booking',
  defaults: getDefaultValues(),
})
@Injectable()
export class BookingState {
  private previousBookingFormSubscription$!: Subscription | undefined;
  private gtmService = inject(GtmService);
  constructor(
    private addressService: AddressService,
    private store: Store,
    private translateService: TranslateService,
    private bookingService: BookingService,
    private fb: FormBuilder,
  ) {}

  @Selector()
  public static showPrimaryButton(state: Booking): boolean {
    return (
      (state.currentStep ?? 0) >= 1 &&
      (state.currentStep ?? 0) < 8 &&
      !state.isLoading
    );
  }

  @Selector()
  public static promoCodeIsNotValid(state: Booking): boolean {
    if (!state?.promoCode) {
      return false;
    }
    return !state.promoCode.isValid;
  }

  @Selector()
  public static promoCode(state: Booking): ICheckPromoCodeResponse | undefined {
    return state.promoCode;
  }

  @Selector()
  public static bookingForm(state: Booking): FormGroup | undefined {
    return state.bookingForm;
  }

  @Selector()
  public static showResumeBookingOnRight(state: Booking): boolean {
    return (state.currentStep ?? 0) >= 1 && (state.currentStep ?? 0) < 7;
  }

  @Selector()
  public static availablePrints(state: Booking): PrintDto[] {
    return state.availablePrints ?? [];
  }

  @Selector()
  public static availableOptions(state: Booking): AvailableOptions[] {
    return state.availableOptions ?? [];
  }

  @Selector()
  public static bookingType(state: Booking): BookingType | undefined {
    return state.type;
  }

  @Selector()
  public static deliveryAddressFormGroup(
    state: Booking,
  ): AddressFormGroup | undefined {
    return state.bookingForm?.get('deliveryAddress') as AddressFormGroup;
  }

  @Selector()
  public static infoForUser(state: Booking): InfoForUser | undefined {
    return state?.bookingData?.infoForUser;
  }

  @Selector()
  public static returnAddressFormGroup(
    state: Booking,
  ): AddressFormGroup | undefined {
    return state.bookingForm?.get('returnAddress') as AddressFormGroup;
  }

  @Selector()
  public static invoiceAddressFormGroup(
    state: Booking,
  ): AddressFormGroup | undefined {
    return state.bookingForm?.get('invoiceAddress') as AddressFormGroup;
  }

  @Selector()
  public static selectedOptions(state: Booking): AvailableOptions[] {
    return state.bookingData?.options
      ? Array.from(state.bookingData?.options.keys()).map(
          (_) => _ as AvailableOptions,
        )
      : [];
  }

  @Selector()
  public static contactPerson1FormGroup(
    state: Booking,
  ): ContactFormGroup | undefined {
    return state.bookingForm?.get('contactPerson1') as ContactFormGroup;
  }

  @Selector()
  public static contactPerson2FormGroup(
    state: Booking,
  ): ContactFormGroup | undefined {
    return state.bookingForm?.get('contactPerson2') as ContactFormGroup;
  }

  @Selector()
  public static invoiceContactFormGroup(
    state: Booking,
  ): ContactFormGroup | undefined {
    return state.bookingForm?.get('invoiceContact') as ContactFormGroup;
  }

  @Selector()
  public static formHasUniqueContactError(state: Booking): boolean {
    return state.bookingForm?.hasError('UNIQUE_CONTACT') ?? false;
  }

  @Selector()
  public static contactPerson1FormInfo(
    state: Booking,
  ): IContactPerson | undefined {
    return BookingState.getContactPersonInfoFromForm('contactPerson1', state);
  }

  @Selector()
  public static selectedPrint(state: Booking): PrintDto | undefined {
    return state.bookingData?.selectedPrints;
  }

  @Selector()
  public static selectConfig(state: Booking): BookingConfigData[] | undefined {
    return state.bookingData?.configs;
  }

  @Selector()
  public static contactPerson2FormInfo(
    state: Booking,
  ): IContactPerson | undefined {
    return BookingState.getContactPersonInfoFromForm('contactPerson2', state);
  }

  @Selector()
  public static eventDateFormControl(state: Booking): FormControl | undefined {
    return state.bookingForm?.get('eventDate') as FormControl;
  }

  @Selector()
  public static selectedPrintFormControl(
    state: Booking,
  ): FormControl | undefined {
    return state.bookingForm?.get('selectedPrint') as FormControl;
  }

  @Selector()
  public static selectedOptionsFormControl(
    state: Booking,
  ): FormControl | undefined {
    return state.bookingForm?.get('selectedOptions') as FormControl;
  }

  @Selector()
  public static deliveryAddress(state: Booking): AddressData | undefined {
    return state.bookingData?.deliveryAddress;
  }

  @Selector()
  public static returnAddress(state: Booking): AddressData | undefined {
    return state.bookingData?.returnAddress;
  }

  @Selector()
  public static currentStep(state: Booking): number | undefined {
    return state.currentStep;
  }

  @Selector()
  public static currentStepMessages(state: Booking): StepMessage[] | undefined {
    return state.currentStepMessages;
  }

  @Selector()
  public static showBottomSheet(state: Booking): boolean {
    return !!state?.currentStep && state?.currentStep < 8;
  }

  @Selector()
  public static bookingVAT(state: Booking): number {
    const tvaToUse = 21;
    return state?.type === 'b2b'
      ? getTVAToApply(
          {
            deliveryCountry: state?.bookingData?.deliveryAddress?.country ?? '',
            invoiceDate: state?.bookingData?.selectedEventDate ?? new Date(),
            agency: state?.bookingData?.relatedAgency ?? '',
          },
          tvaToUse,
        )
      : 0;
  }

  @Selector()
  public static bookingHT(state: Booking): number {
    return calculateBookingPrice(state?.bookingData);
  }

  @Selector()
  public static bookingTotal(state: Booking): number {
    return (
      this.bookingHT(state) +
      (this.bookingHT(state) * this.bookingVAT(state)) / 100
    );
  }

  @Selector()
  public static possibleDeliveryDates(state: Booking): string[] {
    return state.possibleDeliveryDates ?? [];
  }

  @Selector()
  public static possibleReturnDates(state: Booking): string[] {
    return state.possibleReturnDates ?? [];
  }

  @Selector()
  public static bookingDataId(state: Booking): string | undefined {
    return state.bookingData?.id;
  }

  @Selector()
  public static primaryBookingButtonText(state: Booking): string {
    switch (state.currentStep) {
      case 1:
        return 'BOOKING.SET_ADDRESS.BUTTON_TEXT';
      case 2:
        return state?.bookingForm?.get('eventDate')?.valid
          ? 'BOOKING.SET_DATE.BUTTON_TEXT'
          : 'BOOKING.SET_ADDRESS.BUTTON_TEXT';
      case 2.5:
        return 'BOOKING.SET_HOURS.BUTTON_TEXT';
      case 3:
        return 'BOOKING.SET_OPTIONS.BUTTON_TEXT';
      case 4:
        return 'BOOKING.SET_CONTACTS.BUTTON_TEXT';
      case 5:
        return 'BOOKING.SET_INVOICE_INFO.BUTTON_TEXT';
      case 6:
        return 'BOOKING.CONFIRMATION.BUTTON_TEXT';
      case 7:
        return 'RESUME_CONTACTS_CARD.CONFIRM_BOOKING';
    }
    return '';
  }

  @Selector()
  public static deliveryAddressAndReturnAddressAreTheSame(
    state: Booking,
  ): boolean {
    return addressAreSame(
      state.bookingData?.deliveryAddress,
      state.bookingData?.returnAddress,
    );
  }

  @Selector()
  public static bookingOptionsAndPrice(
    state: Booking,
  ): Map<string, number> | undefined {
    if (state.bookingData?.options) {
      const toReturn: Map<string, number> = new Map();
      state.bookingData?.options.forEach((value, key) => {
        toReturn.set(key, PRICES[key as keyof typeof PRICES] * value);
      });

      return new Map<string, number>(toReturn);
    }
    return new Map();
  }

  @Selector()
  public static mandatoryOptionsAndPrice(
    state: Booking,
  ): Map<string, number> | undefined {
    if (state.bookingData?.options) {
      const mandatory: Map<string, number> = new Map();
      state.bookingData?.options.forEach((value, key) => {
        if (MANDATORY_OPTIONS.includes(key as AvailableOptions)) {
          mandatory.set(key, PRICES[key as keyof typeof PRICES] * value);
        }
      });
      return mandatory;
    }
    return new Map();
  }

  @Selector()
  public static optionalOptionsAndPrice(
    state: Booking,
  ): Map<string, number> | undefined {
    if (state.bookingData?.options) {
      const optional: Map<string, number> = new Map();
      state.bookingData?.options.forEach((value, key) => {
        if (!MANDATORY_OPTIONS.includes(key as AvailableOptions)) {
          optional.set(key, PRICES[key as keyof typeof PRICES] * value);
        }
      });
      return new Map<string, number>(optional);
    }
    return new Map();
  }

  @Selector()
  public static isLoading(state: Booking): boolean {
    return !!state?.isLoading;
  }

  @Selector()
  public static eventDateAndDuration(
    state: Booking,
  ): EventDateAndDuration | undefined {
    return {
      eventDate: state.bookingData?.selectedEventDate,
      duration: state.bookingData?.duration,
    };
  }

  @Selector()
  public static invoiceContactFormInfo(
    state: Booking,
  ): IContactPerson | undefined {
    return state?.bookingForm?.get('invoiceContact')?.value as IContactPerson;
  }

  @Selector()
  public static acceptCGVFormControl(state: Booking): FormControl | undefined {
    return state?.bookingForm?.get('acceptCGV') as FormControl;
  }

  @Selector()
  public static acceptNewsLetterFormControl(
    state: Booking,
  ): FormControl | undefined {
    return state?.bookingForm?.get('acceptNewsLetter') as FormControl;
  }

  @Selector()
  public static showSideResumeBooking(state: Booking): boolean {
    return (state.currentStep ?? 0) !== 7 && (state.currentStep ?? 0) >= 1;
  }

  @Selector()
  public static canEditFromBillResume(state: Booking): boolean {
    return (state.currentStep ?? 0) < 7;
  }

  @Selector()
  public static canOpenBottomSheet(state: Booking): boolean {
    return (state?.currentStep ?? 0) < 7;
  }

  @Selector()
  public static showGoToDetailsButton(state: Booking): boolean {
    return state?.currentStep === 7;
  }

  @Selector()
  public static currentStepIsValid(state: Booking): boolean {
    if (state?.isLoading) {
      return false;
    }

    switch (state.currentStep) {
      // Step 1 : Select Address
      case 1:
        return (
          (state.bookingForm?.get('deliveryAddress')?.valid &&
            (!state.bookingForm?.get('returnAddress') ||
              state.bookingForm?.get('returnAddress')?.valid)) ??
          false
        );
      // Step 2 : Select Date
      case 2:
        return (state?.bookingForm?.get('eventDate')?.valid ??
          false) as boolean;
      case 2.5:
        return (
          (state?.bookingData?.options?.get('deliveryCustomMade')
               === undefined) ||
          (!!state?.bookingForm?.get('deliveryDate')?.valid &&
            !!state?.bookingForm?.get('returnDate')?.valid &&
            !!state?.bookingForm?.get('deliveryHour')?.valid &&
            !!state?.bookingForm?.get('returnHour')?.valid)
        );
      // Step 3 : Select Options
      case 3:
        return (state?.bookingForm?.get('selectedPrint')?.valid ??
          false) as boolean;
      // Step 4 : Set contacts
      case 4:
        return (
          (state?.bookingForm?.get('contactPerson1')?.valid &&
            state?.bookingForm?.get('contactPerson2')?.valid &&
            !state?.bookingForm?.hasError('UNIQUE_CONTACT')) ??
          false
        );
      // Step 5 : Set invoice info
      case 5:
        return ((state?.bookingForm?.get('invoiceAddress')?.valid &&
          state?.bookingForm?.get('invoiceContact')?.valid &&
          (state?.type !== 'b2b' ||
            state?.bookingForm?.get('companyVat')?.valid)) ??
          false) as boolean;
      case 7:
        return state?.bookingForm?.get('acceptCGV')?.value ?? false;
      case 8:
        return true;
    }
    return true;
  }

  private static getContactPersonInfoFromForm(
    formGroupName: string,
    state: Booking,
  ): IContactPerson | undefined {
    const contactPerson = state.bookingForm?.get(
      formGroupName,
    ) as ContactFormGroup;
    if (contactPerson) {
      return {
        names: contactPerson.get(NAMES.FIELD_NAME)?.value,
        phone: contactPerson.get(PHONE.FIELD_NAME)?.value,
        email: contactPerson.get(EMAIL.FIELD_NAME)?.value,
      };
    }
    return undefined;
  }

  @Action(SetBookingType)
  public setBookingType(
    { patchState, getState }: StateContext<Booking>,
    { data }: SetBookingType,
  ): void {
    const currentStartDate =
      getState().bookingForm?.get('eventDate')?.value?.startDate;
    if (currentStartDate) {
      getState().bookingForm?.get('eventDate')?.setValue({
        startDate: currentStartDate,
        endDate: currentStartDate,
      });
    }

    patchState({ type: data });
    this.store.dispatch(new SetMandatoryOptions());
  }

  @Action(SetMandatoryOptions)
  public SetMandatoryOptions({
    patchState,
    getState,
  }: StateContext<Booking>): void {
    const bookingType = getState().type ?? 'b2c';
    const currentOptions = [
      ...(getState().bookingData?.options?.entries() ?? []),
    ]
      // remove mandatory options to readd necessary after
      .filter(([key]) => {
        return !MANDATORY_OPTIONS.includes(key as AvailableOptions);
      })
      // remove option from booking type specific
      .filter(filterOptionBasedOnBookingType(bookingType));

    // readd mandatory options for specific booking type
    MANDATORY_OPTIONS.forEach((element) => {
      if (
        (bookingType == 'b2c' &&
          !ONLY_FOR_B2B_OPTIONS.includes(element as AvailableOptions)) ||
        (bookingType == 'b2b' &&
          !ONLY_FOR_B2C_OPTIONS.includes(element as AvailableOptions))
      ) {
        currentOptions.push([element, 1]);
      }
    });
    patchState({
      bookingData: {
        ...getState().bookingData,
        options: new Map<string, number>(currentOptions),
      },
    });
  }

  @Action(SetBookingFormGroup)
  public setBookingFormGroup(
    { patchState }: StateContext<Booking>,
    { data }: SetBookingFormGroup,
  ): void {
    if (this.previousBookingFormSubscription$) {
      this.previousBookingFormSubscription$.unsubscribe();
    }
    this.previousBookingFormSubscription$ = data.valueChanges.subscribe(() => {
      patchState({});
    });
    patchState({ bookingForm: data });
  }

  @Action(AddReturnAddressFormGroup)
  public addReturnAddressFormGroup(ctx: StateContext<Booking>) {
    const tmp = ctx.getState().bookingForm;

    tmp?.addControl('returnAddress', new AddressFormGroup());
    ctx.patchState({ bookingForm: tmp });
  }

  @Action(SetReturnAddressFormGroup)
  setReturnAddressFormGroup(
    ctx: StateContext<Booking>,
    action: SetReturnAddressFormGroup,
  ) {
    const tmp = ctx.getState().bookingForm;

    tmp?.get('returnAddress')?.setValue({
      ...addressDataToAddressFormGroupInitValue(action.returnAddress),
    });
    ctx.patchState({ bookingForm: tmp });
  }

  @Action(RemoveReturnAddressFormGroup)
  public removeReturnAddressFormGroup(ctx: StateContext<Booking>) {
    const tmp = ctx.getState().bookingForm;

    tmp?.removeControl('returnAddress');
    ctx.patchState({ bookingForm: tmp });
  }

  @Action(ClickOnPrimaryButton)
  public clickOnPrimaryButton(ctx: StateContext<Booking>): void {
    if (BookingState.currentStepIsValid(ctx.getState())) {
      switch (ctx.getState().currentStep) {
        case 1:
          ctx.dispatch([new VerifyAddress(), new GetWarehouseAddress()]);
          break;
        case 2:
          ctx.dispatch([new VerifyDate(), new GetWarehouseAddress()]);
          break;
        case 2.5:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          ctx.dispatch(new GoNextStep());
          break;
      }
    }
  }

  @Action(ClearData)
  public clearData({ setState }: StateContext<Booking>): void {
    if (this.previousBookingFormSubscription$) {
      this.previousBookingFormSubscription$.unsubscribe();
      this.previousBookingFormSubscription$ = undefined;
    }
    setState(getDefaultValues());
  }

  @Action(DeleteBooking)
  public deleteBooking(
    { setState }: StateContext<Booking>,
    { bookingId }: DeleteBooking,
  ): void {
    this.bookingService.deleteBooking(bookingId).subscribe(() => {
      this.store.dispatch(new ReLoadCustomerBookings());
    });
  }

  // TODO : use select when state migrated in lib
  @Action(GetIsActive)
  public getIsActive({ getState }: StateContext<Booking>): boolean {
    return !!getState().isActive;
  }

  @Action(SetCurrentStep)
  public setCurrentStep(
    { patchState }: StateContext<Booking>,
    { step }: SetCurrentStep,
  ): void {
    patchState({ currentStep: step });
  }

  @Action(PrefillCustomerInfo)
  public PrefillCustomerInfo({ getState }: StateContext<Booking>): void {
    const customerInfo = this.store.selectSnapshot(CustomerState.customerInfo);
    const customerBillingAddress = this.store.selectSnapshot(
      CustomerState.customerBillingAddress,
    );
    if (customerInfo) {
      const bookingForm = getState().bookingForm;
      if (
        bookingForm &&
        !bookingForm?.get('deliveryAddress')?.get('postalCode')?.value &&
        !bookingForm?.get('deliveryAddress')?.get('city')?.value &&
        !bookingForm?.get('deliveryAddress')?.get('streetName')?.value &&
        !bookingForm?.get('deliveryAddress')?.get('country')?.value
      ) {
        bookingForm?.get('deliveryAddress')?.setValue({
          postalCode: customerInfo?.zipCode ?? '',
          city: customerInfo?.city ?? '',
          streetName: customerInfo?.streetAndNumber ?? '',
          country: customerInfo?.country ?? '',
          complementInfo: '',
        });
      }
      if (
        bookingForm &&
        !bookingForm?.get('contactPerson1')?.get('names')?.value &&
        !bookingForm?.get('contactPerson1')?.get('email')?.value &&
        !bookingForm?.get('contactPerson1')?.get('phone')?.value
      ) {
        bookingForm?.get('contactPerson1')?.setValue({
          names: customerInfo.names,
          email: customerInfo.email,
          phone: customerInfo.phone ?? '',
        });
      }
      if (
        bookingForm &&
        !bookingForm?.get('invoiceContact')?.get('names')?.value &&
        !bookingForm?.get('invoiceContact')?.get('email')?.value &&
        !bookingForm?.get('invoiceContact')?.get('phone')?.value
      ) {
        bookingForm?.get('invoiceContact')?.setValue({
          names: customerInfo.names,
          email: customerInfo.email,
          phone: customerInfo.phone,
        });
      }
      if (
        bookingForm &&
        !bookingForm?.get('invoiceAddress')?.get('postalCode')?.value &&
        !bookingForm?.get('invoiceAddress')?.get('city')?.value &&
        !bookingForm?.get('invoiceAddress')?.get('streetName')?.value &&
        !bookingForm?.get('invoiceAddress')?.get('country')?.value
      ) {
        bookingForm?.get('invoiceAddress')?.setValue({
          postalCode: customerBillingAddress?.postalCode ?? '',
          city: customerBillingAddress?.city ?? '',
          streetName: customerBillingAddress?.streetName ?? '',
          country: customerBillingAddress?.country ?? '',
          complementInfo: '',
        });
      }
    }
  }

  @Action(InitWithBookingInfo)
  public initWithBookingInfo(
    ctx: StateContext<Booking>,
    { bookingData }: InitWithBookingInfo,
  ) {
    ctx.patchState({ bookingData });
  }

  @Action(PrepareBookingForm)
  public prepareBookingForm(
    ctx: StateContext<Booking>,
    { bookingType }: PrepareBookingForm,
  ): void {
    const contactFormRules = getContactPersonRules();
    const invoiceContactFromUser = this.store.selectSnapshot(
      CustomerState.customerInfo,
    );
    const invoiceAddressFromUser = this.store.selectSnapshot(
      CustomerState.customerBillingAddress,
    );
    const currentBookingState = ctx.getState().bookingData;
    if (currentBookingState?.type) {
      bookingType = currentBookingState?.type;
    }

    const bookingForm = this.fb.group({
      deliveryAddress: new AddressFormGroup(),

      eventDate: [
        null,
        [Validators.required, minRangeDateValidator(getMinimumDate())],
      ],
      selectedPrint: [null, Validators.required],
      selectedOptions: [],
      contactPerson1: new ContactFormGroup(),
      contactPerson2: new ContactFormGroup({
        phone: '',
        names: '',
        email: 'temp@default.fr',
      }),
      invoiceAddress: new AddressFormGroup(),
      invoiceContact: new ContactFormGroup(),
      acceptCGV: [false, Validators.requiredTrue],
      acceptNewsLetter: [false],
      deliveryDate: [null, []],
      returnDate: [null, []],
      deliveryHour: [null, []],
      returnHour: [null, []],
      companyVat: [
        null,
        [
          vatValidator(),
          ...(bookingType === 'b2b' ? [Validators.required] : []),
        ],
      ],
    });

    bookingForm.addValidators([
      uniqueContactValidator(['contactPerson1', 'contactPerson2']),
    ]);

    if (currentBookingState) {
      if (currentBookingState?.options?.size) {
        bookingForm
          .get('selectedOptions')
          ?.setValue(Array.from(currentBookingState.options.keys()) as any);
      }

      if (
        currentBookingState?.selectedEventDate &&
        currentBookingState?.duration
      ) {
        const eventDateForm = bookingForm.get('eventDate');
        eventDateForm?.setValue({
          startDate: new Date(currentBookingState.selectedEventDate),
          endDate: addDaysToDate(
            new Date(currentBookingState.selectedEventDate),
            currentBookingState.duration - 1,
          ),
        } as any);
        eventDateForm?.clearValidators();
        eventDateForm?.setValidators([
          Validators.required,
          minRangeDateValidator(
            new Date(currentBookingState.selectedEventDate),
          ),
        ]);
      }

      if (currentBookingState?.selectedPrints) {
        bookingForm
          .get('selectedPrint')
          ?.setValue(currentBookingState.selectedPrints as any);
      }

      if (currentBookingState?.person1) {
        bookingForm
          .get('contactPerson1')
          ?.setValue({ ...currentBookingState.person1 });
      }

      if (currentBookingState?.person2) {
        bookingForm
          .get('contactPerson2')
          ?.setValue({ ...currentBookingState.person2 });
      }

      if (currentBookingState?.deliveryAddress) {
        bookingForm.get('deliveryAddress')?.setValue({
          ...addressDataToAddressFormGroupInitValue(
            currentBookingState.deliveryAddress,
          ),
        });
      } else if (invoiceContactFromUser) {
        bookingForm
          .get('invoiceAddress')
          ?.get('city')
          ?.setValue(invoiceContactFromUser?.city);
        bookingForm
          .get('invoiceAddress')
          ?.get('streetName')
          ?.setValue(invoiceContactFromUser?.streetAndNumber);
        bookingForm
          .get('invoiceAddress')
          ?.get('country')
          ?.setValue(invoiceContactFromUser?.country);
        bookingForm
          .get('invoiceAddress')
          ?.get('postalCode')
          ?.setValue(invoiceContactFromUser?.zipCode);
      }

      if (invoiceContactFromUser) {
        const invoiceContactForm = bookingForm.get('invoiceContact');
        invoiceContactForm
          ?.get(contactFormRules.names.fieldName)
          ?.setValue(invoiceContactFromUser.names);
        invoiceContactForm
          ?.get(contactFormRules.email.fieldName)
          ?.setValue(invoiceContactFromUser.email);
        invoiceContactForm
          ?.get(contactFormRules.phone.fieldName)
          ?.setValue(invoiceContactFromUser.phone);
      }

      if (
        currentBookingState?.returnAddress &&
        !addressAreSame(
          currentBookingState.deliveryAddress,
          currentBookingState.returnAddress,
        )
      ) {
        this.store.dispatch(new AddReturnAddressFormGroup()).subscribe(() => {
          if (bookingForm.get('returnAddress')) {
            bookingForm.get('returnAddress')?.setValue({
              ...addressDataToAddressFormGroupInitValue(
                currentBookingState.returnAddress,
              ),
            } as never); // TODO : check this
          }
        });
      }

      if (currentBookingState?.invoiceAddress) {
        bookingForm.get('invoiceAddress')?.setValue({
          ...addressDataToAddressFormGroupInitValue(
            currentBookingState.invoiceAddress,
          ),
        });
      } else if (invoiceAddressFromUser) {
        bookingForm
          .get('invoiceAddress')
          ?.get('city')
          ?.setValue(invoiceAddressFromUser?.city);
        bookingForm
          .get('invoiceAddress')
          ?.get('streetName')
          ?.setValue(invoiceAddressFromUser?.streetName);
        bookingForm
          .get('invoiceAddress')
          ?.get('country')
          ?.setValue(invoiceAddressFromUser?.country);
        bookingForm
          .get('invoiceAddress')
          ?.get('postalCode')
          ?.setValue(invoiceAddressFromUser?.postalCode);
      }

      if (currentBookingState?.canBeAllEdited === false) {
        bookingForm.disable();
      } else {
        bookingForm.markAllAsTouched();
      }
    }

    const toDispatch = [];
    if (!ctx.getState().bookingData?.id) {
      toDispatch.push(new InitBooking(bookingType));
    }
    toDispatch.push(new SetBookingFormGroup(bookingForm));
    this.store.dispatch(toDispatch);
  }

  @Action(SetBookingStep)
  public setBookingStep(
    ctx: StateContext<Booking>,
    { data }: SetBookingStep,
  ): void {
    if (this.previousBookingFormSubscription$) {
      this.previousBookingFormSubscription$.unsubscribe();
      this.previousBookingFormSubscription$ = undefined;
    }
    ctx.patchState({ currentStep: data });
    this.previousBookingFormSubscription$ = ctx
      .getState()
      .bookingForm?.valueChanges.subscribe((form) => {
        ctx.patchState({});
      });
  }

  @Action(GoNextStep)
  public goNextStep(ctx: StateContext<Booking>): void {
    let currentStep = ctx.getState().currentStep;
    const bookingData = ctx.getState().bookingData;
    if ((currentStep ?? 0) > 7 && !!this.store.selectSnapshot(LoginState.jwt)) {
      currentStep = 2.5;
    }

    switch (currentStep) {
      case 1:
        this.saveAddressInState(ctx);
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_DATE}`]),
        );
        break;
      case 2:
        this.saveEventDateInState(ctx);
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_HOURS}`]),
        );

        break;
      case 2.5:
        const isUps = false;

        this.gtmService.pushEvent({
          event: 'begin_checkout',
          country: bookingData?.deliveryAddress?.country,                               // FR, LU ou BE
          code_postal: bookingData?.deliveryAddress?.postalCode,
          date_event: bookingData?.selectedEventDate,
          type: bookingData?.type,                                 // ou B2B
          livraison_type: 'ups',                      // standard, sur-mesure, ups
          items: [
            {
              item_name: isUps ? 'Borne Expédiée' : 'Borne Classique',
              item_id: isUps ? 'borne-expedier' : 'borne-classique'
            }
          ]
        });
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_OPTIONS}`]),
        );
        break;
      case 3:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_CONTACTS}`]),
        );
        break;
      case 4:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_INVOICE}`]),
        );
        break;
      case 5:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.CONFIRMATION}`]),
        );
        break;
      case 6:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.DETAILS}`]),
        );
        break;
      case 7:
        const isUps2 = false;
        this.gtmService.pushEvent({
          event: 'purchase',
          country: bookingData?.deliveryAddress?.country,                               // FR, LU ou BE
          code_postal: bookingData?.deliveryAddress?.postalCode,
          date_event: bookingData?.selectedEventDate,
          type: bookingData?.type,                                 // ou B2B
          livraison_type: 'ups',                      // standard, sur-mesure, ups
          forfait_impression: '400',
          value:bookingData?.totalAmount,
          currency:'EUR',
          items: [
            {
              item_name: isUps2 ? 'Borne Expédiée' : 'Borne Classique',
              item_id: isUps2 ? 'borne-expedier' : 'borne-classique'
            }
          ]
        });
        this.store.dispatch(new SaveOrder()).subscribe(() => {});
        break;
    }
  }

  @Action(SaveOrder)
  public saveOrder(ctx: StateContext<Booking>): void {
    const state = ctx.getState();
    if (state) {
      const dataToSave = this.bookingFormToCreateBookingDto(state);
      if (dataToSave && !state.stripeUrl) {
        ctx.patchState({
          isLoading: true,
        });
        this.bookingService.saveOrder(dataToSave).subscribe(
          (_) => {
            window.location.href = _;
            ctx.patchState({ isLoading: false, stripeUrl: _ });
          },
          (err) => {
            ctx.patchState({ isLoading: false });

            ctx.patchState({
              currentStepMessages: [
                {
                  message: this.translateService.instant(
                    `BOOKING.${err.error.errorMessage.moreInformation[0].errorCode}`,
                  ),
                  type: 'error',
                },
              ],
            });
          }
        );
      }else if(state.stripeUrl){
        window.location.href = state.stripeUrl;

      }
    }
  }

  @Action(PayOrder)
  public payBooking(ctx: StateContext<Booking>,
  { bookingId }: PayOrder): void {
    const state = ctx.getState();
    if (state) {
        ctx.patchState({
          isLoading: true,
        });
        this.bookingService.payBooking(bookingId).subscribe(
          (_) => {
            window.location.href = _;
          },
          () => ctx.patchState({ isLoading: false }),
        );
    }
  }


  @Action(InitBooking)
  public initBooking(
    { patchState }: StateContext<Booking>,
    { bookingType }: InitBooking,
  ): void {
    patchState({
      bookingData: initNewBooking(bookingType ?? 'b2c'),
      availableOptions: Object.keys(AvailableOptions)
        .filter((_) => !NOT_SELECTABLE_OPTIONS.includes(_ as AvailableOptions))
        .map((key) => AvailableOptions[key as keyof typeof AvailableOptions]),
      availablePrints: AVAILABLE_PRINTS.filter((_) => _.isActive),
    });
  }

  /**
   * Action method to verify the date in the booking state context.
   * @param {StateContext<Booking>} ctx - The state context for the booking.
   * @returns None
   */
  @Action(VerifyDate)
  public verifyDate(ctx: StateContext<Booking>): void {
    const currentState = ctx.getState();
    const eventDate = currentState.bookingForm?.get('eventDate')?.value as
      | DateRange
      | undefined;
    if (eventDate?.startDate) {
      this.store.dispatch(new SetIsLoading(true));

      const duration =
        compareDate(eventDate?.startDate, eventDate?.endDate) + 1;
      this.bookingService
        .verifyDate({
          eventDate: formatDate(eventDate?.startDate, false),
          duration: duration,
          postalCode:
            currentState.bookingData?.deliveryAddress?.postalCode ?? '',
          country:
            currentState.bookingData?.deliveryAddress?.country ??
            DeliveryAvailableCountries.FR,
        })
        .pipe(
          catchError(() => {
            return of(undefined);
          }),
          tap((data) => {
            this.store.dispatch(new SetIsLoading(false));

            if (data?.isAvailable) {
              const currentBooking = ctx.getState().bookingData;
              if (currentBooking) {
                currentBooking.selectedEventDate = eventDate.startDate;
                currentBooking.infoForUser = {
                  ...currentBooking.infoForUser,
                  deliveryDate: data.deliveryDate,
                  returnDate: data.returnDate,
                  possibleSecondDeliveryDate: data.possibleSecondDeliveryDate,
                  possibleSecondReturnDate: data.possibleSecondReturnDate,
                  confirmationDeliveryDate: data.confirmationDeliveryDate,
                  confirmationReturnDate: data.confirmationReturnDate,
                };
              }

              ctx.patchState({
                bookingData: currentBooking,
              });
              if (currentState.type === 'b2b') {
                this.store.dispatch([
                  new SetPossibleDeliveryDates(
                    getPossibleDeliveryDateForB2BEventDate(
                      eventDate.startDate,
                    ).map((_) => formatDate(_)),
                  ),
                  new SetPossibleReturnDates(
                    getPossibleReturnDateForB2BEventDate(eventDate.endDate).map(
                      (_) => formatDate(_),
                    ),
                  ),
                ]);
              }
              if (currentState.type === 'b2c') {
                this.store.dispatch([
                  new SetPossibleDeliveryDates(
                    getPossibleDeliveryDateForB2CEventDate(
                      eventDate.startDate,
                    ).map((_) => formatDate(_)),
                  ),
                  new SetPossibleReturnDates(
                    getPossibleReturnDateForB2CEventDate(eventDate.endDate).map(
                      (_) => formatDate(_),
                    ),
                  ),
                ]);
              }
              this.store.dispatch(new SetDuration(duration));
              this.store.dispatch(new GoNextStep());
            } else {
              ctx.patchState({
                currentStepMessages: [
                  {
                    message: this.translateService.instant(
                      'BOOKING.NO_AVAILABILITY',
                    ),
                    type: 'error',
                  },
                ],
              });
            }
          }),
        )
        .subscribe();
    }
  }

  @Action(SetPrints)
  public setPrints(
    ctx: StateContext<Booking>,
    { selectedPrint }: SetPrints,
  ): void {
    const tmp = ctx.getState().bookingData;
    if (tmp) {
      tmp.selectedPrints = selectedPrint;
      ctx.patchState({ bookingData: tmp });
    }
  }

  @Action(SetOptions)
  public setOptions(ctx: StateContext<Booking>, { data }: SetOptions): void {
    const tmp = ctx.getState().bookingData;
    if (tmp) {
      data.forEach((option) => {
        if (!option.quantity) {
          tmp.options?.delete(option.option);
          if (option.option === AvailableOptions.deliveryCustomMade) {
            tmp.options?.delete(AvailableOptions.b2bNightDelivery);
            tmp.options?.delete(AvailableOptions.b2bNightReturn);
          }
        } else {
          tmp.options?.set(option.option, option.quantity);
        }
      });

      ctx.patchState({ bookingData: tmp });
      const bookingForm = ctx.getState().bookingForm;
      bookingForm
        ?.get('selectedOptions')
        ?.setValue(Array.from(tmp?.options?.keys() ?? []), {
          emitEvent: false,
        });
      let shouldUpdateFormControl = false;
      if (
        tmp.options?.has(AvailableOptions.deliveryCustomMade) &&
        !bookingForm?.get('deliveryDate')?.hasValidator(Validators.required)
      ) {
        bookingForm?.get('deliveryDate')?.setValidators([Validators.required]);
        bookingForm?.get('returnDate')?.setValidators([Validators.required]);
        if (ctx.getState().type === 'b2b') {
          bookingForm?.get('deliveryHour')?.setValidators([Validators.required]);
          bookingForm?.get('returnHour')?.setValidators([Validators.required]);
        }
        shouldUpdateFormControl = true;
      } else if (
        bookingForm?.get('deliveryDate')?.hasValidator(Validators.required)
      ) {
        bookingForm?.get('deliveryDate')?.clearValidators();
        bookingForm?.get('returnDate')?.clearValidators();
        if (ctx.getState().type === 'b2b') {
          bookingForm?.get('deliveryHour')?.clearValidators();
          bookingForm?.get('returnHour')?.clearValidators();
        }
        shouldUpdateFormControl = true;
      }
      if (shouldUpdateFormControl) {
        bookingForm?.get('deliveryDate')?.updateValueAndValidity();
        bookingForm?.get('returnDate')?.updateValueAndValidity();
        bookingForm?.get('deliveryHour')?.updateValueAndValidity();
        bookingForm?.get('returnHour')?.updateValueAndValidity();
      }
      if (shouldUpdateFormControl) {
        ctx.patchState({ bookingForm });
      }
    }
  }

  @Action(SelectStandardDelivery)
  public selectStandardDelivery(
    ctx: StateContext<Booking>,
    { data }: SelectStandardDelivery,
  ): void {
    const bookingForm = ctx.getState().bookingForm;
    if (data) {
      bookingForm?.patchValue({
        deliveryDate: null,
        deliveryHour: null,
        returnDate: null,
        returnHour: null,
      });
      bookingForm?.get('deliveryDate')?.setValidators([]);
      bookingForm?.get('returnDate')?.setValidators([]);
      bookingForm?.get('deliveryDate')?.updateValueAndValidity({emitEvent:false});
      bookingForm?.get('returnDate')?.updateValueAndValidity({emitEvent:false});
      if (ctx.getState().type === 'b2b') {
        bookingForm?.get('deliveryHour')?.setValidators([]);
        bookingForm?.get('returnHour')?.setValidators([]);
        bookingForm?.get('deliveryHour')?.updateValueAndValidity({emitEvent:false});
        bookingForm?.get('returnHour')?.updateValueAndValidity({emitEvent:false});
      }
    } else {
      bookingForm?.get('deliveryDate')?.setValidators([Validators.required]);
      bookingForm?.get('returnDate')?.setValidators([Validators.required]);
      bookingForm?.get('deliveryDate')?.updateValueAndValidity({emitEvent:false});
      bookingForm?.get('returnDate')?.updateValueAndValidity({emitEvent:false});
      if (ctx.getState().type === 'b2b') {
        bookingForm?.get('deliveryHour')?.setValidators([Validators.required]);
        bookingForm?.get('returnHour')?.setValidators([Validators.required]);
        bookingForm?.get('returnHour')?.updateValueAndValidity({emitEvent:false});
        bookingForm?.get('deliveryHour')?.updateValueAndValidity({emitEvent:false});
      }
    }
    ctx.patchState({ bookingForm });
  }
  @Action(UpdateForm)
  public UpdateForm(
    ctx: StateContext<Booking>
  ): void {
    ctx.patchState({
      bookingForm: ctx
        .getState()
        .bookingForm
    });
  }
  @Action(SetIsLoading)
  public setIsLoading(
    ctx: StateContext<Booking>,
    { isLoading }: SetIsLoading,
  ): void {
    ctx.patchState({ isLoading });
    if (isLoading) {
      ctx.getState().bookingForm?.disable();
    } else {
      ctx.getState().bookingForm?.enable();
    }
  }

  @Action(SetBookingData)
  public setBookingData(
    ctx: StateContext<Booking>,
    { bookingData }: SetBookingData,
  ): void {
    ctx.patchState({ bookingData: bookingData });
  }

  @Action(SetPossibleDeliveryDates)
  public SetPossibleDeliveryDates(
    ctx: StateContext<Booking>,
    { dates }: SetPossibleDeliveryDates,
  ): void {
    ctx.patchState({ possibleDeliveryDates: dates ?? [] });
  }

  @Action(SetPossibleReturnDates)
  public SetPossibleReturnDates(
    ctx: StateContext<Booking>,
    { dates }: SetPossibleReturnDates,
  ): void {
    ctx.patchState({ possibleReturnDates: dates ?? [] });
  }

  @Action(SetDuration)
  public SetDuration(ctx: StateContext<Booking>, { duration }: SetDuration) {
    ctx.patchState({
      bookingData: { ...ctx.getState().bookingData, duration },
    });
    this.store.dispatch(
      new SetOptions([
        {
          option:
            ctx.getState().type === 'b2c'
              ? AvailableOptions.b2cDay
              : AvailableOptions.b2bDay,
          quantity: duration,
        },
      ]),
    );
  }

  @Action(CheckPromoCode)
  public checkPromoCode(
    { patchState, getState }: StateContext<Booking>,
    { code }: CheckPromoCode,
  ): void {
    this.bookingService
      .checkPromoCode(code)
      .pipe()
      .subscribe((data) => {
        patchState({
          promoCode: data,
          bookingData: { ...getState().bookingData, promoCode: data },
        });
      });
  }

  @Action(RemovePromoCode)
  public removePromoCode({
    patchState,
    getState,
  }: StateContext<Booking>): void {
    patchState({
      promoCode: undefined,
      bookingData: { ...getState().bookingData, promoCode: undefined },
    });
  }

  @Action(VerifyAddress)
  public verifyAddress(ctx: StateContext<Booking>): void {
    const deliveryAddress = ctx
      .getState()
      .bookingForm?.get('deliveryAddress') as AddressFormGroup;
    const returnAddress = ctx
      .getState()
      .bookingForm?.get('returnAddress') as AddressFormGroup;

    if (deliveryAddress) {
      this.store.dispatch(new SetIsLoading(true));
      this.addressService
        .verify(this.addressFormToVerifyAddressDto(deliveryAddress))
        .pipe(
          take(1),
          tap((data) => {
            if (data.isValid) {
              if(returnAddress){
                this.addressService
                  .verify(this.addressFormToVerifyAddressDto(returnAddress))
                  .subscribe((d) => {
                      this.store.dispatch(new SetIsLoading(false));
                      if (d.isValid) {
                        this.store.dispatch([
                          new GoNextStep(),
                          new SetOptions([
                            {
                              option:
                                ctx.getState().type === 'b2c'
                                  ? AvailableOptions.b2cDelivery
                                  : AvailableOptions.b2bDelivery,
                              quantity: data.isFreeDelivery ? 0 : 1,
                            },
                          ]),
                        ]);
                        ctx.patchState({
                          currentStepMessages: [],
                        });
                      } else {
                        ctx.getState().bookingForm?.get('returnAddress')?.setErrors({
                          notDeliverable: true,
                        });
                        ctx.patchState({
                          currentStepMessages: [
                            {
                              message: this.translateService.instant(
                                'BOOKING.RETURN_ADDRESS_NOT_DELIVERABLE',
                              ),
                              type: 'error',
                            },
                          ],
                        });
                      }

                    });
              }else{
                this.store.dispatch(new SetIsLoading(false));
                  this.store.dispatch([
                    new GoNextStep(),
                    new SetOptions([
                      {
                        option:
                          ctx.getState().type === 'b2c'
                            ? AvailableOptions.b2cDelivery
                            : AvailableOptions.b2bDelivery,
                        quantity: data.isFreeDelivery ? 0 : 1,
                      },
                    ]),
                  ]);
                  ctx.patchState({
                    currentStepMessages: [],
                  });
              }
            } else {
              this.store.dispatch(new SetIsLoading(false));
              ctx.getState().bookingForm?.get('deliveryAddress')?.setErrors({
                notDeliverable: true,
              });
              ctx.patchState({
                currentStepMessages: [
                  {
                    message: this.translateService.instant(
                      'BOOKING.ADDRESS_NOT_DELIVERABLE',
                    ),
                    type: 'error',
                  },
                ],
              });
            }
          }),
          catchError(() => {
            this.store.dispatch(new SetIsLoading(false));
            return of(false);
          }),
        )
        .subscribe();
    }
  }

  // getWarehouseAddress
  @Action(GetWarehouseAddress)
  public getWarehouseAddress(ctx: StateContext<Booking>): void {
    const selectedEventDate = ctx.getState().bookingForm?.get('eventDate')
      ?.value?.startDate;
    const deliveryAddress = ctx
      .getState()
      .bookingForm?.get('deliveryAddress') as AddressFormGroup;
    const addressData = addressFormGroupToAddressData(deliveryAddress);

    if (addressData?.country && selectedEventDate) {
      this.store.dispatch(new SetIsLoading(true));
      this.bookingService
        .getWarehouseAddress(
          addressData.country,
          addressData.postalCode,
          selectedEventDate,
        )
        .pipe(
          tap((data) => {
            this.store.dispatch(new SetIsLoading(false));
            if (data) {
              const warehouseAddress = data.real_linked_warehouse || data;
              ctx.patchState({
                bookingData: {
                  ...ctx.getState().bookingData,
                  relatedAgency: warehouseAddress.relatedAgency,
                },
              });
            }
          }),
          catchError(() => {
            this.store.dispatch(new SetIsLoading(false));
            return of(false);
          }),
        )
        .subscribe();
    }
  }

  @Action(DeleteFrame)
  public deleteFrame(
    { patchState, getState }: StateContext<Booking>,
    { frame }: DeleteFrame,
  ): void {
    const currentBooking = getState().bookingData;
    if (currentBooking) {
      this.bookingService.deleteFrame(frame).subscribe((res) => {
        currentBooking.configs = currentBooking?.configs?.filter(
          (conf: any) => conf.new_config_tool_id !== frame.new_config_tool_id,
        );
        patchState({ bookingData: currentBooking });
      });
    }
  }

  @Action(UpdateFrame)
  public updateFrame({ patchState, getState }: StateContext<Booking>): void {
    const currentBooking = getState().bookingData;
    if (currentBooking) {
      this.bookingService.updateFrame(currentBooking).subscribe((res) => {
        currentBooking.configs = res?.configs;
        patchState({ bookingData: currentBooking });
      });
    }
  }

  private addressFormToVerifyAddressDto(
    deliveryAddress: AddressFormGroup,
  ): IVerifyAddressRequest {
    const rules = getAddressRules();

    return {
      city: deliveryAddress.get(rules.city.fieldName)?.value,
      streetName: deliveryAddress.get(rules.streetName.fieldName)?.value,
      postalCode: deliveryAddress.get(rules.postalCode.fieldName)?.value,
      country: deliveryAddress.get(rules.country.fieldName)?.value,
    };
  }

  private saveEventDateInState(ctx: StateContext<Booking>) {
    const currentBooking =
      ctx.getState().bookingData ||
      initNewBooking(ctx.getState().type ?? 'b2c');

    const dateFromCalendar = ctx.getState().bookingForm?.get('eventDate')
      ?.value as DateRange;

    currentBooking.selectedEventDate = dateFromCalendar?.startDate;
    currentBooking.duration =
      compareDate(dateFromCalendar.startDate, dateFromCalendar.endDate) + 1;

    ctx.patchState({
      bookingData: currentBooking,
    });
  }

  private saveAddressInState(ctx: StateContext<Booking>) {
    const currentBooking =
      ctx.getState().bookingData ||
      initNewBooking(ctx.getState().type ?? 'b2c');
    const deliveryAddress = ctx
      .getState()
      .bookingForm?.get('deliveryAddress') as AddressFormGroup;
    const deliveryAddressToSet: AddressData =
      addressFormGroupToAddressData(deliveryAddress);
    const returnAddress = ctx
      .getState()
      .bookingForm?.get('returnAddress') as AddressFormGroup;
    const returnAddressToSet: AddressData = returnAddress
      ? addressFormGroupToAddressData(returnAddress)
      : deliveryAddressToSet;
    ctx.patchState({
      bookingData: {
        ...currentBooking,
        deliveryAddress: deliveryAddressToSet,
        returnAddress: returnAddressToSet,
      },
    });
  }

  private bookingFormToCreateBookingDto(
    state: Booking,
  ): ICreateOrderRequest | undefined {
    const source = state.bookingForm;
    const bookingData = state.bookingData;
    if (!source || !bookingData) {
      return undefined;
    }
    const contact1 = BookingState.getContactPersonInfoFromForm(
      'contactPerson1',
      state,
    );
    const contact2 = BookingState.getContactPersonInfoFromForm(
      'contactPerson2',
      state,
    );

    const invoiceContact = BookingState.getContactPersonInfoFromForm(
      'invoiceContact',
      state,
    );

    if (!contact1 || !contact2 || !invoiceContact) {
      return undefined;
    }
    const deliveryAddress = addressFormGroupToAddressData(
      source.get('deliveryAddress') as AddressFormGroup,
    );

    let returnAddress = addressFormGroupToAddressData(
      source.get('returnAddress') as AddressFormGroup,
    );

    const invoiceAddress = addressFormGroupToAddressData(
      source.get('invoiceAddress') as AddressFormGroup,
    );

    if (!returnAddress?.postalCode) {
      // detect if return address is the same as delivery address
      returnAddress = { ...deliveryAddress };
    }
    return {
      deliveryAddress: deliveryAddress,
      returnAddress: returnAddress,
      selectedEventDate: source.get('eventDate')?.value?.startDate,
      contact1: {
        names: contact1.names,
        phone: contact1.phone,
        email: contact1.email,
      },
      contact2: {
        names: contact2.names,
        phone: contact2.phone,
        email: contact2.email,
      },
      bookingType: state.type ?? 'b2c',
      duration:
        compareDate(
          source.get('eventDate')?.value?.startDate,
          source.get('eventDate')?.value?.endDate,
        ) + 1,
      selectedPrints: {
        id: source.get('selectedPrint')?.value?.id,
      },
      options: bookingData.options,
      paymentMethod: bookingData.paymentMethod ?? OrderPaymentMethod.delay, // TODO : change
      promoCode: bookingData.promoCode?.promoCode ?? undefined,
      siteVersion:
        this.store.selectSnapshot(CustomerState.currentSiteVersion) ||
        DeliveryAvailableCountries.FR,
      language: this.translateService.currentLang.toUpperCase() as Languages,
      askedDeliveryDate:
        state.type === 'b2b' ? source.get('deliveryDate')?.value : undefined,
      askedReturnDate:
        state.type === 'b2b' ? source.get('returnDate')?.value : undefined,
      askedDeliveryTime:
        state.type === 'b2b' ? source.get('deliveryHour')?.value : undefined,
      askedReturnTime:
        state.type === 'b2b' ? source.get('returnHour')?.value : undefined,
      invoiceContact: {
        names: invoiceContact.names,
        phone: invoiceContact.phone,
        email: invoiceContact.email,
      },
      invoiceAddress,
      invoiceInfo:
        state.type === 'b2b'
          ? {
              companyName: invoiceContact?.names,
              companyVat: source.get('companyVat')?.value,
            }
          : undefined,
    };
  }
}
