<div class="increment-decrement">
  <button (click)="decrement()" [disabled]="minValue && value - 1 < minValue">
    -
  </button>
  <span
  >{{ value }}
    <ng-container *ngIf="incrementLabel"
    ><ng-container *ngIf="value < 2; else plural">{{
        incrementLabel.singular | translate
      }}</ng-container
    ><ng-template #plural>{{
        incrementLabel.plural | translate
      }}</ng-template>
    </ng-container>
  </span>
  <button (click)="increment()" [disabled]="maxValue && value + 1 > maxValue">
    +
  </button>
</div>
