import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SetCurrentSiteVersion } from '@burddy-monorepo/front/shared/data';
import {
  ButtonComponent,
  CloudsComponent,
} from '@burddy-monorepo/front/shared/ui-kit';
import { DeliveryAvailableCountries } from '@burddy-monorepo/shared/shared-data';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { BookingRoutes } from 'apps/front/booking/src/features/booking/booking-routes.enum';
import { CookieService } from 'ngx-cookie-service';
import { filter, Observable } from 'rxjs';

import { HeaderState } from '../data/states';
import { BookingHeaderData } from '../features';
import { BookingHeaderComponent } from '../features/booking-header/booking-header.component';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    BookingHeaderComponent,
    ButtonComponent,
    AsyncPipe,
    NgIf,
    CloudsComponent,
  ],
  selector: 'burddy-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Burddy';
  @Select(HeaderState.headerData) headerData$!: Observable<BookingHeaderData>;

  public shouldShowClouds = true;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private store: Store,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.translateService.setDefaultLang('fr');

    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts?.length) {
      const domainExtension = parts[parts.length - 1];
      if (domainExtension !== 'localhost') {
        this.store.dispatch(
          new SetCurrentSiteVersion(
            domainExtension.toUpperCase() as DeliveryAvailableCountries,
          ),
        );
      }
    }

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.setShouldShowOrNotCloudComponent(
          (event as NavigationEnd).urlAfterRedirects,
        );
      });
  }

  setShouldShowOrNotCloudComponent(currentUrl: string) {
    const urlSplitted = currentUrl.replace('/', '').split('/');
    if (urlSplitted.length) {
      switch (urlSplitted[0]) {
        case 'booking':
          if (
            urlSplitted.length > 1 &&
            urlSplitted[1] !== BookingRoutes.SELECT_PRO_OR_PRIVATE
          ) {
            this.shouldShowClouds = false;
          } else {
            this.shouldShowClouds = true;
          }
          break;
        default:
          this.shouldShowClouds = true;
      }
    }
  }
}
