export function getCookie(nomDuCookie: string): string | undefined {
  const cookies = document.cookie.split(';'); // Sépare tous les cookies par un point-virgule pour obtenir un tableau de cookies

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // Retire les espaces en début et fin de chaque cookie
    const cookieParts = cookie.split('='); // Sépare le nom du cookie de sa valeur

    // Si le nom du cookie correspond à ce que vous recherchez, retournez sa valeur
    if (cookieParts[0] === nomDuCookie) {
      return decodeURIComponent(cookieParts[1]);
    }
  }

  return undefined;
}

export function downloadFileFromBuffer(data: string, fileName: string) {
  const blob = new Blob([data]);
  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
