<h5 *ngIf="title" [innerHTML]="title"></h5>
<ng-container *ngIf="data$ | async as data">
  <ng-container [formGroup]="formGroup">
    <ng-container *ngIf="formGroup">
      <div class="-form-line">
        <div class="-form-item">
          <label for="login">{{ 'FORM.LOGIN.LOGIN' | translate }}</label>
          <input
            (keyup.enter)="goToLogin()"
            [formControlName]="loginRules.login.fieldName"
            [maxlength]="+(loginRules.login.maxLength ?? 99999)"
            [minlength]="+(loginRules.login.minLength ?? 0)"
            [placeholder]="'Ex: Sébastien@gmail.com'"
            name="login"
            type="email"
          />
        </div>
      </div>

      <hr *ngIf="isForCreation" />
      <div class="-form-line">
        <div class="-form-item">
          <label>{{ 'FORM.LOGIN.PASSWORD' | translate }}</label>
          <input
            #password
            (keyup.enter)="goToLogin()"
            [formControlName]="loginRules.password.fieldName"
            type="password"
          />
        </div>
      </div>
      <div *ngIf="isForCreation" class="-form-line">
        <div class="-form-item -row">
          <small
            [ngClass]="{ valid: password.value.match(MIN_8_CHARACTERS_REGEX) }"
            class="-checkMark"
          >
            {{
              'LOGIN.CREATE_OR_LOG.CHECK_PASSWORD_RULES.MORE_THAN_8_CHARACTERS'
                | translate
            }}
          </small>
          <small
            [ngClass]="{
              valid: password.value.match(WITH_AT_LEAST_1_UPPERCASE_REGEX)
            }"
            class="-checkMark"
          >
            {{
              'LOGIN.CREATE_OR_LOG.CHECK_PASSWORD_RULES.ONE_UPPER_CASE'
                | translate
            }}
          </small>
          <small
            [ngClass]="{
              valid: password.value.match(
                WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX
              )
            }"
            class="-checkMark"
          >
            {{
              'LOGIN.CREATE_OR_LOG.CHECK_PASSWORD_RULES.ONE_SPECIAL_CASE'
                | translate
            }}
          </small>
          <small
            [ngClass]="{
              valid: password.value.match(WITH_AT_LEAST_1_NUMBER_REGEX)
            }"
            class="-checkMark"
          >
            {{
              'LOGIN.CREATE_OR_LOG.CHECK_PASSWORD_RULES.ONE_NUMBER_CASE'
                | translate
            }}
          </small>
        </div>
      </div>

      <div *ngIf="isForCreation" class="-form-line">
        <div class="-form-item">
          <label>{{ 'FORM.LOGIN.CONFIRM_PASSWORD' | translate }}</label>
          <input
            [formControlName]="loginRules.confirmPassword.fieldName"
            type="password"
          />
          <small *ngIf="formGroup.hasError('SAME_VALUE')" class="error">{{
              'FORM.LOGIN.PASSWORD_ARE_DIFFERENT' | translate
            }}</small>
        </div>
      </div>
      <a
        (click)="goToPasswordRecovery()"
        *ngIf="!isForCreation"
        class="clickable"
      >{{ 'FORM.LOGIN.PASSWORD_FORGOTTEN' | translate }}</a
      >
    </ng-container>

    <div class="-row -start">
      <burddy-monorepo-button
        (clickEvent)="clickPrimaryButton()"
        [class]="'primary medium'"
        [disabled]="!formGroup.valid || data[0]"
      >
        <span *ngIf="!data[0]">
          <span *ngIf="isForCreation">
            {{ 'FORM.LOGIN.REGISTER' | translate }}
          </span>
          <span *ngIf="!isForCreation">
            {{ 'FORM.LOGIN.CONNECT_BUTTON' | translate }}
          </span>
        </span>
        <span *ngIf="data[0]" left>
          <span *ngIf="isForCreation">
            {{ 'FORM.LOGIN.REGISTERING' | translate }}
          </span>
          <span *ngIf="!isForCreation">
            {{ 'FORM.LOGIN.CONNECTING_BUTTON' | translate }}
          </span>
        </span>
        <burddy-monorepo-spinner
          *ngIf="data[0]"
          right
        ></burddy-monorepo-spinner>
      </burddy-monorepo-button>
<!--
      <a [href]="fbUrl" class="clickable facebook">
        <div class="-row -space-between">
          <img alt="facebook logo" src="assets/img/icons/facebook.svg" />
          <span>{{ 'FORM.LOGIN.FACEBOOK_CONNECT' | translate }}</span>
        </div>
      </a>-->
    </div>
  </ng-container>
</ng-container>
