import { NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BookingData } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

import { CardComponent } from '@burddy-monorepo/front/shared/ui-kit';
import { BookingCardComponent } from '../../booking-card/booking-card.component';

@Component({
  selector: 'burddy-monorepo-booking-cards-list',
  standalone: true,
  imports: [NgFor, NgIf, BookingCardComponent, CardComponent, TranslateModule],
  templateUrl: './booking-cards-list.component.html',
  styleUrls: ['./booking-cards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCardsListComponent implements AfterViewInit {
  @Input() bookings!: BookingData[];
  @Output() loadMore = new EventEmitter<void>();
  @Output() clickOnAdd = new EventEmitter<void>();
  @ViewChild('cardsList') cardsList!: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    if (this.cardsList) {
      this.cardsList.nativeElement.addEventListener(
        'scroll',
        this.onScroll.bind(this),
      );
    }
  }

  addBooking(): void {
    this.clickOnAdd.emit();
  }

  onScroll(event: Event) {
    const element = event?.target as HTMLDivElement;
    const rect = element.getBoundingClientRect();
    if (event?.target) {
      const scrollTop = (event.target as any)['scrollTop'];
      const scrollHeight = (event.target as any)['scrollHeight'];
      const height = rect.height;
      if (scrollTop + height >= scrollHeight - 15) {
        this.loadMore.emit();
      }
    }
  }
}
