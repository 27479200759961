import { FormControl, FormGroup } from '@angular/forms';
import {
  getValidatorsBasedOnRules,
  sameValueValidator,
} from '@burddy-monorepo/front/shared/validators';
import { getLoginFormRules } from '@burddy-monorepo/shared/shared-data';

export interface PasswordModificationFormGroupInitValues {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export class PasswordModificationFormGroup extends FormGroup {
  constructor(init?: PasswordModificationFormGroupInitValues | undefined) {
    const rules = getLoginFormRules(false, true);
    super({
      [rules.currentPassword?.fieldName ?? '']: new FormControl(
        init?.currentPassword,
        getValidatorsBasedOnRules(rules.currentPassword),
      ),
      [rules.password.fieldName]: new FormControl(
        init?.newPassword,
        getValidatorsBasedOnRules(rules.password),
      ),
      [rules.confirmPassword.fieldName]: new FormControl(
        init?.confirmPassword,
        getValidatorsBasedOnRules(rules.confirmPassword),
      ),
    });

    this.addValidators([
      sameValueValidator([
        rules.password.fieldName,
        rules.confirmPassword.fieldName,
      ]),
    ]);
  }
}
