import { Route } from '@angular/router';

import { LoginRoutes } from '../login-routes.enum';
import { BookingRoutes } from '../../booking/booking-routes.enum';
import { headerResolver } from '../../../route-resolvers';

export const loginRoutes: Route[] = [
  {
    path: LoginRoutes.LOG_OR_CREATE,
    loadComponent: () =>
      import('@burddy-monorepo/front/shared/components').then(
        (m) => m.LogOrCreateComponent,
      ),
  },
  {
    path: LoginRoutes.RESET_PASSWORD,
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent,
      ),
  },
  {
    path: LoginRoutes.FORGOT_PASSWORD,
    loadComponent: () =>
      import('./forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent,
      ),
  },
  {
    path: '**',
    redirectTo: LoginRoutes.LOG_OR_CREATE,
    pathMatch: 'full',
  },
];
