import { DatePipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { BookingService } from '@burddy-monorepo/front/shared/services';
import {
  BadgeComponent,
  ButtonComponent,
  CardComponent,
  SpinnerComponent,
} from '@burddy-monorepo/front/shared/ui-kit';
import { BookingData, OrderStatus } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { DeleteBooking, PayOrder } from '@burddy-monorepo/front/shared/data';

@Component({
  selector: 'burddy-monorepo-booking-card',
  standalone: true,
  imports: [
    NgIf,
    CardComponent,
    BadgeComponent,
    NgClass,
    TranslateModule,
    ButtonComponent,
    SpinnerComponent,
  ],
  providers: [DatePipe],
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCardComponent {
  @Input() booking!: BookingData;

  public isLoading = false;
  private _datePipe = inject(DatePipe);
  private _store = inject(Store);
  private _bookingService = inject(BookingService);
  private _cdr = inject(ChangeDetectorRef);

  get isPassedOrder(): boolean {
    return (
      (this.booking?.selectedEventDate?.getTime() ?? 0) < new Date().getTime()
    );
  }

  get isPending(): boolean {
    return (
       this.booking.status === OrderStatus.Pending
    );
  }

  get isComplete(): boolean {
    return (
      this.booking.status === OrderStatus.Complete || this.booking.status===OrderStatus.AddingOptionsPayementComplete
    );
  }

  get classOfStatus(): 'warning' | 'success' | 'error' {
    switch (this.booking.status) {
      case OrderStatus.Complete:
      case OrderStatus.AddingOptionsPayementComplete:
        return 'success';
      case OrderStatus.Pending:
      case OrderStatus.AddingOptionsPayementPending:
        return 'warning';
      case OrderStatus.AddingOptionsPayementFailed:
      case OrderStatus.Delete:
      case OrderStatus.Failed:
        return 'error';
      default:
        return 'error';
    }
  }

  get optionsQuantity(): number {
    return (
      [...(this.booking?.options?.entries() ?? [])].reduce((acc, [, value]) => {
        return acc + value;
      }, 0) ?? 0
    );
  }

  get printValue(): number {
    return this.booking?.selectedPrints?.quantity ?? 0;
  }

  get eventName(): string {
    if (this.booking?.configs?.length) {
      return this.booking.configs[0].eventName ?? '';
    }
    return '';
  }

  get dateTitle(): string {
    if (!this.booking) {
      return '';
    }
    if (this.booking.duration && this.booking.duration > 1) {
      return `${this.booking.duration} days`;
    } else {
      return (
        this._datePipe.transform(
          this.booking.selectedEventDate,
          'd MMMM yyyy',
        ) ?? ''
      );
    }
  }

  editConfig() {
    this._store.dispatch([
      new Navigate([`customize-booking/${this.booking?.id}/main-info`]),
    ]);
  }

  downloadInvoice() {
    this.isLoading = true;
    this._bookingService
      .getInvoiceAPIUrl(this.booking.id ?? '')
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          throw err;
        }),
      )
      .subscribe((data) => {
        const { url, token } = data;
        this._bookingService
          .downloadInvoiceFile(this.booking.id ?? '', url, token)
          .pipe(
            catchError((err) => {
              this.isLoading = false;
              throw err;
            }),
          )
          .subscribe();

        this.isLoading = false;
        this._cdr.detectChanges();
      });
  }

  deleteBooking(){
    this._store.dispatch(new DeleteBooking(this.booking.id ?? ''));
  }
  payBooking(){
    this._store.dispatch(new PayOrder(this.booking.id ?? ''));

  }
}
