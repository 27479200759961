import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED,
  PrintDto,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

import {
  BadgeComponent,
  ModalComponent,
  ModalService,
  SelectOptionBlocComponent,
} from '../../containers';
import { SetCustomPrintsComponent } from '../set-custom-prints/set-custom-prints.component';

@Component({
  selector: 'burddy-monorepo-select-prints-control',
  standalone: true,
  imports: [
    SelectOptionBlocComponent,
    NgIf,
    NgFor,
    TranslateModule,
    BadgeComponent,
    SetCustomPrintsComponent,
    NgClass,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectPrintsControlComponent,
      multi: true,
    },
  ],
  templateUrl: './select-prints-control.component.html',
  styleUrls: ['./select-prints-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPrintsControlComponent
  implements AfterViewInit, ControlValueAccessor, OnInit
{
  @Input() selectedPrint?: PrintDto;
  @Input() availablePrints!: PrintDto[];
  @Output() selectedPrintChange = new EventEmitter<PrintDto>();
  @Input() morePrints: boolean = false;

  public availablePrintsNotCustomized: PrintDto[] = [];
  public MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED =
    MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED;
  public onChange!: (...arg: unknown[]) => unknown;
  public onTouched!: (...arg: unknown[]) => unknown;

  private _cdr = inject(ChangeDetectorRef);
  private _modalService = inject(ModalService);
  private _customPrintModal:
    | ComponentRef<any>
    | ComponentRef<ModalComponent>
    | null
    | undefined;

  public writeValue(obj: PrintDto): void {
    this.selectedPrint = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public changeValue() {
    this.onChange(this.selectedPrint);
    this.onTouched();
    this.selectedPrintChange.emit(this.selectedPrint);
  }

  ngOnInit(): void {
    this.availablePrintsNotCustomized = this.availablePrints.filter((print) => {
      return (print?.quantity ?? 0) <= MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED;
    });
  }

  ngAfterViewInit(): void {
    if (!this.selectedPrint) {
      this.selectedPrint = this.availablePrints[0];
      this.changeValue();
      this._cdr.detectChanges();
    }
  }

  clickBlock(printToSelect: PrintDto) {
    if (this.selectedPrint?.id === printToSelect?.id) {
      this.selectedPrint = undefined;
    } else {
      this.selectedPrint = printToSelect;
    }
    this.changeValue();
  }

  showCustomPrints() {
    this._customPrintModal = this._modalService.openModal({
      component: SetCustomPrintsComponent,
      innerComponentInput: {
        selectedPrint: this.selectedPrint,
        availablePrints: this.availablePrints.filter((print) => {
          return (print?.quantity ?? 0) > MAX_PRINTS_QUANTITY_NOT_CUSTOMIZED;
        }),
        confirmCallBack: (selectedPrint: PrintDto) => {
          this.selectedPrint = selectedPrint;
          this.changeValue();
          this._modalService.closeModal(this._customPrintModal);
          this._cdr.detectChanges();
        },
      },
    });
  }
}
