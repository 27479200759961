import { Route } from '@angular/router';
import { AuthCustomerGuard } from '@burddy-monorepo/front/shared/guards';
import { headerResolver } from 'apps/front/booking/src/route-resolvers';

import { bookingRoutes } from '../features/booking/containers/booking.routes';
import { customizeRoutes } from '../features/customize-booking/container/customize-booking.routes';
import { loginRoutes } from '../features/login/containers/login.routes';
import { profileRoutes } from '../features/profile/containers/profile.routes';
import { loadBookingResolve } from '../route-resolvers/load-booking.resolver';
import { MainRoutes } from '../routes';

export const appRoutes: Route[] = [
  {
    path: MainRoutes.BOOKING,
    children: bookingRoutes,
    loadComponent: () =>
      import(
        '../features/booking/containers/booking-page/booking-page.component'
      ).then((m) => m.BookingPageComponent),
  },
  {
    path: MainRoutes.EDIT_BOOKING + '/:id',
    children: bookingRoutes,
    resolve: { booking: loadBookingResolve },
    loadComponent: () =>
      import(
        '../features/booking/containers/booking-page/booking-page.component'
      ).then((m) => m.BookingPageComponent),
  },
  {
    path: MainRoutes.LOGIN,
    children: loginRoutes,
    loadComponent: () =>
      import(
        '../features/login/containers/login-page/login-page.component'
      ).then((m) => m.LoginPage),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: MainRoutes.PROFILE,
    children: profileRoutes,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import(
        '../features/profile/containers/profile-page/profile-page.component'
      ).then((m) => m.ProfilePageComponent),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: MainRoutes.MY_BOOKINGS,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import(
        '../features/my-bookings/containers/my-bookings-page/my-bookings-page.component'
      ).then((m) => m.MyBookingsPageComponent),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: MainRoutes.CUSTOMIZE_BOOKING,
    children: customizeRoutes,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import(
        '../features/customize-booking/container/customize-booking-page/customize-booking-page.component'
      ).then((m) => m.CustomizeBookingPageComponent),
    resolve: {
      isHeaderResolve: headerResolver,
      booking: loadBookingResolve,
    },
  },
  { path: '**', redirectTo: MainRoutes.BOOKING + '/0', pathMatch: 'full' },
];
