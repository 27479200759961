import {
  AddressData,
  CustomerData,
  DeliveryAvailableCountries,
} from '@burddy-monorepo/shared/shared-data';

export class LoadCustomerBookings {
  public static readonly type = '[Customer] LoadCustomerBookings';
}

export class ReLoadCustomerBookings {
  public static readonly type = '[Customer] ReLoadCustomerBookings';
}

export class SetCustomerInfo {
  public static readonly type = '[Customer] SetCustomerInfo';

  constructor(public data: CustomerData) {}
}

export class SetLoadBooking {
  public static readonly type = '[Customer] SetLoadBooking';

  constructor(public load: boolean) {}
}

export class ClearUserData {
  public static readonly type = '[Customer] ClearData';
}

export class GetCustomerMainInfo {
  public static readonly type = '[Customer] GetCustomerMainInfo';
}

export class SetCurrentSiteVersion {
  public static readonly type = '[Customer] SetCurrentSiteVersion';

  constructor(public siteVersion: DeliveryAvailableCountries) {}
}

export class SetBillingAddress {
  public static readonly type = '[Customer] SetBillingAddress';

  constructor(public address: AddressData) {}
}

export class GetCustomerBillingAddress {
  public static readonly type = '[Customer] GetCustomerBillingAddress';
}
